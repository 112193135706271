import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Image,
    useColorModeValue,
    Badge,
    SimpleGrid,
    Icon,
    List,
    ListItem,
    ListIcon,
    Flex,
} from '@chakra-ui/react';
import { FaCheckCircle, FaLaptopCode, FaMobileAlt, FaSearch, FaRocket, FaRegFileAlt, FaPhone, FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../../imagesComponents/Logo_RockYourNet650.webp';
import Vitrine from '../../imagesComponents/vitrine.webp';

const MotionBox = motion(Box);

export default function MainCreationSiteAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const headingColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('white', 'gray.700');
    const buttonBg = useColorModeValue('white', 'gray.800');
    const buttonText = useColorModeValue('purple.500', 'purple.300');
    const sectionSpacing = 20;

    return (
        <Box as="section" bg={bg} minH="100vh" fontSize="lg">
            <Container maxW="8xl" pt={{ base: 8, lg: 20 }} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={6} align="center" mb={28}>
                            <Badge colorScheme="purple" fontSize="md" px={6} py={1} borderRadius="full" mb={6}>
                                Expert en Création Web
                            </Badge>
                            <Heading as="h1" size="2xl" color={headingColor} textAlign="center">
                                Création de Site Internet à Auxerre
                            </Heading>
                            <Image
                                src={Logo}
                                alt="Logo Rock Your Net"
                                width={{ base: "200px", md: "300px" }}
                                my={6}
                            />
                            <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                Des Sites Web Performants et Design
                            </Heading>
                        </VStack>

                        <Text fontSize="xl" color={textColor}>
                            Spécialiste de la <strong>création de sites internet à Auxerre</strong>, Rock Your Net conçoit des solutions web sur mesure qui répondent aux besoins spécifiques de votre entreprise. Notre expertise technique et notre approche créative vous garantissent un site web professionnel et performant.
                        </Text>
                    </Box>
                </VStack>
            </Container>

            <Container maxW="full" bg={cardBg}>
                <Container maxW="8xl" bg={cardBg} pt={10} pb={10}>
                    <VStack spacing={sectionSpacing} align="start">
                        <Box w="100%" py={12}>
                            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} alignItems="center">
                                <Box>
                                    <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                        Notre Expertise en Création Web
                                    </Heading>
                                    <Text color={textColor} mb={8}>
                                        En tant qu'expert en <strong>création de site internet à Auxerre</strong>, nous vous proposons :
                                    </Text>
                                    <List spacing={6}>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Sites web responsive</strong> : Parfaitement adaptés à tous les appareils, ordinateur, tablette et mobile
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Design moderne et personnalisé</strong> : Une identité visuelle unique
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Optimisation SEO intégrée</strong> : Pour une meilleure visibilité sur les moteurs de recherche
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Performance optimale</strong> : Des temps de chargement rapides, pour ne pas perdre de clients
                                            </Box>
                                        </ListItem>
                                    </List>
                                </Box>
                                <Flex justify="center" align="center">
                                    <Image
                                        src={Vitrine}
                                        alt="Site vitrine exemple"
                                        width={{ base: "100%", lg: "60%" }}
                                        mt={{ base: "20px", lg: "0" }}
                                        maxW="500px"
                                        borderRadius="2xl"
                                        shadow="2xl"
                                    />
                                </Flex>
                            </SimpleGrid>
                        </Box>
                    </VStack>
                </Container>
            </Container>

            <Container maxW="8xl" pt={20} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={20} align="start">
                            <Box w="full">
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Nos Solutions Web sur Mesure
                                </Heading>
                                <Text color={textColor} mb={16}>
                                    Notre agence de <strong>création de sites internet à Auxerre</strong> développe des solutions adaptées à vos objectifs. Chaque projet est unique et mérite une attention particulière.
                                </Text>

                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={12}>
                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaLaptopCode} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Site Vitrine</Heading>
                                        <Text color={textColor}>
                                            Présentez votre entreprise avec élégance et professionnalisme
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaMobileAlt} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Site E-commerce</Heading>
                                        <Text color={textColor}>
                                            Vendez vos produits en ligne avec une boutique optimisée
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaRocket} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Application Web</Heading>
                                        <Text color={textColor}>
                                            Des solutions web avancées pour vos besoins spécifiques
                                        </Text>
                                    </MotionBox>
                                </SimpleGrid>
                            </Box>

                            <Box mt={8}>
                                <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                    Pourquoi Créer Votre Site Web avec Rock Your Net ?
                                </Heading>
                                <Text color={textColor}>
                                    Notre expertise en <strong>création de site internet à Auxerre</strong> vous assure un accompagnement complet et personnalisé. Nous nous engageons à créer un site web qui répond parfaitement à vos attentes et qui contribue à votre succès en ligne. Notre équipe maîtrise les dernières technologies et tendances du web pour vous offrir une solution moderne et pérenne.
                                </Text>
                            </Box>

                            <Box>
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Un Investissement pour Votre Avenir
                                </Heading>
                                <Text color={textColor}>
                                    Choisir Rock Your Net pour la <strong>création de votre site internet à Auxerre</strong>, c'est opter pour un partenaire qui comprend vos enjeux et qui s'engage à développer votre présence numérique. Nous créons des sites web qui génèrent des résultats concrets pour votre activité.
                                </Text>
                            </Box>

                            <Box w="full" bg={cardBg} borderRadius="2xl" p={{ base: 4, lg: 12 }} mt={8}>
                                <VStack spacing={8} align="center">
                                    <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                        Prêt à Lancer Votre Projet Web ?
                                    </Heading>

                                    <Text fontSize="lg" color={textColor} textAlign="center" maxW="2xl">
                                        Discutons ensemble de votre projet et trouvons la solution idéale pour votre présence en ligne.
                                    </Text>

                                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full" maxW="4xl" mx="auto">
                                        <MotionBox
                                            as={RouterLink}
                                            to="/Services"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaRegFileAlt} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Services</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Créations"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaArrowRight} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Créations</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Contact"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaPhone} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Contactez-nous</Text>
                                        </MotionBox>
                                    </SimpleGrid>
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}