import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Image,
    useColorModeValue,
    Badge,
    SimpleGrid,
    Icon,
    List,
    ListItem,
    ListIcon,
    Flex,
} from '@chakra-ui/react';
import { FaCheckCircle, FaBuilding, FaBullhorn, FaChartLine, FaArrowRight, FaPhone, FaRegFileAlt } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../../imagesComponents/Logo_RockYourNet650.webp';
import CommunicationBourgogne from '../../imagesComponents/agenceDigitaleBourgogne.webp';

const MotionBox = motion(Box);

export default function MainCommunicationBourgogne() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const headingColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('white', 'gray.700');
    const buttonBg = useColorModeValue('white', 'gray.800');
    const buttonText = useColorModeValue('purple.500', 'purple.300');
    const sectionSpacing = 20;

    return (
        <Box as="section" bg={bg} minH="100vh" fontSize="lg">
            <Container maxW="8xl" pt={{ base: 8, lg: 20 }} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={6} align="center" mb={28}>
                            <Badge colorScheme="purple" fontSize="md" px={6} py={1} borderRadius="full" mb={6}>
                                Expertise Régionale
                            </Badge>
                            <Heading as="h1" size="2xl" color={headingColor} textAlign="center">
                                Agence de Communication en Bourgogne
                            </Heading>
                            <Image
                                src={Logo}
                                alt="Logo Rock Your Net"
                                width={{ base: "200px", md: "300px" }}
                                my={6}
                            />
                            <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                Votre Partenaire Digital en Bourgogne
                            </Heading>
                        </VStack>

                        <Text fontSize="xl" color={textColor}>
                            En tant qu'<strong>agence de communication en Bourgogne</strong>, Rock Your Net accompagne les entreprises régionales dans leur transformation digitale. Notre expertise locale et notre vision innovante font de nous le partenaire idéal pour développer votre présence en ligne à l'échelle régionale.
                        </Text>
                    </Box>
                </VStack>
            </Container>

            <Container maxW="full" bg={cardBg}>
                <Container maxW="8xl" bg={cardBg} pt={10} pb={10}>
                    <VStack spacing={sectionSpacing} align="start">
                        <Box w="100%" py={12}>
                            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} alignItems="center">
                                <Box>
                                    <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                        Des Solutions Digitales pour la Bourgogne
                                    </Heading>
                                    <Text color={textColor} mb={8}>
                                        Notre <strong>agence de communication en Bourgogne</strong> propose une gamme complète de services adaptés aux entreprises locales :
                                    </Text>
                                    <List spacing={6}>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box as={RouterLink} to="/Services/Site-vitrine" color={textColor}>
                                                <strong>Création de sites web</strong> : Des solutions performantes adaptées à votre activité
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box as={RouterLink} to="/Services/Referencement" color={textColor}>
                                                <strong>Référencement régional</strong> : Optimisation SEO ciblée pour la Bourgogne
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Communication digitale</strong> : Stratégies personnalisées pour votre visibilité
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Marketing local</strong> : Solutions adaptées au marché bourguignon
                                            </Box>
                                        </ListItem>
                                    </List>
                                </Box>
                                <Flex justify="center" align="center">
                                    <Image
                                        src={CommunicationBourgogne}
                                        alt="Région Bourgogne illustration"
                                        width={{ base: "100%", lg: "60%" }}
                                        mt={{ base: "20px", lg: "0" }}
                                        maxW="500px"
                                        borderRadius="2xl"
                                        shadow="2xl"
                                    />
                                </Flex>
                            </SimpleGrid>

                            <Flex justify="center" w="full" mt={16}>
                                <MotionBox
                                    as={RouterLink}
                                    to="/Services"
                                    bg={buttonBg}
                                    whileHover={{ scale: 1.02 }}
                                    p={4}
                                    px={8}
                                    border="2px solid"
                                    borderColor="purple.500"
                                    color={buttonText}
                                    borderRadius="lg"
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '1px solid',
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl'
                                    }}
                                    textAlign="center"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={3}
                                >
                                    <Icon as={FaRegFileAlt} w={5} h={5} />
                                    <Text fontSize="md" fontWeight="medium">Découvrir Nos Services</Text>
                                </MotionBox>
                            </Flex>
                        </Box>
                    </VStack>
                </Container>
            </Container>

            <Container maxW="8xl" pt={20} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={20} align="start">
                            <Box w="full">
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Notre Expertise au Service de la Bourgogne
                                </Heading>
                                <Text color={textColor} mb={16}>
                                    Notre <strong>agence de communication en Bourgogne</strong> met son expertise au service des entreprises régionales pour développer leur présence digitale.
                                </Text>

                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={12}>
                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaBuilding} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Ancrage Local</Heading>
                                        <Text color={textColor}>
                                            Une connaissance approfondie du tissu économique bourguignon
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaBullhorn} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Communication Ciblée</Heading>
                                        <Text color={textColor}>
                                            Des stratégies adaptées aux spécificités de notre région
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaChartLine} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Résultats Concrets</Heading>
                                        <Text color={textColor}>
                                            Un impact mesurable sur votre développement régional
                                        </Text>
                                    </MotionBox>
                                </SimpleGrid>
                            </Box>

                            <Box>
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Une Communication Adaptée aux Enjeux Régionaux
                                </Heading>
                                <Text color={textColor} mb={8}>
                                    En tant qu'<strong>agence de communication en Bourgogne</strong>, nous comprenons les spécificités de notre territoire. Notre approche combine expertise digitale et connaissance du marché local pour offrir des solutions pertinentes et efficaces.
                                </Text>
                                <Text color={textColor}>
                                    Que vous soyez une PME, un commerce, ou une institution, nous adaptons nos services pour répondre à vos objectifs de développement dans la région Bourgogne-Franche-Comté. Notre équipe vous accompagne avec des solutions sur mesure qui valorisent votre ancrage régional.
                                </Text>
                            </Box>

                            {/* Section CTA */}
                            <Box w="full" bg={cardBg} borderRadius="2xl" p={{ base: 4, lg: 12 }} mt={8}>
                                <VStack spacing={8} align="center">
                                    <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                        Développons Ensemble Votre Communication en Bourgogne
                                    </Heading>

                                    <Text fontSize="lg" color={textColor} textAlign="center" maxW="2xl">
                                        Prenez contact avec notre équipe pour discuter de vos projets de communication digitale en Bourgogne.
                                    </Text>

                                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full" maxW="4xl" mx="auto">
                                        <MotionBox
                                            as={RouterLink}
                                            to="/Services"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaRegFileAlt} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Services</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Créations"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaArrowRight} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Réalisations</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Contact"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaPhone} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Contactez-nous</Text>
                                        </MotionBox>
                                    </SimpleGrid>
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}