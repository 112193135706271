import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainApplication from '../sections/MainApplicationWeb';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet'; // Pour gérer les meta-données dans React

export default function ApplicationWeb() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Services de Création d'Applications Web | Rock Your Net</title>
                <meta name="description" content="Découvrez nos services de création d'applications web sur mesure, adaptées à vos besoins spécifiques, avec des technologies modernes et une approche innovante." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Rock Your Net, création d'applications web, développement sur mesure, application mobile, site web, services digitaux, développement logiciel" />
                <link rel="canonical" href="https://rockyournet.com/Services/ApplicationWeb" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:title" content="Services de Création d'Applications Web | Rock Your Net" />
                <meta property="og:description" content="Rock Your Net vous propose des solutions personnalisées pour créer des applications web sur mesure, adaptées à vos besoins spécifiques." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Services/ApplicationWeb" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" /> {/* Remplacer par une image réelle */}

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Services de Création d'Applications Web | Rock Your Net" />
                <meta name="twitter:description" content="Nous créons des applications web modernes et performantes, sur mesure, adaptées à vos besoins spécifiques." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" /> {/* Remplacer par une image réelle */}

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "name": "Services de Création d'Applications Web",
            "url": "https://rockyournet.com/Services/ApplicationWeb",
            "logo": "https://rockyournet.com/static/media/logorockyournet.png",
            "sameAs": [
                "https://www.facebook.com/RockYourNet",
                "https://twitter.com/RockYourNet"
            ],
            "description": "Nous offrons des services professionnels de création d'applications web sur mesure, pour répondre à vos besoins digitaux.",
            "provider": {
                "@type": "Organization",
                "name": "Rock Your Net",
                "url": "https://rockyournet.com"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+33-6-24-31-10-75",
                "contactType": "Customer Service",
                "areaServed": "FR",
                "availableLanguage": "French"
            }
        }
        `}
                </script>
            </Helmet>



            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}

            >
                {/* Header (fixe avec Navbar) */}
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                {/* Section principale */}
                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainApplication />
                </GridItem>

                {/* Footer */}
                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};
