import React from 'react';
import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    Image,
    VStack,
    HStack,
    Icon,
    Badge,
    Flex,
    useColorMode,
    useColorModeValue,
    useBreakpointValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaQuoteLeft, FaStar, FaCheck, FaAward, FaRocket } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, EffectCoverflow } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';

// Import images
import Clients from '../imagesComponents/clients.jpg';
import Cappadoce from '../imagesComponents/cappadoce.png';
import Rhoneau from '../imagesComponents/rhoneauSquare.jpg';
import Massis from '../imagesComponents/MassisSquare200.jpg';

const MotionBox = motion(Box);

const testimonials = [
    {
        name: "Restaurant Cappadoce",
        role: "Restaurant Traditionnel Turc",
        feedback: "Un site vitrine superbe et un support client irréprochable. L'équipe a parfaitement compris nos besoins et a créé un site qui reflète l'authenticité de notre cuisine. Notre visibilité en ligne s'est considérablement améliorée.",
        image: Cappadoce,
        rating: 5,
        benefits: [
            "Top 1 Google",
            "Design sur mesure",
            "Site responsive"
        ],
        date: "Client depuis 2022",
        badge: "Site Vitrine"
    },
    {
        name: "Rhôn'eau",
        role: "Plomberie & Chauffage",
        feedback: "Notre activité a explosé grâce à leur expertise en création de site internet. L'équipe est professionnelle, réactive et a su mettre en avant nos services de manière claire et efficace. Leur professionnalisme a dépassé nos attentes.",
        image: Rhoneau,
        rating: 5,
        benefits: [
            "Top 5 Google local",
            "Taux de conversion 15%",
            "Support réactif"
        ],
        date: "Client depuis 2023",
        badge: "Site Vitrine"
    },
    {
        name: "Massis Club d'Echecs",
        role: "Club Sportif",
        feedback: "Rock Your Net a transformé notre présence en ligne grâce à un référencement exceptionnel ! Le site est intuitif, responsive, moderne et parfaitement adapté à nos besoins. Nos adhésions ont significativement augmenté.",
        image: Massis,
        rating: 5,
        benefits: [
            "Top 3 Google",
            "Visibilité accrue",
            "Interface moderne"
        ],
        date: "Client depuis 2023",
        badge: "Site Associatif"
    }
];
const TestimonialCard = ({ testimonial }) => {
    const { colorMode } = useColorMode();
    const cardBg = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const accentColor = useColorModeValue('purple.700', 'orange.200');
    const badgeBg = useColorModeValue('orange.200', 'purple.900');

    return (
        <MotionBox
            p={8}
            h={{ base: "auto", md: "550px" }}
            shadow="xl"
            borderRadius="xl"
            bg={cardBg}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            _hover={{ transform: 'translateY(-8px)', shadow: '2xl' }}
            position="relative"
            overflow="hidden"
        >
            <VStack spacing={6} align="stretch">
                <Badge
                    position="absolute"
                    top={4}
                    right={4}
                    bg={badgeBg}
                    color={accentColor}
                    px={3}
                    py={1}
                    borderRadius="full"
                    fontSize="sm"
                >
                    {testimonial.badge}
                </Badge>

                <Flex align="center" gap={4}>
                    <Box
                        borderRadius="full"
                        overflow="hidden"
                        boxSize="80px"
                        border="3px solid"
                        borderColor={accentColor}
                    >
                        <Image
                            src={testimonial.image}
                            alt={testimonial.name}
                            w="full"
                            h="full"
                            objectFit="cover"
                            loading="lazy"
                        />
                    </Box>
                    <VStack align="start" spacing={1}>
                        <Text fontWeight="bold" fontSize="lg" color={accentColor}>
                            {testimonial.name}
                        </Text>
                        <Text fontSize="sm" color={textColor}>
                            {testimonial.role}
                        </Text>
                    </VStack>
                </Flex>

                <HStack spacing={1}>
                    {[...Array(testimonial.rating)].map((_, i) => (
                        <Icon key={i} as={FaStar} color="yellow.400" />
                    ))}
                </HStack>

                <Icon as={FaQuoteLeft} w={8} h={8} color={accentColor} opacity={0.3} />

                <Text fontSize="md" color={textColor} lineHeight="tall">
                    {testimonial.feedback}
                </Text>

                <VStack align="start" spacing={2} pt={4}>
                    {testimonial.benefits.map((benefit, index) => (
                        <HStack key={index} spacing={2}>
                            <Icon as={FaCheck} color="green.500" />
                            <Text fontSize="sm" color={textColor}>
                                {benefit}
                            </Text>
                        </HStack>
                    ))}
                </VStack>
            </VStack>
        </MotionBox>
    );
};

const TestimonialsSection = () => {
    const headingColor = useColorModeValue('gray.800', 'white');
    const overlayColor = useColorModeValue(
        'rgba(221, 204, 255, 0.95)',
        'rgba(36, 9, 48, 0.9)'
    );
    const isMobile = useBreakpointValue({ base: true, md: false });

    const swiperStyles = {
        '.swiper': {
            overflow: 'visible',
            padding: '40px 0'
        },
        '.swiper-pagination': {
            bottom: '0px !important',
        },
        '.swiper-pagination-bullet': {
            background: useColorModeValue('purple.500', 'purple.200'),
            width: '10px',
            height: '10px',
            opacity: '0.5',
        },
        '.swiper-pagination-bullet-active': {
            opacity: '1',
        },
        '.swiper-button-next, .swiper-button-prev': {
            color: useColorModeValue('purple.500', 'purple.200'),
            _after: {
                fontSize: '20px',
            },
        },
        '.swiper-slide': {
            opacity: '0.7',
            transition: 'all 0.3s ease',
        },
        '.swiper-slide-active': {
            opacity: '1',
            zIndex: 2,
        }
    };

    const MobileCarousel = () => (
        <Box w="full" sx={swiperStyles} overflow="visible">
            <Swiper
                modules={[Pagination, Navigation]}
                spaceBetween={15}
                slidesPerView={1}
                centeredSlides={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                loop={true}
                style={{
                    padding: '20px 0 40px',
                }}
            >
                {testimonials.map((testimonial, index) => (
                    <SwiperSlide key={index}>
                        <TestimonialCard testimonial={testimonial} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );

    const DesktopGrid = () => (
        <Flex
            flexWrap="wrap"
            gap={8}
            justify="center"
        >
            {testimonials.map((testimonial, index) => (
                <Box
                    key={index}
                    flex="0 1 400px"
                    maxW="400px"
                >
                    <TestimonialCard testimonial={testimonial} />
                </Box>
            ))}
        </Flex>
    );

    return (
        <Box
            as="section"
            py={{ base: '20', md: '32' }}
            backgroundImage={`url(${Clients})`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundAttachment="fixed"
            position="relative"
            _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: overlayColor,
                backdropFilter: 'blur(2px)',
                zIndex: 1,
            }}
        >
            <Container maxW="8xl" position="relative" zIndex={2}>
                <VStack spacing={16}>
                    <VStack spacing={6} textAlign="center" maxW="3xl" mx="auto">
                        <HStack justify="center">
                            <Badge
                                colorScheme="purple"
                                fontSize="md"
                                px={6}
                                py={1}
                                borderRadius="full"
                            >
                                Témoignages Clients
                                <Icon as={FaAward} w={6} h={6} ml={1} pt={1} color="purple.500" />
                            </Badge>
                        </HStack>
                        <Heading
                            fontSize={{ base: '3xl', md: '5xl' }}
                            fontWeight="bold"
                            color={headingColor}
                            lineHeight="shorter"
                        >
                            Ils nous font confiance
                        </Heading>
                        <Text
                            fontSize={{ base: 'lg', md: 'xl' }}
                            color={useColorModeValue('gray.600', 'gray.300')}
                        >
                            Découvrez les retours d'expérience de nos clients et les résultats obtenus grâce à nos solutions digitales.
                        </Text>
                    </VStack>

                    <Box w="full" px={{ base: 4, md: 8 }}>
                        {isMobile ? <MobileCarousel /> : <DesktopGrid />}
                    </Box>

                    {/* Nouvelle section CTA */}
                    <VStack
                        spacing={6}
                        textAlign="center"
                        maxW="3xl"
                        mx="auto"
                        pt={14}
                        borderTop="2px solid"
                        borderTopColor={useColorModeValue('purple.100', 'purple.700')}
                    >
                        <Heading
                            fontSize={{ base: '2xl', md: '3xl' }}
                            fontWeight="bold"
                            color={headingColor}
                            lineHeight="shorter"
                        >
                            Prêt à Rejoindre Nos Clients Satisfaits ?
                        </Heading>
                        <Text
                            fontSize={{ base: 'md', md: 'lg' }}
                            color={useColorModeValue('gray.600', 'gray.300')}
                            maxW="2xl"
                        >
                            Transformez votre présence en ligne et boostez votre visibilité avec nos solutions sur mesure. Contactez-nous dès aujourd'hui pour discuter de votre projet.
                        </Text>
                        <Button
                            as={RouterLink}
                            to="/Contact"
                            colorScheme="purple"
                            size="lg"
                            px={8}
                            py={6}
                            fontSize="lg"
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                            rightIcon={<Icon as={FaRocket} />}
                        >
                            Démarrer Votre Projet
                        </Button>
                    </VStack>
                </VStack>
            </Container>
        </Box>
    );
};

export default TestimonialsSection;