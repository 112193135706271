import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainAgenceWebAuxerre from '../../sections/référencement/MainAgenceWebAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function AgenceWebAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Agence Web à Auxerre | Rock Your Net - Solutions Digitales</title>
                <meta name="description" content="Rock Your Net, votre agence web à Auxerre. Experts en développement web, design, référencement et stratégie digitale pour développer votre présence en ligne." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="agence web Auxerre, développement web Auxerre, webdesign Auxerre, solutions digitales Auxerre, agence digitale Auxerre, création site web Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Agence-web-auxerre" />

                {/* Open Graph */}
                <meta property="og:title" content="Agence Web à Auxerre | Rock Your Net - Solutions Digitales" />
                <meta property="og:description" content="Votre agence web à Auxerre : développement, design et stratégie digitale pour booster votre présence en ligne." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Agence-web-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Agence Web à Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Solutions web complètes pour votre entreprise. Expertise en développement, design et stratégie digitale." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net - Agence Web Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet.png",
                        "description": "Agence web professionnelle à Auxerre spécialisée en développement web, design et stratégie digitale.",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "14 Place de l'Hôtel de Ville",
                            "addressLocality": "Auxerre",
                            "postalCode": "89000",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": 47.7979,
                            "longitude": 3.5695
                        },
                        "url": "https://rockyournet.com/Agence-web-auxerre",
                        "telephone": "+33624311075",
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "priceRange": "€€",
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "serviceType": [
                            "Développement Web",
                            "Web Design",
                            "Référencement SEO",
                            "Marketing Digital"
                        ]
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainAgenceWebAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};