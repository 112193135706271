import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Button,
    Image,
    Badge,
    Flex,
    Icon,
    VStack,
    HStack,
    useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaExternalLinkAlt, FaCode, FaMobileAlt, FaDesktop, FaSearch, FaRocket } from 'react-icons/fa';
import { motion } from 'framer-motion';

// Importation des images
import CappadoceImage from '../imagesComponents/CappadoceAccueil.jpg';
import RhoneauImage from '../imagesComponents/RhoneauAccueil.jpg';
import GarageAppImage from '../imagesComponents/GarageAccueil.jpg'; // Remplacer par la vraie image

const MotionBox = motion(Box);

// Données enrichies
const creations = [
    {
        title: "Restaurant Cappadoce",
        description: "Un site vitrine moderne pour un restaurant turc à Auxerre mettant en valeur la richesse de la cuisine traditionnelle turque.",
        image: CappadoceImage,
        link: "https://www.restaurant-cappadoce.com/",
        badge: "Site Vitrine",
        technologies: ["WordPress", "SEO", "Responsive"],
        features: [
            { icon: FaDesktop, text: "Performance" },
            { icon: FaMobileAlt, text: "100% responsive" },
            { icon: FaSearch, text: "SEO optimisé" }
        ],
        results: "Top 1 Google local"
    },
    {
        title: "Rhôn'eau",
        description: "Un site vitrine pour un plombier à Lyon combinant une interface épurée avec une optimisation SEO poussée pour une visibilité locale maximale.",
        image: RhoneauImage,
        link: "https://www.rhoneau.fr/",
        badge: "Site Vitrine",
        technologies: ["UX/UI Design", "WordPress", "SEO"],
        features: [
            { icon: FaRocket, text: "Design moderne" },
            { icon: FaSearch, text: "SEO local" },
            { icon: FaCode, text: "Code optimisé" }
        ],
        results: "Top 3 Google local"
    },
    {
        title: "Application Garages",
        description: "Une application web innovante pour la gestion des véhicules et services, optimisant le flux de travail des garages automobiles.",
        image: GarageAppImage,
        link: "https://garage-parrot.eu/accueil.php",
        badge: "Application Métier",
        technologies: ["PHP", "MySQL", "Responsive"],
        features: [
            { icon: FaCode, text: "Architecture MVC" },
            { icon: FaMobileAlt, text: "PWA" },
            { icon: FaRocket, text: "Haute performance" }
        ],
        results: "Gain de productivité de 30%"
    },
];

const CreationCard = ({ creation }) => {
    const cardBg = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const accentColor = useColorModeValue('purple.700', 'orange.200');
    const badgeBg = useColorModeValue('orange.200', 'purple.900');
    const buttonBG = useColorModeValue('purple.500', 'purple.200');
    const buttonBgHover = useColorModeValue('white', 'gray.50');
    const buttonColor = useColorModeValue('white', 'gray.700');
    const buttonColorHover = useColorModeValue('purple.600', 'purple.600');


    return (
        <MotionBox
            bg={cardBg}
            shadow="xl"
            borderRadius="xl"
            overflow="hidden"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            _hover={{ transform: 'translateY(-8px)', shadow: '2xl' }}
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Box position="relative">
                <Image
                    src={creation.image}
                    alt={creation.title}
                    objectFit="cover"
                    objectPosition="top"
                    w="100%"
                    h="250px"
                />
                <Badge
                    position="absolute"
                    top={4}
                    right={4}
                    bg={badgeBg}
                    color={accentColor}
                    px={3}
                    py={1}
                    borderRadius="full"
                    fontSize="sm"
                    shadow="lg"
                >
                    {creation.badge}
                </Badge>
            </Box>

            <VStack p={6} spacing={4} align="stretch" flex="1">
                <Heading as="h3" fontSize="xl" color={useColorModeValue('gray.800', 'white')}>
                    {creation.title}
                </Heading>

                <Text color={textColor}>
                    {creation.description}
                </Text>

                {/*  <HStack spacing={2} flexWrap="wrap">
                    {creation.technologies.map((tech, index) => (
                        <Badge
                            key={index}
                            colorScheme="purple"
                            variant="outline"
                            borderRadius="full"
                            px={2}
                        >
                            {tech}
                        </Badge>
                    ))}
                </HStack> */}

                <Box>
                    <Text fontSize="sm" fontWeight="semibold" mb={2} color={useColorModeValue('gray.800', 'white')}>
                        Caractéristiques clés :
                    </Text>
                    <VStack align="start" spacing={2}>
                        {creation.features.map((feature, index) => (
                            <HStack key={index} spacing={2}>
                                <Icon as={feature.icon} color="orange.500" />
                                <Text fontSize="sm" color={textColor}>
                                    {feature.text}
                                </Text>
                            </HStack>
                        ))}
                    </VStack>
                </Box>

                <Text fontSize="sm" fontWeight="bold" color="green.500" mt="auto">
                    {creation.results}
                </Text>

                <Button
                    as="a"
                    href={creation.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    colorScheme="purple"
                    variant="outline"
                    size="md"
                    rightIcon={<FaExternalLinkAlt />}
                    bg={buttonBG}
                    color={buttonColor}
                    _hover={{
                        transform: 'translateY(-2px)',
                        shadow: 'lg',
                        bg: buttonBgHover,
                        color: buttonColorHover
                    }}
                >
                    Découvrir
                </Button>
            </VStack>
        </MotionBox>
    );
};

const LastCreations = () => {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <Box as="section" py={{ base: '20', md: '32' }} bg={bg}>
            <Container maxW="8xl">
                <VStack spacing={16}>
                    <VStack spacing={6} textAlign="center" maxW="3xl" mx="auto">
                        <Badge colorScheme="purple" fontSize="md" px={6} py={1} borderRadius="full">
                            Portfolio
                        </Badge>
                        <Heading
                            as="h2"
                            fontSize={{ base: '3xl', md: '5xl' }}
                            fontWeight="bold"
                            color={headingColor}
                            lineHeight="shorter"
                        >
                            Nos Dernières Créations
                        </Heading>
                        <Text fontSize={{ base: 'lg', md: 'xl' }} color={textColor}>
                            Découvrez comment nous transformons les idées de nos clients en solutions digitales performantes et esthétiques.
                        </Text>
                    </VStack>

                    <Box w="full" px={{ base: 4, md: 8 }}>
                        <Flex
                            flexWrap="wrap"
                            gap={8}
                            justify="center"
                        >
                            {creations.map((creation, index) => (
                                <Box
                                    key={index}
                                    flex={{ base: "1 1 100%", md: "0 1 400px" }}
                                    maxW="400px"
                                >
                                    <CreationCard creation={creation} />
                                </Box>
                            ))}
                        </Flex>
                    </Box>

                    <Box textAlign="center" pt={8}>
                        <Button
                            as={RouterLink}
                            to="/Créations"
                            size="lg"
                            colorScheme="purple"
                            px={8}
                            rightIcon={<FaRocket />}
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                        >
                            Toutes nos créations
                        </Button>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
};

export default LastCreations;