import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Button,
    Flex,
    Icon,
    Grid,
    VStack,
    Badge,
    useColorModeValue,
    useBreakpointValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaLaptopCode, FaRocket, FaChartLine, FaHandshake, FaCheck, FaUsers, FaLightbulb, FaCogs } from 'react-icons/fa';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const features = [
    {
        icon: FaLaptopCode,
        title: "Création Web Professionnelle",
        text: "Sites vitrines, e-commerce et applications sur mesure adaptés à vos besoins spécifiques.",
        color: "blue.500"
    },
    {
        icon: FaRocket,
        title: "Stratégies Digitales",
        text: "Plans marketing personnalisés pour maximiser votre présence en ligne et atteindre vos objectifs.",
        color: "purple.500"
    },
    {
        icon: FaChartLine,
        title: "SEO Expert",
        text: "Optimisation technique et éditoriale pour améliorer votre visibilité sur les moteurs de recherche.",
        color: "green.500"
    },
    {
        icon: FaHandshake,
        title: "Accompagnement Dédié",
        text: "Support continu et conseils personnalisés tout au long de votre projet digital.",
        color: "orange.500"
    },
    {
        icon: FaUsers,
        title: "Expérience Utilisateur",
        text: "Interfaces intuitives et ergonomiques pour une expérience utilisateur optimale.",
        color: "red.500"
    },
    {
        icon: FaLightbulb,
        title: "Innovation Continue",
        text: "Veille technologique et solutions innovantes pour garder votre avance.",
        color: "yellow.500"
    },
];

const stats = [
    { number: "100%", label: "Clients Satisfaits" },
    { number: "24/7", label: "Support Client" },
];

export default function CompanySection() {
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const bg = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.700');
    const btnSize = useBreakpointValue({ base: 'md', md: 'lg' });

    return (
        <Box as="section" py={{ base: '20', md: '28' }} bg={bg} position="relative">
            <Container maxW="8xl">
                <VStack spacing={16}>
                    {/* Hero Section */}
                    <VStack spacing={6} textAlign="center" maxW="3xl" mx="auto">
                        <Badge colorScheme="purple" fontSize="md" px={6} py={1} borderRadius="full">
                            Votre Partenaire Digital
                        </Badge>
                        <Heading
                            as="h1"
                            fontSize={{ base: '4xl', md: '5xl', lg: '5xl' }}
                            fontWeight="bold"
                            color={headingColor}
                            lineHeight="shorter"
                        >
                            Transformez Votre Vision en Réalité Digitale
                        </Heading>
                        <Text fontSize={{ base: 'lg', md: 'xl' }} color={textColor}>
                            Chez Rock Your Net, nous donnons vie à vos projets web avec expertise et créativité.
                            Notre équipe passionnée combine technologie de pointe et design innovant pour créer
                            des solutions digitales qui dépassent vos attentes.
                        </Text>
                    </VStack>

                    {/* Stats Section */}
                    <Flex
                        justify="center"
                        gap={8}
                        wrap="wrap"
                        w="full"
                    >
                        {stats.map((stat, index) => (
                            <MotionBox
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                                textAlign="center"
                            >
                                <Text
                                    fontSize={{ base: '4xl', md: '6xl' }}
                                    fontWeight="bold"
                                    color="purple.500"
                                >
                                    {stat.number}
                                </Text>
                                <Text fontSize="lg" color={textColor}>{stat.label}</Text>
                            </MotionBox>
                        ))}
                    </Flex>

                    {/* Features Grid */}
                    <Grid
                        templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
                        gap={8}
                        w="full"
                    >
                        {features.map((feature, index) => (
                            <MotionFlex
                                key={index}
                                direction="column"
                                align="start"
                                p={8}
                                bg={cardBg}
                                shadow="xl"
                                borderRadius="xl"
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.1 }}
                                whileHover={{ y: -5 }}
                            >
                                <Icon as={feature.icon} w={12} h={12} color={feature.color} mb={4} />
                                <Text fontSize="xl" fontWeight="bold" mb={2} color={headingColor}>
                                    {feature.title}
                                </Text>
                                <Text color={textColor}>
                                    {feature.text}
                                </Text>
                            </MotionFlex>
                        ))}
                    </Grid>

                    {/* Call to Action */}
                    <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>
                        <Button
                            as={RouterLink}
                            to="/Contact"
                            colorScheme="purple"
                            size={btnSize}
                            px={8}
                            py={7}
                            fontSize="lg"
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                        >
                            Démarrer votre projet
                        </Button>
                        <Button
                            as={RouterLink}
                            to="/Services"
                            variant="outline"
                            colorScheme="purple"
                            size={btnSize}
                            px={8}
                            py={7}
                            fontSize="lg"
                            _hover={{
                                transform: 'translateY(-2px)',
                                shadow: 'xl',
                                bg: "white",            // Couleur de fond blanche au survol
                                color: 'purple.600'
                            }}
                        >
                            Découvrir nos services
                        </Button>
                    </Flex>
                </VStack>
            </Container>
        </Box>
    );
}