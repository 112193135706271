import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Image,
    useColorModeValue,
    Badge,
    SimpleGrid,
    Icon,
    List,
    ListItem,
    ListIcon,
    Flex,
} from '@chakra-ui/react';
import { FaCheckCircle, FaCode, FaRocket, FaMobile, FaSync, FaRegFileAlt, FaPhone, FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../../imagesComponents/Logo_RockYourNet650.webp';
import Refonte from '../../imagesComponents/refonteAuxerre.webp';

const MotionBox = motion(Box);

export default function MainRefonteAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const headingColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('white', 'gray.700');
    const buttonBg = useColorModeValue('white', 'gray.800');
    const buttonText = useColorModeValue('purple.500', 'purple.300');
    const sectionSpacing = 20;

    return (
        <Box as="section" bg={bg} minH="100vh" fontSize="lg">
            <Container maxW="8xl" pt={{ base: 8, lg: 20 }} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={6} align="center" mb={28}>
                            <Badge colorScheme="purple" fontSize="md" px={6} py={1} borderRadius="full" mb={6}>
                                Expert en Refonte Web
                            </Badge>
                            <Heading as="h1" size="2xl" color={headingColor} textAlign="center">
                                Refonte Site Internet à Auxerre
                            </Heading>
                            <Image
                                src={Logo}
                                alt="Logo Rock Your Net"
                                width={{ base: "200px", md: "300px" }}
                                my={6}
                            />
                            <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                Modernisez Votre Présence en Ligne
                            </Heading>
                        </VStack>

                        <Text fontSize="xl" color={textColor} mb={12}>
                            Spécialiste de la <strong>refonte de site internet à Auxerre</strong>, Rock Your Net transforme votre site web obsolète en une plateforme moderne et performante. Notre expertise technique et notre approche créative vous garantissent une refonte réussie qui respecte votre identité tout en boostant vos performances.
                        </Text>
                    </Box>
                </VStack>
            </Container>

            <Container maxW="full" bg={cardBg}>
                <Container maxW="8xl" bg={cardBg} pt={10} pb={10}>
                    <VStack spacing={sectionSpacing} align="start">
                        <Box w="100%" py={12}>
                            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} alignItems="center">
                                <Box>
                                    <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                        Pourquoi Refondre Votre Site Web ?
                                    </Heading>
                                    <Text color={textColor} mb={8}>
                                        La <strong>refonte de votre site internet à Auxerre</strong> est essentielle pour maintenir votre compétitivité :
                                    </Text>
                                    <List spacing={6}>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Design moderne et responsive</strong> : Une expérience utilisateur optimale sur tous les appareils
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Performance améliorée</strong> : Des temps de chargement rapides pour retenir vos visiteurs
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>SEO optimisé</strong> : Un meilleur positionnement dans les moteurs de recherche
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Fonctionnalités modernes</strong> : Des outils adaptés à vos besoins actuels
                                            </Box>
                                        </ListItem>
                                    </List>
                                </Box>
                                <Flex justify="center" align="center">
                                    <Image
                                        src={Refonte}
                                        alt="Refonte site web illustration"
                                        width={{ base: "100%", lg: "60%" }}
                                        mt={{ base: "20px", lg: "0" }}
                                        maxW="500px"
                                        borderRadius="2xl"
                                        shadow="2xl"
                                    />
                                </Flex>
                            </SimpleGrid>
                            {/* Bouton CTA */}
                            <Flex justify="center" w="full" mt={16}>
                                <MotionBox
                                    as={RouterLink}
                                    to="/Services/Site-vitrine"
                                    bg={buttonBg}
                                    whileHover={{ scale: 1.02 }}
                                    p={4}
                                    px={8}
                                    border="2px solid"
                                    borderColor="purple.500"
                                    color={buttonText}
                                    borderRadius="lg"
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '1px solid',
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl'
                                    }}
                                    textAlign="center"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={3}
                                >
                                    <Icon as={FaSync} w={5} h={5} />
                                    <Text fontSize="md" fontWeight="medium">Découvrir Nos Services</Text>
                                </MotionBox>
                            </Flex>
                        </Box>
                    </VStack>
                </Container>
            </Container>

            <Container maxW="8xl" pt={20} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={20} align="start">
                            <Box w="full">
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Notre Processus de Refonte
                                </Heading>
                                <Text color={textColor} mb={16}>
                                    Notre approche de <strong>refonte de site web à Auxerre</strong> suit une méthodologie éprouvée pour garantir le succès de votre projet.
                                </Text>

                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={12}>
                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaCode} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Analyse Technique</Heading>
                                        <Text color={textColor}>
                                            Audit complet de votre site actuel et définition des objectifs de refonte
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaMobile} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Design Responsive</Heading>
                                        <Text color={textColor}>
                                            Création d'une interface moderne adaptée à tous les écrans
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaRocket} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Optimisation</Heading>
                                        <Text color={textColor}>
                                            Performance technique et référencement naturel optimisés
                                        </Text>
                                    </MotionBox>
                                </SimpleGrid>
                            </Box>

                            <Box>
                                <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                    Une Refonte Stratégique
                                </Heading>
                                <Text color={textColor}>
                                    La <strong>refonte de votre site internet à Auxerre</strong> va au-delà du simple relooking. Nous repensons intégralement votre présence en ligne pour l'adapter aux standards actuels du web et aux attentes de vos visiteurs. Notre expertise technique garantit une migration en douceur de votre contenu et une amélioration significative des performances.
                                </Text>
                            </Box>

                            <Box>
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Un Investissement Pour l'Avenir
                                </Heading>
                                <Text color={textColor}>
                                    Choisir Rock Your Net pour la <strong>refonte de votre site à Auxerre</strong>, c'est opter pour un partenaire qui comprend vos enjeux business. Nous créons des sites web modernes, évolutifs et performants qui vous permettront de développer votre activité en ligne pour les années à venir.
                                </Text>
                            </Box>

                            {/* Section CTA */}
                            <Box w="full" bg={cardBg} borderRadius="2xl" p={{ base: 4, lg: 12 }} mt={8}>
                                <VStack spacing={8} align="center">
                                    <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                        Prêt à Moderniser Votre Site Web ?
                                    </Heading>

                                    <Text fontSize="lg" color={textColor} textAlign="center" maxW="2xl">
                                        Discutons de votre projet et transformons votre site en une plateforme performante.
                                    </Text>

                                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full" maxW="4xl" mx="auto">
                                        <MotionBox
                                            as={RouterLink}
                                            to="/Services"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaRegFileAlt} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Services</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Créations"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaArrowRight} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Réalisations</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Contact"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaPhone} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Contactez-nous</Text>
                                        </MotionBox>
                                    </SimpleGrid>
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}