import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainRefonteAuxerre from '../../sections/référencement/MainRefonteAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function RefonteAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Refonte Site Internet à Auxerre | Rock Your Net</title>
                <meta name="description" content="Donnez un nouveau souffle à votre présence en ligne avec Rock Your Net. Experts en refonte de site internet à Auxerre : design moderne, performance et SEO optimisés." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="refonte site internet auxerre, refonte site web auxerre, modernisation site web auxerre, mise à jour site internet auxerre, relooking site web auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Refonte-site-auxerre" />

                {/* Open Graph */}
                <meta property="og:title" content="Refonte Site Internet à Auxerre | Rock Your Net" />
                <meta property="og:description" content="Transformez votre site web obsolète en une plateforme moderne et performante. Experts en refonte de sites internet à Auxerre." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Refonte-site-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Refonte Site Internet à Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Modernisez votre site web avec notre expertise en refonte de sites internet à Auxerre. Design responsive et optimisation SEO." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "name": "Refonte de Sites Internet Auxerre",
                        "provider": {
                            "@type": "Organization",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet.png"
                        },
                        "description": "Service professionnel de refonte de sites web à Auxerre. Modernisation, optimisation des performances et du référencement.",
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services de refonte web",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Refonte design responsive"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Optimisation des performances"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Migration de contenu"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainRefonteAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};