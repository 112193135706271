import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Image,
    useColorModeValue,
    Badge,
    SimpleGrid,
    Icon,
    List,
    ListItem,
    ListIcon,
    Flex,
} from '@chakra-ui/react';
import { FaCheckCircle, FaWordpress, FaPaintBrush, FaShoppingCart, FaCog, FaRegFileAlt, FaPhone, FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../../imagesComponents/Logo_RockYourNet650.webp';
import Wordpress from '../../imagesComponents/wordpress.webp';

const MotionBox = motion(Box);

export default function MainWordPressAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const headingColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('white', 'gray.700');
    const buttonBg = useColorModeValue('white', 'gray.800');
    const buttonText = useColorModeValue('purple.500', 'purple.300');
    const sectionSpacing = 20;

    return (
        <Box as="section" bg={bg} minH="100vh" fontSize="lg">
            <Container maxW="8xl" pt={{ base: 8, lg: 20 }} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={6} align="center" mb={28}>
                            <Badge colorScheme="purple" fontSize="md" px={6} py={1} borderRadius="full" mb={6}>
                                Expert WordPress
                            </Badge>
                            <Heading as="h1" size="2xl" color={headingColor} textAlign="center">
                                Création Site WordPress à Auxerre
                            </Heading>
                            <Image
                                src={Logo}
                                alt="Logo Rock Your Net"
                                width={{ base: "200px", md: "300px" }}
                                my={6}
                            />
                            <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                Des Sites Web Professionnels et Évolutifs
                            </Heading>
                        </VStack>

                        <Text fontSize="xl" color={textColor} mb={12}>
                            Spécialiste de la <strong>création de sites WordPress à Auxerre</strong>, Rock Your Net conçoit des sites web performants et faciles à gérer. Notre expertise WordPress vous garantit un site professionnel, sécurisé et parfaitement adapté à vos besoins.
                        </Text>
                    </Box>
                </VStack>
            </Container>

            <Container maxW="full" bg={cardBg}>
                <Container maxW="8xl" bg={cardBg} pt={10} pb={10}>
                    <VStack spacing={sectionSpacing} align="start">
                        <Box w="100%" py={12}>
                            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} alignItems="center">
                                <Box>
                                    <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                        Pourquoi Choisir WordPress ?
                                    </Heading>
                                    <Text color={textColor} mb={8}>
                                        Expert en <strong>développement WordPress à Auxerre</strong>, nous vous proposons :
                                    </Text>
                                    <List spacing={6}>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Interface intuitive</strong> : Gérez facilement votre contenu sans compétences techniques
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Design personnalisable</strong> : Des thèmes et mises en page sur mesure
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>SEO optimisé</strong> : Une structure idéale pour le référencement naturel
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Évolutivité maximale</strong> : Ajoutez des fonctionnalités selon vos besoins
                                            </Box>
                                        </ListItem>
                                    </List>
                                </Box>
                                <Flex justify="center" align="center">
                                    <Image
                                        src={Wordpress}
                                        alt="WordPress illustration"
                                        width={{ base: "100%", lg: "60%" }}
                                        mt={{ base: "20px", lg: "0" }}
                                        maxW="500px"
                                        borderRadius="2xl"
                                        shadow="2xl"
                                    />
                                </Flex>
                            </SimpleGrid>
                            <Flex justify="center" w="full" mt={16}>
                                <MotionBox
                                    as={RouterLink}
                                    to="/Services"
                                    bg={buttonBg}
                                    whileHover={{ scale: 1.02 }}
                                    p={4}
                                    px={8}
                                    border="2px solid"
                                    borderColor="purple.500"
                                    color={buttonText}
                                    borderRadius="lg"
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '1px solid',
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl'
                                    }}
                                    textAlign="center"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={3}
                                >
                                    <Icon as={FaWordpress} w={5} h={5} />
                                    <Text fontSize="md" fontWeight="medium">Découvrir nos Solutions WordPress</Text>
                                </MotionBox>
                            </Flex>
                        </Box>
                    </VStack>
                </Container>
            </Container>

            <Container maxW="8xl" pt={20} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={20} align="start">
                            <Box w="full">
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Nos Solutions WordPress
                                </Heading>
                                <Text color={textColor} mb={16}>
                                    Expert en <strong>création de sites WordPress à Auxerre</strong>, nous développons des solutions adaptées à chaque secteur d'activité. Notre maîtrise de WordPress et de ses extensions nous permet de créer des sites web sur mesure, performants et évolutifs.
                                </Text>

                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} mt={12}>
                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                        as={RouterLink}
                                        to="/Services/Site-vitrine"
                                        cursor="pointer"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaPaintBrush} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Site Vitrine</Heading>
                                        <List spacing={3} color={textColor}>
                                            <ListItem display="Flex" alignItems="center">
                                                <ListIcon as={FaCheckCircle} color="green.500" w={4} h={4} />
                                                <Text fontSize="sm">Design personnalisé</Text>
                                            </ListItem>
                                            <ListItem display="Flex" alignItems="center">
                                                <ListIcon as={FaCheckCircle} color="green.500" w={4} h={4} />
                                                <Text fontSize="sm">Responsive mobile</Text>
                                            </ListItem>
                                            <ListItem display="Flex" alignItems="center">
                                                <ListIcon as={FaCheckCircle} color="green.500" w={4} h={4} />
                                                <Text fontSize="sm">Optimisé SEO</Text>
                                            </ListItem>
                                        </List>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                        as={RouterLink}
                                        to="/Services/Site-e-commerce"
                                        cursor="pointer"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaShoppingCart} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Site E-commerce</Heading>
                                        <List spacing={3} color={textColor}>
                                            <ListItem display="Flex" alignItems="center">
                                                <ListIcon as={FaCheckCircle} color="green.500" w={4} h={4} />
                                                <Text fontSize="sm">WooCommerce intégré</Text>
                                            </ListItem>
                                            <ListItem display="Flex" alignItems="center">
                                                <ListIcon as={FaCheckCircle} color="green.500" w={4} h={4} />
                                                <Text fontSize="sm">Paiement sécurisé</Text>
                                            </ListItem>
                                            <ListItem display="Flex" alignItems="center">
                                                <ListIcon as={FaCheckCircle} color="green.500" w={4} h={4} />
                                                <Text fontSize="sm">Gestion des stocks</Text>
                                            </ListItem>
                                        </List>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                        as={RouterLink}
                                        to="/Services/ApplicationWeb"
                                        cursor="pointer"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaCog} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Site Sur Mesure</Heading>
                                        <List spacing={3} color={textColor}>
                                            <ListItem display="Flex" alignItems="center">
                                                <ListIcon as={FaCheckCircle} color="green.500" w={4} h={4} />
                                                <Text fontSize="sm">Fonctionnalités avancées</Text>
                                            </ListItem>
                                            <ListItem display="Flex" alignItems="center">
                                                <ListIcon as={FaCheckCircle} color="green.500" w={4} h={4} />
                                                <Text fontSize="sm">Plugins personnalisés</Text>
                                            </ListItem>
                                            <ListItem display="Flex" alignItems="center">
                                                <ListIcon as={FaCheckCircle} color="green.500" w={4} h={4} />
                                                <Text fontSize="sm">API & Intégrations</Text>
                                            </ListItem>
                                        </List>
                                    </MotionBox>
                                </SimpleGrid>
                            </Box>

                            <Box mt={8}>
                                <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                    Notre Expertise WordPress
                                </Heading>
                                <Text color={textColor} mb={8}>
                                    Spécialistes en <strong>création de sites WordPress à Auxerre</strong>, nous maîtrisons toutes les facettes de cette plateforme pour vous offrir une solution web complète et performante. De la conception à la maintenance, en passant par le développement sur mesure, nous vous garantissons un site web professionnel qui répond à vos objectifs.
                                </Text>
                                <Text color={textColor}>
                                    Grâce à WordPress, vous bénéficiez d'un site web facile à administrer, évolutif et optimisé pour le référencement. Notre expertise technique nous permet de personnaliser entièrement votre site pour qu'il corresponde parfaitement à votre image de marque et à vos besoins spécifiques.
                                </Text>
                            </Box>

                            <Box>
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Un Accompagnement Complet
                                </Heading>
                                <Text color={textColor}>
                                    Choisir Rock Your Net pour votre <strong>site WordPress à Auxerre</strong>, c'est opter pour un accompagnement personnalisé à chaque étape de votre projet. De la formation à l'utilisation de votre site jusqu'au suivi régulier des performances, nous sommes à vos côtés pour garantir le succès de votre présence en ligne.
                                </Text>
                            </Box>

                            {/* Section CTA */}
                            <Box w="full" bg={cardBg} borderRadius="2xl" p={{ base: 4, lg: 12 }} mt={8}>
                                <VStack spacing={8} align="center">
                                    <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                        Prêt à Créer Votre Site WordPress ?
                                    </Heading>

                                    <Text fontSize="lg" color={textColor} textAlign="center" maxW="2xl">
                                        Discutons de votre projet et créons ensemble le site web qui vous ressemble.
                                    </Text>

                                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full" maxW="4xl" mx="auto">
                                        <MotionBox
                                            as={RouterLink}
                                            to="/Services"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaWordpress} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Services</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Créations"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaArrowRight} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Réalisations</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Contact"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaPhone} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Contactez-nous</Text>
                                        </MotionBox>
                                    </SimpleGrid>
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}