import React from 'react';
import { Box, Container, Heading, Text, Flex, Link, SimpleGrid, useColorModeValue, Icon, Circle } from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

const MainCarteDuSite = () => {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.700');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const accentColor = useColorModeValue('orange.500', 'orange.400');

    const siteMap = [
        { title: 'Accueil', link: '/' },
        { title: 'Services', link: '/Services' },
        { title: 'Site Vitrine', link: '/Services/Site-vitrine' },
        { title: 'Site E-commerce', link: '/Services/Site-e-commerce' },
        { title: 'Référencement', link: '/Services/Referencement' },
        { title: 'Applications Web', link: '/Services/ApplicationWeb' },
        { title: 'Community Management', link: '/Services/Community-Management' },
        { title: 'Produits', link: '/Produits' },
        { title: 'Créations', link: '/Créations' },
        { title: 'Agence Communication Bourgogne', link: '/Agence-communication-bourgogne' },
        { title: 'Optimisation Web Auxerre', link: '/Optimisation-web-auxerre' },
        { title: 'Refonte Site Auxerre', link: '/Refonte-site-auxerre' },
        { title: 'Solutions E-commerce Yonne', link: '/Solutions-ecommerce-yonne' },
        { title: 'Communication Auxerre', link: '/Communication-Auxerre' },
        { title: 'Création de Site Auxerre', link: '/Creation-site-auxerre' },
        { title: 'Agence Web Auxerre', link: '/Agence-web-auxerre' },
        { title: 'Développement Web Auxerre', link: '/developpement-web-auxerre' },
        { title: 'Référencement Auxerre', link: '/Referencement-auxerre' },
        { title: 'E-commerce Auxerre', link: '/Ecommerce-auxerre' },
        { title: 'Marketing Digital Bourgogne', link: '/Marketing-digital-bourgogne' },
        { title: 'Maintenance Yonne', link: '/Maintenance-yonne' },
        { title: 'Agence Digitale Bourgogne', link: '/Agence-digitale-bourgogne' },
        { title: 'WordPress Auxerre', link: '/WordPress-auxerre' },
        { title: 'Community Management Auxerre', link: '/Community-management-auxerre' },
        { title: 'À Propos', link: '/A-propos' },
        { title: 'Contact', link: '/Contact' },
    ];

    return (
        <Box as="section" bg={bg} py={20}>
            <Container maxW="8xl">
                <Heading
                    size="2xl"
                    mb={8}
                    color={headingColor}
                    textAlign="center"
                >
                    Carte du Site
                </Heading>
                <Text
                    fontSize="lg"
                    color={textColor}
                    mb={16}
                    textAlign="center"
                >
                    Découvrez toutes les pages de notre site web et naviguez facilement.
                </Text>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4}>
                    {siteMap.map((page, index) => (
                        <Link
                            as={RouterLink}
                            to={page.link}
                            key={index}
                            display="block"
                            bg={cardBg}
                            px={8}
                            py={4}
                            borderRadius="lg"
                            shadow="xl"
                            transition="all 0.3s"
                            _hover={{
                                transform: 'translateY(-5px)',
                                shadow: '2xl'
                            }}
                        >
                            <Flex
                                justify="space-between"
                                align="center"

                            >
                                <Heading size="md" color={headingColor}>
                                    {page.title}
                                </Heading>
                                <Circle size="40px" bg={accentColor}>
                                    <Icon as={FaArrowRight} color="white" />
                                </Circle>
                            </Flex>
                        </Link>
                    ))}
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default MainCarteDuSite;