import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainCommunicationBourgogne from '../../sections/référencement/MainCommunicationBourgogne';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function CommunicationBourgogne() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Agence de Communication en Bourgogne | Rock Your Net</title>
                <meta name="description" content="Rock Your Net, votre agence de communication digitale en Bourgogne. Expertise en stratégie digitale, création web et marketing digital pour les entreprises bourguignonnes." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="agence communication Bourgogne, agence digitale Bourgogne, création site web Bourgogne, référencement Bourgogne, marketing digital Bourgogne, communication entreprise Bourgogne" />
                <link rel="canonical" href="https://rockyournet.com/Communication-bourgogne" />

                {/* Open Graph */}
                <meta property="og:title" content="Agence de Communication en Bourgogne | Rock Your Net" />
                <meta property="og:description" content="Rock Your Net, agence de communication digitale en Bourgogne. Experts en stratégie web et communication digitale pour développer votre entreprise." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Communication-bourgogne" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Agence de Communication en Bourgogne | Rock Your Net" />
                <meta name="twitter:description" content="Développez votre présence en ligne en Bourgogne avec Rock Your Net. Experts en stratégie web et marketing digital." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "MarketingAgency",
                        "name": "Rock Your Net - Agence de Communication Bourgogne",
                        "image": "https://rockyournet.com/static/media/logorockyournet.png",
                        "description": "Agence de communication digitale en Bourgogne spécialisée en création web, référencement et stratégie digitale.",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "14 Place de l'Hôtel de Ville",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "postalCode": "89000",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": 47.7979,
                            "longitude": 3.5695
                        },
                        "areaServed": {
                            "@type": "State",
                            "name": "Bourgogne-Franche-Comté"
                        },
                        "url": "https://rockyournet.com/Communication-bourgogne",
                        "telephone": "+33624311075",
                        "priceRange": "€€",
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainCommunicationBourgogne />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};