import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Button,
    useColorModeValue,
    Image,
    VStack,
    HStack,
    Badge,
    List,
    ListItem,
    ListIcon,
    Divider,
    useBreakpointValue,
} from '@chakra-ui/react';
import {
    FaCheck,
    FaMobileAlt,
    FaSearch,
    FaCog,
    FaClock,
    FaRocket,
    FaPaintBrush,
    FaChartLine,
    FaShieldAlt,
    FaTools,
    FaArrowRight,
    FaCommentAlt,
    FaUserFriends,
    FaChartBar,
    FaInstagram,
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import communitymanagement from '../imagesComponents/communitymanagement.webp';
import Logo from '../imagesComponents/Logo_RockYourNet650.webp';
import { Link as RouterLink } from 'react-router-dom';

const MotionBox = motion(Box);

// Fonctionnalités enrichies
const features = [
    {
        id: 1,
        title: "Gestion des Réseaux Sociaux",
        description: "Nous prenons en charge la création et la gestion de vos comptes sur les principaux réseaux sociaux pour développer votre présence en ligne.",
        icon: FaCommentAlt,
        color: "blue.400",
        details: [
            "Planification de contenu",
            "Interaction avec vos abonnés",
            "Analyse des performances",
            "Optimisation des publications"
        ]
    },
    {
        id: 2,
        title: "Marketing de Contenu",
        description: "Nous créons et diffusons du contenu engageant sur vos canaux digitaux pour attirer de nouveaux prospects et fidéliser vos clients.",
        icon: FaPaintBrush,
        color: "purple.400",
        details: [
            "Rédaction de blogs et articles",
            "Conception de visuels attrayants",
            "Stratégie de partage de contenu",
            "Analyse de l'engagement"
        ]
    },
    {
        id: 3,
        title: "Analyse et Reporting",
        description: "Nous vous fournissons des rapports détaillés sur les performances de vos campagnes pour vous aider à prendre les meilleures décisions stratégiques.",
        icon: FaChartBar,
        color: "green.400",
        details: [
            "Tableaux de bord personnalisés",
            "Métriques clés en temps réel",
            "Benchmarking concurrentiel",
            "Recommandations d'amélioration"
        ]
    },
    {
        id: 4,
        title: "Publicité Digitale",
        description: "Nous mettons en place et optimisons vos campagnes publicitaires sur les réseaux sociaux et les moteurs de recherche pour générer des leads qualifiés.",
        icon: FaSearch,
        color: "orange.400",
        details: [
            "Ciblage publics pertinents",
            "Création de visuels et annonces",
            "Gestion et optimisation des campagnes",
            "Reporting des performances"
        ]
    }
];

// Processus de création
const process = [
    {
        title: "Audit et Stratégie",
        description: "Analyse approfondie de votre présence en ligne et définition d'une stratégie sur-mesure",
        icon: FaCog,
        duration: "2 semaines"
    },
    {
        title: "Création de Contenu",
        description: "Développement de contenus engageants pour vos réseaux sociaux",
        icon: FaUserFriends,
        duration: "3-4 semaines"
    },
    {
        title: "Gestion des Réseaux",
        description: "Publication, interaction et suivi des performances des publications",
        icon: FaCommentAlt,
        duration: "Continu"
    },
    {
        title: "Suivi et Optimisation",
        description: "Analyse des métriques et ajustement de la stratégie au fil du temps",
        icon: FaChartLine,
        duration: "Mensuel"
    }
];

// Avantages clés
const benefits = [
    "Développer votre notoriété en ligne",
    "Attirer de nouveaux prospects",
    "Fidéliser votre communauté",
    "Générer du trafic qualifié",
    "Booster vos ventes et conversions",
    "Optimiser votre présence digitale"
];

// Garanties
const guarantees = [
    "Stratégie sur-mesure garantie",
    "Contenu créatif et engageant",
    "Amélioration continue des performances",
    "Rapports et analyses détaillés"
];

export default function CommunityManagement() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const statBg = useColorModeValue('purple.50', 'purple.900');

    const imageWidth = useBreakpointValue({ base: '300px', md: '450px', lg: '500px' });

    return (
        <Box as="section" bg={bg} minH="100vh">
            {/* Hero section améliorée */}
            <Container maxW="8xl" pt={{ base: 20, md: 28 }} pb={{ base: 16, md: 24 }}>
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16} alignItems="center">
                    <MotionBox
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <VStack align={{ base: 'center', md: 'center' }} spacing={8}>
                            <Badge
                                colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm"
                            >
                                Community Management Pro
                            </Badge>
                            <Heading
                                lineHeight={1.2}
                                fontWeight="bold"
                                fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                                color={headingColor}
                            >
                                Développez votre présence digitale avec
                                <Flex mt={4} justifyContent="center">
                                    <Image
                                        src={Logo}
                                        alt="Logo Rock Your Net"
                                        width={{ base: '200px', md: '280px' }}
                                    />
                                </Flex>
                            </Heading>
                            <Text color={textColor} fontSize="xl" lineHeight="tall">
                                Nous vous accompagnons dans la gestion stratégique de vos réseaux sociaux
                                pour attirer de nouveaux clients et fidéliser votre communauté.
                            </Text>

                            <SimpleGrid columns={{ base: 2, xl: 4 }} spacing={2}>
                                <Box
                                    bg={cardBg}
                                    p={6}
                                    borderRadius="xl"
                                    shadow="xl"
                                    transition="transform 0.3s ease"
                                    _hover={{ transform: 'translateY(-5px)' }}
                                >
                                    <Flex justify="center" align="center" mb={4}>
                                        <Icon as={FaFacebookF} w={10} h={10} color="orange.500" />
                                    </Flex>
                                    <Heading as="h4" size="md" align="center" mb={2} color={headingColor}>
                                        Facebook
                                    </Heading>
                                </Box>

                                <Box
                                    bg={cardBg}
                                    p={6}
                                    borderRadius="xl"
                                    shadow="xl"
                                    transition="transform 0.3s ease"
                                    _hover={{ transform: 'translateY(-5px)' }}
                                >
                                    <Flex justify="center" align="center" mb={4}>
                                        <Icon as={FaInstagram} w={10} h={10} color="orange.500" />
                                    </Flex>
                                    <Heading as="h4" size="md" align="center" mb={2} color={headingColor}>
                                        Instagram
                                    </Heading>
                                </Box>

                                <Box
                                    bg={cardBg}
                                    p={6}
                                    borderRadius="xl"
                                    shadow="xl"
                                    transition="transform 0.3s ease"
                                    _hover={{ transform: 'translateY(-5px)' }}
                                >
                                    <Flex justify="center" align="center" mb={4}>
                                        <Icon as={FaLinkedinIn} w={10} h={10} color="orange.500" />
                                    </Flex>
                                    <Heading as="h4" size="md" align="center" mb={2} color={headingColor}>
                                        LinkedIn
                                    </Heading>
                                </Box>

                                <Box
                                    bg={cardBg}
                                    p={6}
                                    borderRadius="xl"
                                    shadow="xl"
                                    transition="transform 0.3s ease"
                                    _hover={{ transform: 'translateY(-5px)' }}
                                >
                                    <Flex justify="center" align="center" mb={4}>
                                        <Icon as={FaTwitter} w={10} h={10} color="orange.500" />
                                    </Flex>
                                    <Heading as="h4" size="md" align="center" mb={2} color={headingColor}>
                                        Twitter
                                    </Heading>
                                </Box>
                            </SimpleGrid>

                            <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>
                                <Button
                                    as={RouterLink}
                                    to="/Contact"
                                    colorScheme="purple"
                                    size="lg"
                                    rightIcon={<FaRocket />}
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '1px solid',
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl'
                                    }}
                                >
                                    Démarrer Votre Projet
                                </Button>
                                <Button
                                    as={RouterLink}
                                    to="/Services"
                                    variant="outline"
                                    colorScheme="purple"
                                    size="lg"
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '1px solid',
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl'
                                    }}
                                >
                                    En Savoir Plus
                                </Button>
                            </Flex>
                        </VStack>
                    </MotionBox>

                    <MotionBox
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        display="flex"
                        justifyContent="center"
                    >
                        <Image
                            src={communitymanagement}
                            alt="Community Management"
                            width={imageWidth}
                            objectFit="cover"
                            borderRadius="2xl"
                            shadow="2xl"
                        />
                    </MotionBox>
                </SimpleGrid>
            </Container>

            {/* Section des fonctionnalités */}
            <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Nos Services</Badge>
                            <Heading
                                fontSize={{ base: '2xl', md: '4xl' }}
                                color={headingColor}
                            >
                                Tout ce qu'il faut pour prospérer sur les réseaux sociaux
                            </Heading>
                            <Text
                                fontSize="lg"
                                color={textColor}
                                maxW="3xl"
                            >
                                Découvrez nos services de community management pour booster votre visibilité et votre engagement en ligne.
                            </Text>
                        </VStack>

                        <SimpleGrid
                            columns={{ base: 1, md: 2, lg: 4 }}
                            spacing={8}
                            w="full"
                        >
                            {features.map((feature, index) => (
                                <MotionBox
                                    key={feature.id}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.2 }}
                                >
                                    <Box
                                        bg={bg}
                                        p={8}
                                        borderRadius="xl"
                                        shadow="xl"
                                        height="full"
                                        _hover={{
                                            transform: 'translateY(-5px)',
                                            shadow: '2xl',
                                        }}
                                        transition="all 0.3s"
                                    >
                                        <VStack align="start" spacing={5}>
                                            <Box
                                                bg={feature.color}
                                                w={12}
                                                h={12}
                                                borderRadius="lg"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Icon
                                                    as={feature.icon}
                                                    color="white"
                                                    w={6}
                                                    h={6}
                                                />
                                            </Box>

                                            <Heading
                                                as="h3"
                                                fontSize="xl"
                                                color={headingColor}
                                            >
                                                {feature.title}
                                            </Heading>

                                            <Text color={textColor}>
                                                {feature.description}
                                            </Text>

                                            <List spacing={2}>
                                                {feature.details.map((detail, idx) => (
                                                    <ListItem
                                                        key={idx}
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <ListIcon
                                                            as={FaCheck}
                                                            color={feature.color}
                                                        />
                                                        <Text fontSize="sm" color={textColor}>
                                                            {detail}
                                                        </Text>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </VStack>
                                    </Box>
                                </MotionBox>
                            ))}
                        </SimpleGrid>
                        <Box w="full" mt={16}>
                            <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                Nos Services Social Media
                            </Heading>
                            <Text color={textColor} mb={16}>
                                Notre expertise en <strong>community management à Auxerre</strong> couvre l'ensemble des réseaux sociaux pour une communication digitale à 360°.
                            </Text>

                            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={12}>
                                <MotionBox
                                    whileHover={{ scale: 1.05 }}
                                    p={8}
                                    bg={bg}
                                    borderRadius="xl"
                                    shadow="xl"
                                >
                                    <Flex justify="center">
                                        <Icon as={FaInstagram} w={12} h={12} color="orange.500" mb={6} />
                                        <Icon as={FaFacebookF} w={12} h={10} mt="3px" color="orange.500" mb={6} />
                                    </Flex>
                                    <Heading as="h4" size="md" mb={4} color={headingColor}>
                                        Instagram & Facebook
                                    </Heading>
                                    <Text color={textColor}>
                                        Stratégie de contenu visuel, stories, reels et publications engageantes pour développer votre communauté
                                    </Text>
                                </MotionBox>

                                <MotionBox
                                    whileHover={{ scale: 1.05 }}
                                    p={8}
                                    bg={bg}
                                    borderRadius="xl"
                                    shadow="xl"
                                >
                                    <Flex justify="center">
                                        <Icon as={FaLinkedinIn} w={12} h={12} color="orange.500" mb={6} />
                                    </Flex>
                                    <Heading as="h4" size="md" mb={4} color={headingColor}>
                                        LinkedIn
                                    </Heading>
                                    <Text color={textColor}>
                                        Communication B2B professionnelle, personal branding et développement de votre réseau professionnel
                                    </Text>
                                </MotionBox>

                                <MotionBox
                                    whileHover={{ scale: 1.05 }}
                                    p={8}
                                    bg={bg}
                                    borderRadius="xl"
                                    shadow="xl"
                                >
                                    <Flex justify="center">
                                        <Icon as={FaTwitter} w={12} h={12} color="orange.500" mb={6} />
                                    </Flex>
                                    <Heading as="h4" size="md" mb={4} color={headingColor}>
                                        Twitter
                                    </Heading>
                                    <Text color={textColor}>
                                        Veille sectorielle, communication instantanée et interaction avec votre communauté en temps réel
                                    </Text>
                                </MotionBox>
                            </SimpleGrid>
                        </Box>
                    </VStack>
                </Container>
            </Box>

            {/* Processus de création */}
            <Container maxW="8xl" py={20}>
                <VStack spacing={12}>
                    <VStack spacing={4} textAlign="center">
                        <Badge colorScheme="purple"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm">Notre Approche</Badge>
                        <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                            Comment nous gérons votre communauté
                        </Heading>
                        <Text fontSize="lg" color={textColor} maxW="3xl">
                            Un processus itératif et transparent pour développer votre présence en ligne
                        </Text>
                    </VStack>

                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
                        {process.map((step, index) => (
                            <MotionBox
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                            >
                                <Box
                                    bg={cardBg}
                                    p={8}
                                    h={{ base: 'auto', lg: '350px', xl: '270px' }}
                                    borderRadius="xl"
                                    shadow="xl"
                                    position="relative"
                                >
                                    <VStack spacing={4} align="start">
                                        <Box
                                            bg="purple.500"
                                            color="white"
                                            borderRadius="full" w={10}
                                            h={10}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            fontSize="lg"
                                            fontWeight="bold"
                                        >
                                            {index + 1}
                                        </Box>
                                        <Heading
                                            as="h3"
                                            fontSize="xl"
                                            color={headingColor}
                                        >
                                            {step.title}
                                        </Heading>
                                        <Text color={textColor}>
                                            {step.description}
                                        </Text>

                                        <HStack color="orange.500"
                                            position={{ base: 'relative', lg: 'absolute', xl: 'absolute' }}
                                            bottom={{ base: '0', lg: '30px', xl: '30px' }} >
                                            <Icon as={FaClock} />
                                            <Text fontSize="sm" fontWeight="medium">
                                                {step.duration}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                    {index < process.length - 1 && (
                                        <Box
                                            display={{ base: 'none', lg: 'block' }}
                                            position="absolute"
                                            right="-60%"
                                            top="50%"
                                            w="60%"
                                            h="2px"
                                            bg="purple.500"
                                            zIndex={0}
                                        />
                                    )}
                                </Box>
                            </MotionBox>
                        ))}
                    </SimpleGrid>
                </VStack>
            </Container>

            {/* Avantages et Garanties */}
            <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16}>
                        {/* Avantages */}
                        <VStack align="start" spacing={8}>
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Avantages</Badge>
                            <Heading fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
                                Pourquoi confier votre community management ?
                            </Heading>
                            <List spacing={4}>
                                {benefits.map((benefit, index) => (
                                    <ListItem
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <ListIcon
                                            as={FaCheck}
                                            color="green.500"
                                            w={5}
                                            h={5}
                                        />
                                        <Text color={textColor} fontSize="lg">
                                            {benefit}
                                        </Text>
                                    </ListItem>
                                ))}
                            </List>
                        </VStack>

                        {/* Garanties */}
                        <VStack align="start" spacing={8}>
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Nos Garanties</Badge>
                            <Heading fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
                                Notre engagement qualité
                            </Heading>
                            <List spacing={4}>
                                {guarantees.map((guarantee, index) => (
                                    <ListItem
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <ListIcon
                                            as={FaShieldAlt}
                                            color="orange.500"
                                            w={5}
                                            h={5}
                                        />
                                        <Text color={textColor} fontSize="lg">
                                            {guarantee}
                                        </Text>
                                    </ListItem>
                                ))}
                            </List>
                        </VStack>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* CTA Final */}
            <Container maxW="3xl" py={20}>
                <VStack spacing={8} textAlign="center">
                    <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                        Prêt à booster votre présence en ligne ?
                    </Heading>
                    <Text fontSize="lg" color={textColor}>
                        Faites confiance à nos experts pour développer votre communauté et générer plus de leads.
                    </Text>
                    <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>
                        <Button
                            as={RouterLink}
                            to="/Contact"
                            colorScheme="purple"
                            size="lg"
                            rightIcon={<FaRocket />}
                            _hover={{
                                bg: 'white',
                                color: 'purple.600',
                                border: '1px solid',
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl'
                            }}
                        >
                            Démarrer Votre Projet
                        </Button>
                        <Button
                            as={RouterLink}
                            to="/Services"
                            variant="outline"
                            colorScheme="purple"
                            size="lg"
                            _hover={{
                                bg: 'white',
                                color: 'purple.600',
                                border: '1px solid',
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl'
                            }}
                        >
                            En Savoir Plus
                        </Button>
                    </Flex>
                </VStack>
            </Container>
        </Box >
    );
}