import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainApplicationAuxerre from '../../sections/référencement/MainApplicationAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function ApplicationAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Création Application Web à Auxerre | Rock Your Net</title>
                <meta name="description" content="Rock Your Net développe vos applications web sur mesure à Auxerre. Experts en solutions digitales innovantes pour votre entreprise." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="création application web Auxerre, développement application Auxerre, application métier Auxerre, développeur web Auxerre, application sur mesure Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Application-web-auxerre" />

                {/* Open Graph */}
                <meta property="og:title" content="Création Application Web à Auxerre | Rock Your Net" />
                <meta property="og:description" content="Expert en développement d'applications web à Auxerre. Solutions digitales sur mesure et innovantes pour votre entreprise." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Application-web-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Création Application Web à Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Développement d'applications web professionnelles à Auxerre. Solutions personnalisées pour optimiser vos processus métier." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebApplication",
                        "name": "Rock Your Net - Création Applications Web Auxerre",
                        "applicationCategory": "Custom Business Applications",
                        "offers": {
                            "@type": "Offer",
                            "description": "Développement d'applications web sur mesure"
                        },
                        "provider": {
                            "@type": "Organization",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet.png",
                            "description": "Agence spécialisée en développement d'applications web à Auxerre",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "postalCode": "89000",
                                "addressCountry": "FR"
                            }
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainApplicationAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};