import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Image,
    useColorModeValue,
    Badge,
    SimpleGrid,
    Icon,
    List,
    ListItem,
    ListIcon,
    Flex,
} from '@chakra-ui/react';
import { FaCheckCircle, FaSearch, FaChartLine, FaMapMarkerAlt, FaCog, FaRegFileAlt, FaPhone, FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../../imagesComponents/Logo_RockYourNet650.webp';
import SEO from '../../imagesComponents/seo.jpg';

const MotionBox = motion(Box);

export default function MainReferencementAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const headingColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('white', 'gray.700');
    const buttonBg = useColorModeValue('white', 'gray.800');
    const buttonText = useColorModeValue('purple.500', 'purple.300');
    const sectionSpacing = 20;

    return (
        <Box as="section" bg={bg} minH="100vh" fontSize="lg">
            <Container maxW="8xl" pt={{ base: 8, lg: 20 }} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={6} align="center" mb={28}>
                            <Badge colorScheme="purple" fontSize="md" px={6} py={1} borderRadius="full" mb={6}>
                                Expert SEO Local
                            </Badge>
                            <Heading as="h1" size="2xl" color={headingColor} textAlign="center">
                                Référencement SEO à Auxerre
                            </Heading>
                            <Image
                                src={Logo}
                                alt="Logo Rock Your Net"
                                width={{ base: "200px", md: "300px" }}
                                my={6}
                            />
                            <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                Boostez Votre Visibilité sur Google
                            </Heading>
                        </VStack>

                        <Text fontSize="xl" color={textColor} mb={12}>
                            Expert en <strong>référencement SEO à Auxerre</strong>, Rock Your Net optimise votre présence en ligne pour atteindre les premiers résultats de Google. Notre expertise locale et nos stratégies SEO personnalisées vous garantissent une visibilité accrue auprès de vos clients potentiels.
                        </Text>


                    </Box>
                </VStack>
            </Container>

            <Container maxW="full" bg={cardBg}>
                <Container maxW="8xl" bg={cardBg} pt={10} pb={10}>
                    <VStack spacing={sectionSpacing} align="start">
                        <Box w="100%" py={12}>
                            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} alignItems="center">
                                <Box>
                                    <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                        Notre Approche du SEO Local
                                    </Heading>
                                    <Text color={textColor} mb={8}>
                                        En tant qu'expert du <strong>référencement à Auxerre</strong>, nous développons des stratégies sur mesure :
                                    </Text>
                                    <List spacing={6}>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box as={RouterLink} to="/Services/Referencement" color={textColor}>
                                                <strong>Audit SEO complet</strong> : Analyse approfondie de votre site et de sa visibilité
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Optimisation technique</strong> : Performance, vitesse et structure optimales
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Contenu optimisé</strong> : Rédaction SEO et stratégie de mots-clés locale
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Suivi des performances</strong> : Rapports détaillés et ajustements continus
                                            </Box>
                                        </ListItem>
                                    </List>
                                </Box>
                                <Flex justify="center" align="center">
                                    <Image
                                        src={SEO}
                                        alt="Référencement SEO illustration"
                                        width={{ base: "100%", lg: "60%" }}
                                        mt={{ base: "20px", lg: "0" }}
                                        maxW="500px"
                                        borderRadius="2xl"
                                        shadow="2xl"
                                    />
                                </Flex>
                            </SimpleGrid>
                            {/* Ajout du bouton */}
                            <Flex justify="center" w="full" mt={16}>
                                <MotionBox
                                    as={RouterLink}
                                    to="/Services/Referencement"
                                    bg={buttonBg}
                                    whileHover={{ scale: 1.02 }}
                                    p={4}
                                    px={8}
                                    border="2px solid"
                                    borderColor="purple.500"
                                    color={buttonText}
                                    borderRadius="lg"
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '1px solid',
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl'
                                    }}
                                    textAlign="center"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={3}
                                >
                                    <Icon as={FaSearch} w={5} h={5} />
                                    <Text fontSize="md" fontWeight="medium">Découvrir nos Services SEO</Text>
                                </MotionBox>
                            </Flex>
                        </Box>
                    </VStack>
                </Container>
            </Container>

            <Container maxW="8xl" pt={20} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={20} align="start">
                            <Box w="full">
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Services SEO Complets
                                </Heading>
                                <Text color={textColor} mb={16}>
                                    Notre expertise en <strong>référencement SEO à Auxerre</strong> couvre tous les aspects nécessaires pour améliorer votre visibilité en ligne.
                                </Text>

                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={12}>
                                    <MotionBox
                                        as={RouterLink}
                                        to="/Services/Referencement"
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                        cursor="pointer"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaSearch} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>SEO On-Page</Heading>
                                        <Text color={textColor}>
                                            Optimisation complète de votre site pour les moteurs de recherche
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaMapMarkerAlt} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>SEO Local</Heading>
                                        <Text color={textColor}>
                                            Visibilité renforcée dans la région d'Auxerre et ses environs
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaChartLine} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Analyse et Suivi</Heading>
                                        <Text color={textColor}>
                                            Monitoring constant et optimisation continue des performances
                                        </Text>
                                    </MotionBox>
                                </SimpleGrid>
                            </Box>

                            <Box mt={8}>
                                <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                    Pourquoi Choisir Notre Expertise SEO ?
                                </Heading>
                                <Text color={textColor} mb={8}>
                                    Notre expertise en <strong>référencement SEO à Auxerre</strong> se distingue par une approche stratégique et personnalisée. <Text as={RouterLink} to="/Services/Referencement" color="purple.500" _hover={{ textDecoration: 'underline' }}>Découvrez nos services SEO détaillés</Text> pour des résultats mesurables et durables.
                                </Text>
                                <Text color={textColor}>
                                    Notre équipe d'experts SEO utilise des techniques conformes aux dernières recommandations de Google pour assurer une progression constante de votre visibilité. Nous adaptons notre stratégie en fonction de votre secteur d'activité et de votre zone géographique pour maximiser votre impact local.
                                </Text>
                            </Box>

                            <Box>
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Le SEO, Un Investissement Rentable
                                </Heading>
                                <Text color={textColor}>
                                    Investir dans le <strong>référencement naturel à Auxerre</strong> est la clé pour développer durablement votre présence en ligne. Contrairement à la publicité payante, le SEO offre des résultats à long terme et un meilleur retour sur investissement. Chez Rock Your Net, nous vous accompagnons dans cette démarche avec transparence et professionnalisme.
                                </Text>
                            </Box>

                            {/* Section CTA */}
                            <Box w="full" bg={cardBg} borderRadius="2xl" p={{ base: 4, lg: 12 }} mt={8}>
                                <VStack spacing={8} align="center">
                                    <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                        Prêt à Améliorer Votre Visibilité en Ligne ?
                                    </Heading>

                                    <Text fontSize="lg" color={textColor} textAlign="center" maxW="2xl">
                                        Discutons de votre projet et définissons ensemble la meilleure stratégie SEO pour votre entreprise.
                                    </Text>

                                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full" maxW="4xl" mx="auto">
                                        <MotionBox
                                            as={RouterLink}
                                            to="/Services"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaRegFileAlt} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Services</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Créations"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaArrowRight} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Réalisations</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Contact"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaPhone} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Contactez-nous</Text>
                                        </MotionBox>
                                    </SimpleGrid>
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}