import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    List,
    ListItem,
    Link,
    VStack,
    HStack,
    Icon,
    Badge,
    Divider,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    FaBuilding,
    FaUser,
    FaEnvelope,
    FaServer,
    FaShieldAlt,
    FaFileContract,
    FaUserShield,
    FaCopyright,
    FaCookie,
    FaGavel,
    FaPhone,
    FaCheck,
} from 'react-icons/fa';

const LegalMentions = () => {
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const bgSection = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const accentColor = useColorModeValue('purple.500', 'purple.300');

    return (
        <Box as="section" py={{ base: 8, md: 16 }}>
            <Container maxW="7xl">
                <VStack spacing={12} align="stretch">
                    {/* Header Section */}
                    <Box textAlign="center" mb={8}>
                        <Badge
                            colorScheme="purple"
                            fontSize="md"
                            px={6}
                            py={1}
                            borderRadius="full"
                            mb={4}
                        >
                            Documentation Légale
                        </Badge>
                        <Heading
                            as="h1"
                            fontSize={{ base: '3xl', md: '4xl' }}
                            fontWeight="bold"
                            color={headingColor}
                            mb={4}
                        >
                            Mentions Légales
                        </Heading>
                        <Text
                            fontSize={{ base: 'lg', md: 'xl' }}
                            color={textColor}
                            maxW="3xl"
                            mx="auto"
                        >
                            En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site <strong>Rock Your Net</strong> l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
                        </Text>
                    </Box>

                    {/* Main Content */}
                    <VStack spacing={8} align="stretch">
                        {/* Section 1 - Présentation */}
                        <Box
                            p={8}
                            bg={bgSection}
                            borderRadius="xl"
                            borderWidth="1px"
                            borderColor={borderColor}
                            _hover={{ shadow: 'lg' }}
                            transition="all 0.3s"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon as={FaBuilding} w={6} h={6} color={accentColor} />
                                <Heading as="h2" fontSize="2xl" color={headingColor}>
                                    1. Présentation du site
                                </Heading>
                            </HStack>
                            <List spacing={4} color={textColor}>
                                <ListItem>
                                    <HStack align="start" spacing={4}>
                                        <Icon as={FaBuilding} mt={1} color={accentColor} />
                                        <Text><strong>Propriétaire :</strong> RockYourNet – SIRET 93238667500015 – 13 Rue de l'Abbé Grégoire, 89290 Escolives-Sainte-Camille, France</Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack align="start" spacing={4}>
                                        <Icon as={FaUser} mt={1} color={accentColor} />
                                        <Text><strong>Créateur :</strong> Colas Vincendon</Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack align="start" spacing={4}>
                                        <Icon as={FaEnvelope} mt={1} color={accentColor} />
                                        <Text><strong>Responsable publication :</strong> Colas Vincendon – contact@rockyournet.com</Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack align="start" spacing={4}>
                                        <Icon as={FaUser} mt={1} color={accentColor} />
                                        <Text><strong>Webmaster :</strong> Colas Vincendon – contact@rockyournet.com</Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack align="start" spacing={4}>
                                        <Icon as={FaServer} mt={1} color={accentColor} />
                                        <Text><strong>Hébergeur :</strong> O2Switch – 222 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, France – Téléphone : <Link href="tel:+33444446040" color={accentColor}>+33 4 44 44 60 40</Link></Text>
                                    </HStack>
                                </ListItem>
                            </List>
                        </Box>

                        {/* Section 2 - Conditions générales */}
                        <Box
                            p={8}
                            bg={bgSection}
                            borderRadius="xl"
                            borderWidth="1px"
                            borderColor={borderColor}
                            _hover={{ shadow: 'lg' }}
                            transition="all 0.3s"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon as={FaFileContract} w={6} h={6} color={accentColor} />
                                <Heading as="h2" fontSize="2xl" color={headingColor}>
                                    2. Conditions générales d'utilisation du site et des services proposés
                                </Heading>
                            </HStack>
                            <Text color={textColor}>
                                L'utilisation du site <strong>www.rockyournet.com</strong> implique l'acceptation pleine et entière des conditions générales d'utilisation ci-après décrites. Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter de manière régulière.
                            </Text>
                        </Box>

                        {/* Section 3 - Services */}
                        <Box
                            p={8}
                            bg={bgSection}
                            borderRadius="xl"
                            borderWidth="1px"
                            borderColor={borderColor}
                            _hover={{ shadow: 'lg' }}
                            transition="all 0.3s"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon as={FaServer} w={6} h={6} color={accentColor} />
                                <Heading as="h2" fontSize="2xl" color={headingColor}>
                                    3. Description des services fournis
                                </Heading>
                            </HStack>
                            <Text color={textColor}>
                                Le site <strong>www.rockyournet.com</strong> a pour objet de fournir une information concernant l'ensemble des activités de la société. Rock Your Net s'efforce de fournir sur le site des informations aussi précises que possible mais ne saurait être tenu responsable des omissions, inexactitudes et carences dans la mise à jour.
                            </Text>
                        </Box>

                        {/* Section 4 - Propriété intellectuelle */}
                        <Box
                            p={8}
                            bg={bgSection}
                            borderRadius="xl"
                            borderWidth="1px"
                            borderColor={borderColor}
                            _hover={{ shadow: 'lg' }}
                            transition="all 0.3s"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon as={FaCopyright} w={6} h={6} color={accentColor} />
                                <Heading as="h2" fontSize="2xl" color={headingColor}>
                                    4. Propriété intellectuelle et contrefaçons
                                </Heading>
                            </HStack>
                            <Text color={textColor}>
                                Rock Your Net est propriétaire des droits de propriété intellectuelle ou détient les droits d'usage sur tous les éléments accessibles sur le site. Toute reproduction, modification ou adaptation de tout ou partie du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable.
                            </Text>
                        </Box>

                        {/* Sections 5 à 8 avec le même modèle */}
                        {/* Section 5 - Responsabilité */}
                        <Box
                            p={8}
                            bg={bgSection}
                            borderRadius="xl"
                            borderWidth="1px"
                            borderColor={borderColor}
                            _hover={{ shadow: 'lg' }}
                            transition="all 0.3s"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon as={FaShieldAlt} w={6} h={6} color={accentColor} />
                                <Heading as="h2" fontSize="2xl" color={headingColor}>
                                    5. Limitations de responsabilité
                                </Heading>
                            </HStack>
                            <Text color={textColor}>
                                Rock Your Net ne pourra être tenu responsable des dommages directs ou indirects causés au matériel de l'utilisateur lors de l'accès au site <strong>www.rockyournet.com</strong>.
                            </Text>
                        </Box>

                        {/* Section 6 - Données personnelles */}
                        <Box
                            p={8}
                            bg={bgSection}
                            borderRadius="xl"
                            borderWidth="1px"
                            borderColor={borderColor}
                            _hover={{ shadow: 'lg' }}
                            transition="all 0.3s"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon as={FaUserShield} w={6} h={6} color={accentColor} />
                                <Heading as="h2" fontSize="2xl" color={headingColor}>
                                    6. Gestion des données personnelles
                                </Heading>
                            </HStack>
                            <Text color={textColor}>
                                Rock Your Net ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site. Conformément à la loi 78-17 du 6 janvier 1978 relative à l'informatique et aux libertés, l'utilisateur dispose d'un droit d'accès, de rectification et d'opposition aux données personnelles le concernant.
                            </Text>
                        </Box>

                        {/* Section 7 - Cookies */}
                        <Box
                            p={8}
                            bg={bgSection}
                            borderRadius="xl"
                            borderWidth="1px"
                            borderColor={borderColor}
                            _hover={{ shadow: 'lg' }}
                            transition="all 0.3s"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon as={FaCookie} w={6} h={6} color={accentColor} />
                                <Heading as="h2" fontSize="2xl" color={headingColor}>
                                    7. Liens hypertextes et cookies
                                </Heading>
                            </HStack>
                            <Text color={textColor}>
                                La navigation sur le site <strong>www.rockyournet.com</strong> est susceptible de provoquer l'installation de cookie(s) sur l'ordinateur de l'utilisateur. L'utilisateur peut refuser l'installation des cookies en modifiant les paramètres de son navigateur.
                            </Text>
                        </Box>

                        {/* Section 8 - Droit applicable */}
                        <Box
                            p={8}
                            bg={bgSection}
                            borderRadius="xl"
                            borderWidth="1px"
                            borderColor={borderColor}
                            _hover={{ shadow: 'lg' }}
                            transition="all 0.3s"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon as={FaGavel} w={6} h={6} color={accentColor} />
                                <Heading as="h2" fontSize="2xl" color={headingColor}>
                                    8. Droit applicable et attribution de juridiction
                                </Heading>
                            </HStack>
                            <Text color={textColor}>
                                Tout litige en relation avec l'utilisation du site <strong>www.rockyournet.com</strong> est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Lyon.
                            </Text>
                        </Box>
                    </VStack>
                </VStack>
            </Container>
        </Box>
    );
};

export default LegalMentions;