import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Image,
    Button,
    VStack,
    HStack,
    Badge,
    Divider,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
    FaRocket,
    FaHandshake,
    FaStar,
    FaChartLine,
    FaLightbulb,
    FaUsers,
    FaCode,
    FaHeart,
    FaCheckCircle,
    FaArrowRight
} from 'react-icons/fa';
import MissionImage from '../imagesComponents/Logo_RockYourNet650.webp';
import { Link as RouterLink } from 'react-router-dom';

const MotionBox = motion(Box);

const stats = [
    { number: "100%", label: "Engagement", icon: FaHeart },
    { number: "100%", label: "Clients Satisfaits", icon: FaHandshake }
];

const expertise = [
    "Développement Web Sur Mesure",
    "Design UX/UI Innovant",
    "Optimisation SEO Avancée",
    "Support Technique Réactif",
    "Maintenance Continue",
    "Formation Personnalisée"
];

const valeurs = [
    {
        title: "Innovation",
        description: "Nous restons à la pointe de la technologie pour offrir des solutions modernes et efficaces. Notre veille technologique constante nous permet d'intégrer les meilleures pratiques du secteur.",
        icon: FaLightbulb,
        color: "blue.500"
    },
    {
        title: "Proximité",
        description: "Nous créons une relation de confiance avec chaque client. Notre approche personnalisée garantit que vos besoins spécifiques sont au cœur de chaque décision.",
        icon: FaHandshake,
        color: "purple.500"
    },
    {
        title: "Excellence",
        description: "Chaque projet bénéficie de notre engagement total envers la qualité. Nous ne nous contentons pas du minimum, nous visons l'excellence dans chaque détail.",
        icon: FaStar,
        color: "yellow.500"
    },
    {
        title: "Transparence",
        description: "Communication claire et honnête tout au long du projet. Vous êtes informé à chaque étape et participez activement aux décisions importantes.",
        icon: FaUsers,
        color: "green.500"
    }
];

const processus = [
    {
        titre: "Analyse",
        description: "Compréhension approfondie de vos besoins",
        icon: FaLightbulb
    },
    {
        titre: "Conception",
        description: "Création de solutions sur mesure",
        icon: FaCode
    },
    {
        titre: "Développement",
        description: "Réalisation technique experte",
        icon: FaRocket
    },
    {
        titre: "Optimisation",
        description: "Performance et SEO",
        icon: FaChartLine
    }
];

export default function AboutPage() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');
    const statBg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    return (
        <Box as="section" bg={cardBg} py={12} fontSize="lg">
            {/* Hero Section */}
            <Container maxW="7xl" py={16}>
                <VStack spacing={8} textAlign="center">
                    <Badge
                        colorScheme="purple"
                        px={3}
                        py={1}
                        borderRadius="full"
                        fontSize="sm"
                    >
                        Notre Histoire
                    </Badge>
                    <Heading
                        as="h1"
                        fontSize={{ base: '3xl', md: '5xl' }}
                        fontWeight="bold"
                        color={headingColor}
                        lineHeight="shorter"
                    >
                        Transformons Ensemble
                        <br />
                        Votre Vision Digitale
                    </Heading>
                    <Text
                        fontSize={{ base: 'lg', md: 'xl' }}
                        color={textColor}
                        maxW="3xl"
                    >
                        Rock Your Net est bien plus qu'une agence web - nous sommes vos partenaires
                        dans la transformation digitale. Notre passion est de créer des solutions
                        web innovantes qui propulsent votre succès en ligne.
                    </Text>
                </VStack>
            </Container>

            {/* Stats Section */}
            <Container maxW="7xl" py={12}>
                <SimpleGrid
                    columns={{ base: 1, md: 2 }}
                    display="flex"
                    justifyContent="center"
                    spacing={8}
                >
                    {stats.map((stat, index) => (
                        <MotionBox

                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.2 }}
                            bg={bg}
                            p={8}
                            w={{ base: '180px', md: '400px' }}
                            borderRadius="xl"
                            shadow="xl"
                            textAlign="center"
                        >
                            <Icon
                                as={stat.icon}
                                w={10}
                                h={10}
                                color="purple.500"
                                mb={4}
                            />
                            <Text
                                fontSize="4xl"
                                fontWeight="bold"
                                color={headingColor}
                                mb={2}
                            >
                                {stat.number}
                            </Text>
                            <Text color={textColor}>
                                {stat.label}
                            </Text>
                        </MotionBox>
                    ))}
                </SimpleGrid>
            </Container>
            {/* Section "Qui sommes-nous ?" */}
            <Box bg={bg} py={20}>
                <Container maxW="7xl">
                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16} alignItems="center">
                        <MotionBox
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5 }}
                        >
                            <VStack align="start" spacing={6}>
                                <Heading as="h2" fontSize="3xl" color={headingColor}>
                                    Qui sommes-nous ?
                                </Heading>
                                <Text color={textColor} fontSize="lg" lineHeight="tall">
                                    Fondée par un passionné du développement informatique, Rock Your Net
                                    incarne l'innovation et l'excellence dans le domaine du web. Notre mission
                                    est de démocratiser la transformation digitale pour toutes les entreprises.
                                </Text>
                                <Text color={textColor} fontSize="lg" lineHeight="tall">
                                    Avec plusieurs années d'expertise, nous avons développé une approche unique
                                    qui combine créativité, performance technique et stratégie marketing pour
                                    offrir des solutions web complètes et efficaces.
                                </Text>

                                <Divider my={6} borderColor={borderColor} />

                                <Heading as="h3" fontSize="xl" color={headingColor} mb={4}>
                                    Notre Expertise
                                </Heading>
                                <SimpleGrid columns={2} spacing={4}>
                                    {expertise.map((item, index) => (
                                        <HStack key={index} spacing={2}>
                                            <Icon as={FaCheckCircle} color="green.500" />
                                            <Text color={textColor}>{item}</Text>
                                        </HStack>
                                    ))}
                                </SimpleGrid>
                            </VStack>
                        </MotionBox>

                        <MotionBox
                            display="flex"
                            justifyContent="center"
                            initial={{ opacity: 0, x: 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5 }}
                        >
                            <Image
                                src={MissionImage}
                                alt="Rock Your Net"
                                w={{ base: "250px", md: "auto" }}
                                h="auto"
                                objectFit="cover"
                            />
                        </MotionBox>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Section Processus */}
            <Container maxW="7xl" py={20}>
                <VStack spacing={12}>
                    <VStack spacing={4} textAlign="center">
                        <Badge colorScheme="purple"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm"
                        >Notre Approche</Badge>
                        <Heading as="h2" fontSize="3xl" color={headingColor}>
                            Notre Processus de Travail
                        </Heading>
                        <Text color={textColor} maxW="2xl">
                            Une méthodologie éprouvée pour garantir le succès de vos projets
                        </Text>
                    </VStack>

                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8} w="full">
                        {processus.map((etape, index) => (
                            <MotionBox
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                            >
                                <VStack
                                    bg={bg}
                                    h={{ base: 'auto', md: '250px' }}
                                    p={8}
                                    borderRadius="xl"
                                    shadow="xl"
                                    spacing={4}
                                    position="relative"
                                    _hover={{ transform: 'translateY(-5px)', shadow: '2xl' }}
                                    transition="all 0.3s"
                                >
                                    <Box
                                        bg="purple.500"
                                        w={12}
                                        h={12}
                                        borderRadius="full"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        mb={4}
                                    >
                                        <Icon as={etape.icon} color="white" w={6} h={6} />
                                    </Box>
                                    <Text
                                        fontWeight="bold"
                                        fontSize="xl"
                                        color={headingColor}
                                    >
                                        {etape.titre}
                                    </Text>
                                    <Text
                                        color={textColor}
                                        textAlign="center"
                                    >
                                        {etape.description}
                                    </Text>
                                    {index < processus.length - 1 && (
                                        <Box
                                            display={{ base: 'none', lg: 'block' }}
                                            position="absolute"
                                            right="-60%"
                                            top="30%"
                                            w="60%"
                                            h="2px"
                                            bg="purple.500"
                                            zIndex={0}
                                        />
                                    )}
                                </VStack>
                            </MotionBox>
                        ))}
                    </SimpleGrid>
                </VStack>
            </Container>

            {/* Section Valeurs */}
            <Box bg={bg} py={20}>
                <Container maxW="7xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm"
                            >Nos Valeurs</Badge>
                            <Heading as="h2" fontSize="3xl" color={headingColor}>
                                Les Valeurs Qui Nous Définissent
                            </Heading>
                            <Text color={textColor} maxW="2xl">
                                Des principes fondamentaux qui guident chacune de nos actions
                            </Text>
                        </VStack>

                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                            {valeurs.map((valeur, index) => (
                                <MotionBox
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.2 }}
                                >
                                    <Box
                                        p={8}
                                        bg={cardBg}
                                        h={{ base: 'auto', md: '240px' }}
                                        borderRadius="xl"
                                        shadow="xl"
                                        _hover={{ transform: 'translateY(-5px)', shadow: '2xl' }}
                                        transition="all 0.3s"
                                    >
                                        <Flex align="center" mb={6}>
                                            <Box
                                                bg={valeur.color}
                                                w={12}
                                                h={12}
                                                borderRadius="full"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                mr={4}
                                            >
                                                <Icon as={valeur.icon} color="white" w={6} h={6} />
                                            </Box>
                                            <Heading as="h3" fontSize="xl" color={headingColor}>
                                                {valeur.title}
                                            </Heading>
                                        </Flex>
                                        <Text color={textColor} fontSize="lg">
                                            {valeur.description}
                                        </Text>
                                    </Box>
                                </MotionBox>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>

            {/* CTA Section */}
            <Container maxW="7xl" py={20}>
                <Box
                    bg={bg}
                    borderRadius="2xl"
                    shadow="2xl"
                    p={{ base: 8, md: 16 }}
                    textAlign="center"
                >
                    <VStack spacing={8}>
                        <Heading as="h2" fontSize="3xl" color={headingColor}>
                            Prêt à Transformer Votre Présence en Ligne ?
                        </Heading>
                        <Text color={textColor} fontSize="lg" maxW="2xl">
                            Rejoignez les entreprises qui font confiance à Rock Your Net pour leur transformation digitale.
                            Contactez-nous dès aujourd'hui pour discuter de votre projet.
                        </Text>
                        <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>

                            <Button
                                as={RouterLink}
                                to="/Contact"
                                colorScheme="purple"
                                size="lg"
                                px={8}
                                rightIcon={<FaArrowRight />}
                                _hover={{
                                    bg: 'white',            // Couleur de fond blanche au survol
                                    color: 'purple.600',     // Texte de couleur purple.600 au survol
                                    border: '1px solid',     // Ajoute une bordure
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl' // Couleur de la bordure purple.600
                                }}
                            >
                                Démarrer Votre Projet
                            </Button>
                            <Button
                                as={RouterLink}
                                to="/Services"
                                variant="outline"
                                colorScheme="purple"
                                size="lg"
                                px={8}
                                _hover={{
                                    bg: 'white',            // Couleur de fond blanche au survol
                                    color: 'purple.600',     // Texte de couleur purple.600 au survol
                                    border: '1px solid',     // Ajoute une bordure
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl' // Couleur de la bordure purple.600
                                }}
                            >
                                Découvrir Nos Services
                            </Button>
                        </Flex>
                    </VStack>
                </Box>
            </Container>
        </Box>
    );
}
