import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainCommunityManagementAuxerre from '../../sections/référencement/MainCommunityManagementAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function CommunityManagementAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Community Management à Auxerre | Rock Your Net</title>
                <meta name="description" content="Confiez la gestion de vos réseaux sociaux à Rock Your Net, expert en community management à Auxerre. Solutions personnalisées et stratégies digitales innovantes." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="community management Auxerre, gestion réseaux sociaux Auxerre, social media Auxerre, stratégie digitale Auxerre, community manager Auxerre, marketing réseaux sociaux Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Community-management-auxerre" />

                {/* Open Graph */}
                <meta property="og:title" content="Community Management à Auxerre | Rock Your Net" />
                <meta property="og:description" content="Développez votre présence sur les réseaux sociaux avec Rock Your Net. Expert en community management à Auxerre pour une communication digitale efficace." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Community-management-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Community Management à Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Gestion professionnelle de vos réseaux sociaux à Auxerre. Stratégies personnalisées pour développer votre communauté." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "MarketingAgency",
                        "name": "Rock Your Net - Community Management Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet.png",
                        "description": "Agence spécialisée en community management et gestion des réseaux sociaux à Auxerre. Expert en stratégie social media et engagement communautaire.",
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services de community management",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Gestion des réseaux sociaux"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création de contenu social media"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Animation de communauté"
                                    }
                                }
                            ]
                        },
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "postalCode": "89000",
                            "addressCountry": "FR"
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainCommunityManagementAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};