// src/theme.js

import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const colors = {
  brand: {
    50: "#e3f2f9",
    100: "#c5e4f3",
    200: "#a2d4ec",
    300: "#7ac1e4",
    400: "#47a9da",
    500: "#0088cc",
    600: "#007ab8",
    700: "#006ba1",
    800: "#005885",
    900: "#003f5e",
  },
  light: {
    bg: "#5B40A5",
    color: "#FFFFFF",
  },
  light_secondary: {
    bg: "#3F1480",
    color: "#0A3630",
  },
  light_tertiary: {
    bg: "#F42272",
    color: "#FFFFFF",
  },
  light_font: {
    white: "#FFFFFF",
    black: "#0A3630",
  },
  light_bg: {
    white: "#FFFFFF",
    lightgrey: "#FAFAFA",
    middlegrey: "#ECECEC",
    black: "#000000",
  },
  dark: {
    bg: "#F1F0FF",
    color: "#3B1154",
  },
  dark_secondary: {
    bg: "#ffffff",
    color: "#ffffff",
  },
  dark_tertiary: {
    bg: "#F42272",
    color: "#ffffff",
  },
  dark_font: {
    white: "#FFFFFF",
    black: "#000000",
  },
  dark_bg: {
    white: "#FFFFFF",
    darkgrey: "#18253E",
    black: "#03012C",
  },
};

const theme = extendTheme({
  config,
  colors,
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === "dark" ? colors.dark.bg : colors.light.bg,
        color:
          props.colorMode === "dark" ? colors.dark.color : colors.light.color,
      },
    }),
  },
});

export default theme;
