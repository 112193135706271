import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Image,
    SimpleGrid,
    Flex,
    Button,
    VStack,
    HStack,
    Icon,
    Badge,
    Tag,
    useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaGlobe, FaMobile, FaCode, FaSearch, FaPalette, FaRocket, FaExternalLinkAlt } from 'react-icons/fa';

// Import des images
import Cappadoce from '../imagesComponents/CappadoceAccueil.jpg';
import Rhoneau from '../imagesComponents/RhoneauAccueil.jpg';
import UPG from '../imagesComponents/UPGAccueil.jpg';
import Massis from '../imagesComponents/MassisAccueil.jpg';
import Garage from '../imagesComponents/GarageAccueil.jpg';
import VGT from '../imagesComponents/VGTAccueil.jpg';

const MotionBox = motion(Box);

// Données enrichies des créations
const creations = [
    {
        title: "Very Good Trip",
        description: "Créée en partenariat avec Make-it. Une application innovante utilisant l'IA pour créer des voyages sur mesure à la demande des utilisateurs, avec une interface utilisateur fluide et intuitive.",
        image: VGT,
        link: "https://verygoodtrip.org/",
        technologies: ["React", "IA", "Node.js", "MongoDB"],
        features: ["Planification IA", "Interface intuitive", "Responsive"],
        category: "Application Web",
        completion: "2023"
    },
    {
        title: "Restaurant Cappadoce",
        description: "Un site vitrine moderne pour un restaurant turc à Auxerre, mettant en valeur ses spécialités culinaires avec un référencement SEO optimisé.",
        image: Cappadoce,
        link: "https://www.restaurant-cappadoce.com/",
        technologies: ["React", "SEO", "Chakra UI"],
        features: ["SEO optimisé", "Performance", "Design Moderne"],
        category: "Site Vitrine",
        completion: "2023"
    },
    {
        title: "Gestion de Garages",
        description: "Création d'une application-web complète pour la gestion des sites internet des concessionnaires automobiles, intégrant des fonctionnalités avancées pour la gestion des véhicules et des services.",
        image: Garage,
        link: "https://garage-parrot.eu/accueil.php",
        technologies: ["PHP", "MySQL", "JavaScript"],
        features: ["Gestion de stock", "Interface admin", "Multi-utilisateurs"],
        category: "Application Métier",
        completion: "2023"
    },
    {
        title: "Massis Club d'Echecs",
        description: "Un site vitrine pour un club d'échecs à Lyon, offrant des fonctionnalités interactives pour les membres et un référencement SEO adapté au domaine sportif.",
        image: Massis,
        link: "https://www.massis-club-echecs.com/",
        technologies: ["React", "SEO", "Chakra UI"],
        features: ["Espace membres", "Actualités", "Événements"],
        category: "Site Associatif",
        completion: "2023"
    },
    {
        title: "Rhôn'eau",
        description: "Un site vitrine pour un plombier à Lyon, conçu pour attirer plus de clients grâce à un design professionnel et un référencement SEO performant.",
        image: Rhoneau,
        link: "https://www.rhoneau.fr/",
        technologies: ["React", "SEO", "Chakra UI"],
        features: ["SEO optimisé", "Portfolio", "Contact rapide"],
        category: "Site Vitrine",
        completion: "2023"
    },
    {
        title: "Un Pas de Géant",
        description: "Un site vitrine dédié à une association qui aide les enfants handicapés, avec un design inclusif et un SEO adapté pour atteindre plus de bénévoles et de soutiens.",
        image: UPG,
        link: "https://www.unpasdegeant.com/",
        technologies: ["React", "SEO", "Accessibilité"],
        features: ["Don en ligne", "Actualités", "Événements"],
        category: "Site Associatif",
        completion: "2023"
    }
];

// Ajout des statistiques
const stats = [
    { number: "100+", label: "Sites Créés" },
    { number: "95%", label: "Clients Satisfaits" },
    { number: "30+", label: "Top 3 Google" }
];

const CreationCard = ({ creation }) => {
    const cardBg = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const headingColor = useColorModeValue('gray.800', 'white');
    const tagBg = useColorModeValue('purple.50', 'purple.900');

    const accentColor = useColorModeValue('purple.700', 'orange.200');
    const badgeBg = useColorModeValue('orange.200', 'purple.900');

    return (
        <MotionBox
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            bg={cardBg}
            borderRadius="xl"
            shadow="xl"
            p={6}
            _hover={{ transform: 'translateY(-8px)', shadow: '2xl' }}
            height="full"
            display="flex"
            flexDirection="column"
            position="relative"
        >
            <Box position="relative" mb={6}>
                <Image
                    src={creation.image}
                    alt={creation.title}
                    borderRadius="lg"
                    width="100%"
                    height="250px"
                    objectFit="cover"
                    objectPosition="top"
                />
                <Badge
                    position="absolute"
                    top={4}
                    right={4}
                    colorScheme="purple"
                    bg={badgeBg}
                    color={accentColor}
                    fontSize="sm"
                    px={3}
                    py={1}
                    borderRadius="full"
                >
                    {creation.category}
                </Badge>
            </Box>

            <VStack align="stretch" spacing={4} flex="1">
                <Heading as="h3" fontSize="2xl" color={headingColor}>
                    {creation.title}
                </Heading>

                <Text color={textColor} fontSize="md">
                    {creation.description}
                </Text>

                {/*   <Box>
                    <Text fontWeight="bold" mb={2} color={headingColor}>
                        Technologies utilisées :
                    </Text>
                    <Flex gap={2} flexWrap="wrap">
                        {creation.technologies.map((tech, index) => (
                            <Tag
                                key={index}
                                bg={tagBg}
                                color="purple.500"
                                size="sm"
                            >
                                {tech}
                            </Tag>
                        ))}
                    </Flex>
                </Box>  */}

                <Box>
                    <Text fontWeight="bold" mb={2} color={headingColor}>
                        Fonctionnalités clés :
                    </Text>
                    <VStack align="start" spacing={2}>
                        {creation.features.map((feature, index) => (
                            <HStack key={index}>
                                <Icon as={FaRocket} color="orange.500" />
                                <Text color={textColor}>{feature}</Text>
                            </HStack>
                        ))}
                    </VStack>
                </Box>

                {/*}  <Text fontSize="sm" color={textColor} mt="auto">
                    Réalisé en {creation.completion}
                </Text> */}

                <Button
                    as="a"
                    href={creation.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    colorScheme="purple"
                    mt="auto"
                    rightIcon={<FaExternalLinkAlt />}
                    _hover={{
                        bg: 'white',            // Couleur de fond blanche au survol
                        color: 'purple.600',     // Texte de couleur purple.600 au survol
                        border: '1px solid',     // Ajoute une bordure
                        borderColor: 'purple.600',
                        transform: 'translateY(-2px)',
                        shadow: 'xl' // Couleur de la bordure purple.600
                    }}
                >
                    Découvrir
                </Button>
            </VStack>
        </MotionBox>
    );
};

const CreationPage = () => {
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const bg = useColorModeValue('gray.50', 'gray.900');
    const statBg = useColorModeValue('white', 'gray.700');

    return (
        <Box as="section" pt={20} pb={10} bg={bg} minH="100vh">
            {/* Hero Section */}
            <Container maxW="7xl" textAlign="center" mb={20}>
                <Heading
                    as="h1"
                    fontSize={{ base: '3xl', md: '5xl' }}
                    fontWeight="bold"
                    color={headingColor}
                    mb={6}
                >
                    Nos Créations
                </Heading>
                <Text
                    fontSize={{ base: 'lg', md: 'xl' }}
                    color={textColor}
                    maxW="3xl"
                    mx="auto"
                >
                    Découvrez nos dernières réalisations en matière de sites web,
                    d'applications et de solutions digitales sur mesure.
                </Text>

                {/* Stats Section */}
                {/* <SimpleGrid
                    columns={{ base: 1, md: 3 }}
                    spacing={8}
                    mt={12}
                >
                    {stats.map((stat, index) => (
                        <Box
                            key={index}
                            bg={statBg}
                            p={6}
                            borderRadius="lg"
                            shadow="md"
                        >
                            <Text
                                fontSize="4xl"
                                fontWeight="bold"
                                color="purple.500"
                                mb={2}
                            >
                                {stat.number}
                            </Text>
                            <Text color={textColor}>{stat.label}</Text>
                        </Box>
                    ))}
                </SimpleGrid> */}
            </Container>

            {/* Grid des créations */}
            <Container maxW="7xl" mb={20}>
                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing={8}
                >
                    {creations.map((creation, index) => (
                        <CreationCard key={index} creation={creation} />
                    ))}
                </SimpleGrid>
                <Box display="flex" justifyContent="center" mt={12} maxW="xl"
                    mx="auto"
                    pb={12}
                    borderBottom="2px solid"
                    borderBottomColor={useColorModeValue('purple.100', 'purple.700')}>

                    <Heading as="h2" fontSize="2xl" color={headingColor}>
                        Et bien d'autres encore...
                    </Heading>
                </Box>
            </Container>

            {/* CTA Section */}
            <Box bg={bg} pt={6} pb={20}>
                <Container maxW="3xl" textAlign="center">
                    <VStack spacing={8}>
                        <Heading as="h2" fontSize="3xl" color={headingColor}>
                            Prêt à Lancer Votre Projet ?
                        </Heading>
                        <Text fontSize="lg" color={textColor} maxW="2xl">
                            Transformez votre vision en réalité avec notre expertise en développement web et mobile.
                            Contactez-nous pour discuter de votre projet.
                        </Text>
                        <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>

                            <Button
                                as={RouterLink}
                                to="/contact"
                                colorScheme="purple"
                                size="lg"
                                rightIcon={<FaRocket />}
                                _hover={{
                                    bg: 'white',            // Couleur de fond blanche au survol
                                    color: 'purple.600',     // Texte de couleur purple.600 au survol
                                    border: '1px solid',     // Ajoute une bordure
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl' // Couleur de la bordure purple.600
                                }}
                            >
                                Démarrer un Projet
                            </Button>
                            <Button
                                as={RouterLink}
                                to="/services"
                                variant="outline"
                                colorScheme="purple"
                                size="lg"
                                _hover={{
                                    bg: 'white',            // Couleur de fond blanche au survol
                                    color: 'purple.600',     // Texte de couleur purple.600 au survol
                                    border: '1px solid',     // Ajoute une bordure
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl' // Couleur de la bordure purple.600
                                }}
                            >
                                Voir nos Services
                            </Button>
                        </Flex>
                    </VStack>
                </Container>
            </Box>
        </Box>
    );
};

export default CreationPage;