import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "./theme";
import Accueil from "./components/pages/Accueil";
import Services from "./components/pages/Services";
import Créations from "./components/pages/Créations";
import SiteVitrine from "./components/pages/SiteVitrine";
import SiteECommerce from "./components/pages/SiteECommerce";
import Referencement from "./components/pages/Referencement";
import Application from "./components/pages/ApplicationWeb";
import CommunityManagement from "./components/pages/CommunityManagement";
import Contact from "./components/pages/Contact";
import APropos from "./components/pages/APropos";
import Produits from "./components/pages/Produits";
import MentionsLégales from "./components/pages/MentionsLegales";
import CarteDuSite from "./components/pages/CarteDuSite";
import CreationSiteAuxerre from "./components/pages/référencement/CreationSiteAuxerre";
import CommunicationAuxerre from "./components/pages/référencement/CommunicationAuxerre";
import AgenceWebAuxerre from "./components/pages/référencement/AgenceWebAuxerre";
import DeveloppementWebAuxerre from "./components/pages/référencement/DeveloppementWebAuxerre";
import ReferencementAuxerre from "./components/pages/référencement/ReferencementAuxerre";
import EcommerceAuxerre from "./components/pages/référencement/EcommerceAuxerre";
import MarketingBourgogne from "./components/pages/référencement/MarketingBourgogne";
import MaintenanceYonne from "./components/pages/référencement/MaintenanceYonne";
import AgenceDigitaleBourgogne from "./components/pages/référencement/AgenceDigitaleBourgogne";
import WordPressAuxerre from "./components/pages/référencement/WordpressAuxerre";
import CommunityManagementAuxerre from "./components/pages/référencement/CommunityManagementAuxerre";
import StrategieBourgogne from "./components/pages/référencement/StrategieBourgogne";
import ApplicationAuxerre from "./components/pages/référencement/ApplicationAuxerre";
import AuditSeoAuxerre from "./components/pages/référencement/AuditSeoAuxerre";
import WebDesignBourgogne from "./components/pages/référencement/WebDesignBourgogne";
import RefonteAuxerre from "./components/pages/référencement/RefonteAuxerre";
import SolutionsEcommerceYonne from "./components/pages/référencement/SolutionsEcommerceYonne";
import OptimisationWebAuxerre from "./components/pages/référencement/OptimisationWebAuxerre";
import CommunicationBourgogne from "./components/pages/référencement/CommunicationBourgogne";

import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Services/Site-vitrine" element={<SiteVitrine />} />
          <Route path="/Services/Site-e-commerce" element={<SiteECommerce />} />
          <Route path="/Services/Referencement" element={<Referencement />} />
          <Route path="/Services/ApplicationWeb" element={<Application />} />
          <Route
            path="/Services/Community-Management"
            element={<CommunityManagement />}
          />
          <Route path="/Créations" element={<Créations />} />
          <Route path="/Produits" element={<Produits />} />
          <Route path="/A-propos" element={<APropos />} />
          <Route path="/Contact" element={<Contact />} />
          <Route
            path="/Communication-Auxerre"
            element={<CommunicationAuxerre />}
          />
          <Route
            path="/Creation-site-auxerre"
            element={<CreationSiteAuxerre />}
          />
          <Route path="/Agence-web-auxerre" element={<AgenceWebAuxerre />} />
          <Route
            path="/Developpement-web-auxerre"
            element={<DeveloppementWebAuxerre />}
          />
          <Route
            path="/Referencement-auxerre"
            element={<ReferencementAuxerre />}
          />
          <Route path="/Ecommerce-auxerre" element={<EcommerceAuxerre />} />
          <Route
            path="/Marketing-digital-bourgogne"
            element={<MarketingBourgogne />}
          />
          <Route path="/Maintenance-yonne" element={<MaintenanceYonne />} />
          <Route
            path="/Agence-digitale-bourgogne"
            element={<AgenceDigitaleBourgogne />}
          />
          <Route path="/WordPress-auxerre" element={<WordPressAuxerre />} />
          <Route
            path="/Community-management-auxerre"
            element={<CommunityManagementAuxerre />}
          />
          <Route
            path="/Strategie-digitale-bourgogne"
            element={<StrategieBourgogne />}
          />
          <Route
            path="/Application-web-auxerre"
            element={<ApplicationAuxerre />}
          />
          <Route path="/Audit-seo-auxerre" element={<AuditSeoAuxerre />} />
          <Route
            path="/Web-design-bourgogne"
            element={<WebDesignBourgogne />}
          />
          <Route path="/Refonte-site-auxerre" element={<RefonteAuxerre />} />
          <Route
            path="/Solutions-ecommerce-yonne"
            element={<SolutionsEcommerceYonne />}
          />
          <Route
            path="/Optimisation-web-auxerre"
            element={<OptimisationWebAuxerre />}
          />
          <Route
            path="/Agence-communication-bourgogne"
            element={<CommunicationBourgogne />}
          />

          <Route path="/Mentions-Légales" element={<MentionsLégales />} />
          <Route path="/Carte-du-site" element={<CarteDuSite />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
