import React from 'react';
import {
    Box,
} from '@chakra-ui/react';
import HeroSection from "./HeroSection"
import ServicesSection from "./ServicesSection"
import CompanySection from "./CompanySection"
import LastCréationsSection from "./LastCréationsSection"
import TestimonialsSection from "./TestimonialsSection"

// Page d'accueil
const MainAccueil = () => {
    return (
        <Box>
            <HeroSection />
            <CompanySection />
            <ServicesSection />
            <LastCréationsSection />
            <TestimonialsSection />
        </Box>
    );
};

export default MainAccueil;
