import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainCreationSiteAuxerre from '../../sections/référencement/MainCreationSiteAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function CreationSiteAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Création de Site Internet à Auxerre | Rock Your Net</title>
                <meta name="description" content="Expert en création de sites internet à Auxerre, Rock Your Net conçoit des sites web professionnels, modernes et optimisés pour votre entreprise." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="création site internet Auxerre, création site web Auxerre, développement web Auxerre, site vitrine Auxerre, agence web Auxerre, Rock Your Net" />
                <link rel="canonical" href="https://rockyournet.com/Creation-site-auxerre" />

                {/* Open Graph */}
                <meta property="og:title" content="Création de Site Internet à Auxerre | Rock Your Net" />
                <meta property="og:description" content="Développez votre présence en ligne avec Rock Your Net, expert en création de sites internet à Auxerre. Sites web professionnels et optimisés." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Creation-site-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Création de Site Internet à Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Création de sites web professionnels à Auxerre. Sites optimisés, responsive design et référencement naturel." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebDesignService",
                        "name": "Rock Your Net - Création de Sites Internet Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet.png",
                        "description": "Service de création de sites internet professionnels à Auxerre. Experts en développement web et optimisation SEO.",
                        "provider": {
                            "@type": "Organization",
                            "name": "Rock Your Net",
                            "url": "https://rockyournet.com"
                        },
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services de création web",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création de sites web vitrine"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Développement de sites e-commerce"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Optimisation SEO"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainCreationSiteAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};