import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainEcommerceAuxerre from '../../sections/référencement/MainEcommerceAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function EcommerceAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Création Site E-commerce à Auxerre | Rock Your Net</title>
                <meta name="description" content="Experts en création de sites e-commerce à Auxerre. Solutions de vente en ligne personnalisées, performantes et sécurisées pour développer votre business." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="création site e-commerce Auxerre, boutique en ligne Auxerre, développement e-commerce Auxerre, site marchand Auxerre, web e-commerce Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Ecommerce-auxerre" />

                {/* Open Graph */}
                <meta property="og:title" content="Création Site E-commerce à Auxerre | Rock Your Net" />
                <meta property="og:description" content="Développez votre activité en ligne avec Rock Your Net. Expert en création de sites e-commerce à Auxerre, solutions sur mesure et accompagnement complet." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Ecommerce-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Création Site E-commerce à Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Solutions e-commerce professionnelles à Auxerre. Boutiques en ligne optimisées et sécurisées pour votre business." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebDevelopmentBusiness",
                        "name": "Rock Your Net - Création E-commerce Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet.png",
                        "description": "Agence spécialisée en création de sites e-commerce à Auxerre. Solutions de vente en ligne professionnelles et personnalisées.",
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services e-commerce",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création de boutique en ligne"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Intégration de paiement sécurisé"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Optimisation e-commerce"
                                    }
                                }
                            ]
                        },
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "postalCode": "89000",
                            "addressCountry": "FR"
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainEcommerceAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};