import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Image,
    useColorModeValue,
    Badge,
    SimpleGrid,
    Icon,
    List,
    ListItem,
    ListIcon,
    Flex,
} from '@chakra-ui/react';
import { FaCheckCircle, FaPaintBrush, FaMobileAlt, FaDesktop, FaRegFileAlt, FaPhone, FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../../imagesComponents/Logo_RockYourNet650.webp';
import WebDesign from '../../imagesComponents/RockYourNet.webp';

const MotionBox = motion(Box);

export default function MainWebDesignBourgogne() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const headingColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('white', 'gray.700');
    const buttonBg = useColorModeValue('white', 'gray.800');
    const buttonText = useColorModeValue('purple.500', 'purple.300');
    const sectionSpacing = 20;

    return (
        <Box as="section" bg={bg} minH="100vh" fontSize="lg">
            <Container maxW="8xl" pt={{ base: 8, lg: 20 }} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={6} align="center" mb={28}>
                            <Badge colorScheme="purple" fontSize="md" px={6} py={1} borderRadius="full" mb={6}>
                                Design & Créativité
                            </Badge>
                            <Heading as="h1" size="2xl" color={headingColor} textAlign="center">
                                Web Design en Bourgogne
                            </Heading>
                            <Image
                                src={Logo}
                                alt="Logo Rock Your Net"
                                width={{ base: "200px", md: "300px" }}
                                my={6}
                            />
                            <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                Design Web Moderne & Créatif
                            </Heading>
                        </VStack>

                        <Text fontSize="xl" color={textColor} mb={12}>
                            Votre agence de <strong>web design en Bourgogne</strong>, Rock Your Net crée des sites web alliant esthétisme et performance. Notre approche créative et notre expertise technique vous garantissent un design unique qui reflète parfaitement votre identité.
                        </Text>
                    </Box>
                </VStack>
            </Container>

            <Container maxW="full" bg={cardBg}>
                <Container maxW="8xl" bg={cardBg} pt={10} pb={10}>
                    <VStack spacing={sectionSpacing} align="start">
                        <Box w="100%" py={12}>
                            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} alignItems="center">
                                <Box>
                                    <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                        L'Art du Web Design
                                    </Heading>
                                    <Text color={textColor} mb={8}>
                                        Notre expertise en <strong>web design en Bourgogne</strong> s'appuie sur des principes essentiels :
                                    </Text>
                                    <List spacing={6}>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Design responsive</strong> : Une expérience utilisateur optimale sur tous les écrans
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Interface intuitive</strong> : Navigation fluide et ergonomique
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Identité visuelle</strong> : Design unique aligné avec votre marque
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Performance optimisée</strong> : Vitesse de chargement et fluidité
                                            </Box>
                                        </ListItem>
                                    </List>
                                </Box>
                                <Flex justify="center" align="center" order={{ base: -1, lg: 1 }}>
                                    <Image
                                        src={WebDesign}
                                        alt="Web design illustration"
                                        width={{ base: "100%", lg: "70%" }}
                                        mt={{ base: "20px", lg: "0" }}
                                        maxW="500px"
                                        borderRadius="2xl"
                                        shadow="2xl"
                                    />
                                </Flex>
                            </SimpleGrid>
                            <Flex justify="center" w="full" mt={16}>
                                <MotionBox
                                    as={RouterLink}
                                    to="/Créations"
                                    bg={buttonBg}
                                    whileHover={{ scale: 1.02 }}
                                    p={4}
                                    px={8}
                                    border="2px solid"
                                    borderColor="purple.500"
                                    color={buttonText}
                                    borderRadius="lg"
                                    _hover={{
                                        bg: 'white',
                                        color: 'purple.600',
                                        border: '2px solid',
                                        borderColor: 'purple.600',
                                        shadow: 'xl'
                                    }}
                                    textAlign="center"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap={3}
                                >
                                    <Icon as={FaPaintBrush} w={5} h={5} />
                                    <Text fontSize="md" fontWeight="medium">Découvrir Nos Créations</Text>
                                </MotionBox>
                            </Flex>
                        </Box>
                    </VStack>
                </Container>
            </Container>

            <Container maxW="8xl" pt={20} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={20} align="start">
                            <Box w="full">
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Notre Expertise en Design Web
                                </Heading>
                                <Text color={textColor} mb={16}>
                                    Notre agence de <strong>web design en Bourgogne</strong> propose une approche globale pour créer des sites web qui se démarquent.
                                </Text>

                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={12}>
                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaDesktop} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Design UI/UX</Heading>
                                        <Text color={textColor}>
                                            Interfaces intuitives et expérience utilisateur optimisée
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaMobileAlt} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Design Responsive</Heading>
                                        <Text color={textColor}>
                                            Adaptation parfaite à tous les appareils
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaPaintBrush} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Identité Visuelle</Heading>
                                        <Text color={textColor}>
                                            Design unique et cohérent avec votre image de marque
                                        </Text>
                                    </MotionBox>
                                </SimpleGrid>
                            </Box>

                            <Box mt={8}>
                                <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                    Le Design au Service de Votre Succès
                                </Heading>
                                <Text color={textColor}>
                                    En tant qu'agence de <strong>web design en Bourgogne</strong>, nous comprenons l'importance d'un design professionnel pour votre image de marque. Notre équipe crée des interfaces modernes et attractives qui engagent vos visiteurs et les convertissent en clients. Nous utilisons les dernières tendances du web design tout en privilégiant une approche fonctionnelle et efficace.
                                </Text>
                            </Box>

                            <Box>
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Design et Performance
                                </Heading>
                                <Text color={textColor}>
                                    Le <strong>web design en Bourgogne</strong> ne se limite pas à l'esthétique. Nous créons des sites qui allient beauté et performance technique. Chaque élément de design est optimisé pour garantir des temps de chargement rapides et une expérience utilisateur fluide. Notre objectif est de créer des sites web qui non seulement attirent l'attention mais convertissent également vos visiteurs.
                                </Text>
                            </Box>

                            {/* Section CTA */}
                            <Box w="full" bg={cardBg} borderRadius="2xl" p={{ base: 4, lg: 12 }} mt={8}>
                                <VStack spacing={8} align="center">
                                    <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                        Prêt à Donner Vie à Votre Projet Web ?
                                    </Heading>

                                    <Text fontSize="lg" color={textColor} textAlign="center" maxW="2xl">
                                        Discutons ensemble de votre vision et créons un design qui vous ressemble.
                                    </Text>

                                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full" maxW="4xl" mx="auto">
                                        <MotionBox
                                            as={RouterLink}
                                            to="/Services/Site-vitrine"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaRegFileAlt} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Services</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Créations"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaArrowRight} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Réalisations</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Contact"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaPhone} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Contactez-nous</Text>
                                        </MotionBox>
                                    </SimpleGrid>
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}