import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Image,
    useColorModeValue,
    Badge,
    SimpleGrid,
    Icon,
    List,
    ListItem,
    ListIcon,
    Flex,
} from '@chakra-ui/react';
import { FaCheckCircle, FaBuilding, FaBullhorn, FaChartLine, FaArrowRight, FaPhone, FaRegFileAlt } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../../imagesComponents/Logo_RockYourNet650.webp';

const MotionBox = motion(Box);

export default function MainCommunicationAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const linkColor = useColorModeValue('purple.500', 'purple.400');
    const headingColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('white', 'gray.700');
    const buttonBg = useColorModeValue('white', 'gray.800');
    const buttonText = useColorModeValue('purple.500', 'purple.300');
    const sectionSpacing = 20; // Espacement entre les sections

    return (
        <Box as="section" bg={bg} minH="100vh" fontSize="lg">
            <Container maxW="8xl" pt={{ base: 8, lg: 20 }} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    {/* Introduction */}
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={6} align="center" mb={28}>
                            <Badge colorScheme="purple"
                                fontSize="md"
                                px={6}
                                py={1}
                                borderRadius="full"
                                mb={6}>Expertise Locale</Badge>
                            <Heading as="h1" size="2xl" color={headingColor} textAlign="center">
                                Agence de Communication à Auxerre
                            </Heading>
                            <Image
                                src={Logo}
                                alt="Logo Rock Your Net"
                                width={{ base: "200px", md: "300px" }}
                                my={6}
                            />
                            <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                Votre Partenaire Digital
                            </Heading>
                        </VStack>

                        <Text fontSize="xl" color={textColor} mb={12}>
                            En tant qu'<strong>agence de communication à Auxerre</strong>, Rock Your Net s'engage à propulser votre entreprise dans l'ère digitale. Notre expertise locale et notre vision innovante font de nous le partenaire idéal pour votre transformation numérique.
                        </Text>

                        {/* Contenu principal article */}
                        <VStack spacing={16} align="start">
                            <Box>
                                <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                    L'Excellence de la Communication Digitale à Auxerre
                                </Heading>
                                <Text color={textColor}>
                                    Dans un monde en constante évolution, votre <strong>présence numérique</strong> est devenue indispensable. Notre <strong>agence de communication à Auxerre</strong> combine expertise technique et créativité pour vous offrir des solutions sur mesure. Nous comprenons les enjeux spécifiques du marché local et adaptons nos stratégies pour maximiser votre impact.
                                </Text>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>

            <Container maxW="full" bg={cardBg}>
                <Container maxW="8xl" bg={cardBg} pt={10} pb={10}>
                    <VStack spacing={sectionSpacing} align="start">
                        <Box w="100%" py={12}>
                            <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                Des Services Adaptés aux Entreprises Auxerroises
                            </Heading>
                            <Text color={textColor} mb={8}>
                                En tant qu'<strong>agence de communication à Auxerre</strong>, nous proposons une gamme complète de services pour répondre à vos besoins :
                            </Text>
                            <List spacing={6}>
                                <ListItem display="Flex">
                                    <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                    <Box as={RouterLink} to="/Services" color={linkColor}>
                                        <strong>Création de sites web</strong> : Des solutions performantes et adaptées à votre image
                                    </Box>
                                </ListItem>
                                <ListItem display="Flex">
                                    <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                    <Box as={RouterLink} to="/Services/Referencement" color={linkColor}>
                                        <strong>Référencement local</strong> : Optimisation SEO ciblée pour la région d'Auxerre
                                    </Box>
                                </ListItem>
                                <ListItem display="Flex">
                                    <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                    <Box color={textColor}>
                                        <strong>Marketing digital</strong> : Stratégies personnalisées pour votre croissance
                                    </Box>
                                </ListItem>
                                <ListItem display="Flex">
                                    <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                    <Box color={textColor}>
                                        <strong>Gestion des réseaux sociaux</strong> : Animation et engagement de votre communauté
                                    </Box>
                                </ListItem>
                            </List>
                        </Box>
                    </VStack>
                </Container>
            </Container>

            <Container maxW="8xl" pt={20} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    {/* Introduction */}
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={20} align="start">
                            <Box w="full">
                                <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                    Pourquoi Choisir Rock Your Net ?
                                </Heading>
                                <Text color={textColor} mb={16}>
                                    Notre <strong>agence de communication à Auxerre</strong> se distingue par son approche personnalisée et sa connaissance approfondie du tissu économique local. Nous sommes plus qu'une simple agence : nous sommes votre partenaire de croissance.
                                </Text>

                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={12}>
                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaBuilding} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Expertise Locale</Heading>
                                        <Text color={textColor}>
                                            Une connaissance approfondie du marché auxerrois et de ses spécificités
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaBullhorn} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Stratégie Personnalisée</Heading>
                                        <Text color={textColor}>
                                            Des solutions sur mesure adaptées à vos objectifs et votre budget
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaChartLine} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Résultats Mesurables</Heading>
                                        <Text color={textColor}>
                                            Un suivi détaillé de vos performances et des ajustements continus
                                        </Text>
                                    </MotionBox>
                                </SimpleGrid>
                            </Box>


                            <Box mt={8}>
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Une Approche Moderne de la Communication
                                </Heading>
                                <Text color={textColor}>
                                    Notre <strong>agence de communication à Auxerre</strong> reste à la pointe des dernières tendances digitales. Nous intégrons les technologies les plus récentes pour garantir à nos clients une communication efficace et innovante. De la <strong>création de contenu</strong> à la <strong>stratégie digitale</strong>, nous assurons une présence en ligne cohérente et performante.
                                </Text>
                            </Box>

                            {/* Conclusion */}
                            <Box>
                                <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                    Votre Succès, Notre Priorité
                                </Heading>
                                <Text color={textColor}>
                                    Choisir Rock Your Net comme <strong>agence de communication à Auxerre</strong>, c'est opter pour un partenaire engagé dans votre réussite. Notre équipe d'experts met tout en œuvre pour transformer vos objectifs en résultats concrets. Contactez-nous pour découvrir comment nous pouvons contribuer à votre croissance digitale.
                                </Text>
                            </Box>

                            {/* Section CTA */}
                            <Box w="full" bg={cardBg} borderRadius="2xl" p={12} mt={8}>
                                <VStack spacing={8} align="center">
                                    <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                        Prêt à Développer Votre Présence Digitale ?
                                    </Heading>

                                    <Text fontSize="lg" color={textColor} textAlign="center" maxW="2xl">
                                        Découvrez comment notre expertise peut transformer votre communication et booster votre visibilité en ligne.
                                    </Text>

                                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full" maxW="4xl" mx="auto">
                                        <MotionBox
                                            as={RouterLink}
                                            to="/Services"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',            // Couleur de fond blanche au survol
                                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                                border: '1px solid',     // Ajoute une bordure
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl' // Couleur de la bordure purple.600
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaRegFileAlt} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Services</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Créations"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',            // Couleur de fond blanche au survol
                                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                                border: '1px solid',     // Ajoute une bordure
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl' // Couleur de la bordure purple.600
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaArrowRight} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Créations</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Contact"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',            // Couleur de fond blanche au survol
                                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                                border: '1px solid',     // Ajoute une bordure
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl' // Couleur de la bordure purple.600
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaPhone} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Contactez-nous</Text>
                                        </MotionBox>
                                    </SimpleGrid>
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}