import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Button,
    useColorModeValue,
    Image,
    VStack,
    HStack,
    Badge,
    List,
    ListItem,
    ListIcon,
    Divider,
    useBreakpointValue,
} from '@chakra-ui/react';
import {
    FaShoppingCart,
    FaLock,
    FaMobileAlt,
    FaSearch,
    FaCreditCard,
    FaChartLine,
    FaCog,
    FaRocket,
    FaTools,
    FaArrowRight,
    FaBoxOpen,
    FaShippingFast,
    FaUsersCog,
    FaCheck,
    FaClock,
    FaShieldAlt,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import EcommerceImage from '../imagesComponents/e-commerce.webp';
import Logo from '../imagesComponents/Logo_RockYourNet650.webp';
import { Link as RouterLink } from 'react-router-dom';

const MotionBox = motion(Box);

// Fonctionnalités enrichies
const features = [
    {
        id: 1,
        title: "Paiement Sécurisé",
        description: "Intégration des solutions de paiement les plus sûres et populaires pour maximiser vos ventes.",
        icon: FaLock,
        color: "green.400",
        details: [
            "Paiements CB sécurisés",
            "Multiples moyens de paiement",
            "Transactions cryptées",
            "Anti-fraude intégré"
        ]
    },
    {
        id: 2,
        title: "Gestion des Produits",
        description: "Interface intuitive pour gérer votre catalogue, vos stocks et vos commandes efficacement.",
        icon: FaBoxOpen,
        color: "blue.400",
        details: [
            "Gestion des stocks",
            "Catégories illimitées",
            "Imports/Exports CSV",
            "Variants produits"
        ]
    },
    {
        id: 3,
        title: "Optimisation E-commerce",
        description: "Solutions optimisées pour convertir vos visiteurs en clients et maximiser vos ventes.",
        icon: FaChartLine,
        color: "purple.400",
        details: [
            "SEO e-commerce",
            "Analytics avancés",
            "Abandon de panier",
            "Promotions automatisées"
        ]
    },
    {
        id: 4,
        title: "Livraison Intégrée",
        description: "Gestion complète des expéditions et intégration des principaux transporteurs.",
        icon: FaShippingFast,
        color: "orange.400",
        details: [
            "Multi-transporteurs",
            "Calcul automatique",
            "Suivi des colis",
            "Étiquettes d'expédition"
        ]
    }
];

// Fonctionnalités avancées
const advancedFeatures = [
    {
        title: "Marketing Intégré",
        features: [
            "Codes promotionnels",
            "Programmes de fidélité",
            "Emails automatisés",
            "Cross-selling"
        ]
    },
    {
        title: "Gestion Clients",
        features: [
            "Comptes clients",
            "Historique commandes",
            "Service après-vente",
            "Avis produits"
        ]
    },
    {
        title: "Analytics",
        features: [
            "Tableau de bord",
            "Rapports de vente",
            "Suivi des conversions",
            "Comportement client"
        ]
    }
];

// Processus de création
const process = [
    {
        title: "Analyse & Conception",
        description: "Étude de vos besoins et création d'une maquette e-commerce personnalisée",
        icon: FaCog,
        duration: "1-2 semaines"
    },
    {
        title: "Développement",
        description: "Création de votre boutique avec les meilleures pratiques e-commerce",
        icon: FaRocket,
        duration: "2-4 semaines"
    },
    {
        title: "Configuration",
        description: "Mise en place des paiements, livraisons et autres fonctionnalités",
        icon: FaTools,
        duration: "1-2 semaines"
    },
    {
        title: "Formation & Lancement",
        description: "Formation à l'utilisation et déploiement sécurisé de votre boutique",
        icon: FaUsersCog,
        duration: "1 semaine"
    }
];

// Garanties
const guarantees = [
    {
        title: "Sécurité Maximale",
        description: "Protection SSL, paiements sécurisés, sauvegarde quotidienne"
    },
    {
        title: "Support Premium",
        description: "Assistance technique dédiée et maintenance continue"
    },
    {
        title: "Formation Complète",
        description: "Formation approfondie à l'utilisation de votre boutique"
    },
    {
        title: "Évolutivité",
        description: "Solution évolutive qui s'adapte à votre croissance"
    }
];

// Avantages clés
const benefits = [
    "Vente 24/7 sans interruption",
    "Élargissement de votre zone de chalandise",
    "Réduction des coûts opérationnels",
    "Automatisation des ventes",
    "Analyse détaillée des performances",
    "Fidélisation client optimisée"
];

export default function SiteEcommerce() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const statBg = useColorModeValue('purple.50', 'purple.900');

    const imageWidth = useBreakpointValue({ base: '300px', md: '450px', lg: '500px' });

    return (
        <Box as="section" bg={bg} minH="100vh">
            {/* Hero Section Améliorée */}
            <Container maxW="8xl" pt={{ base: 20, md: 28 }} pb={{ base: 16, md: 24 }}>
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16} alignItems="center">
                    <MotionBox
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <VStack align={{ base: "center", md: "start" }} spacing={8}>
                            <Badge
                                colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm"
                            >
                                Solution E-commerce Complète
                            </Badge>
                            <Heading
                                lineHeight={1.2}
                                fontWeight="bold"
                                fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                                color={headingColor}
                            >
                                Lancez votre boutique en ligne avec
                                <Flex mt={4} justifyContent="center">
                                    <Image
                                        src={Logo}
                                        alt="Logo Rock Your Net"
                                        width={{ base: '200px', md: '280px' }}
                                    />
                                </Flex>
                            </Heading>
                            <Text color={textColor} fontSize="xl" lineHeight="tall">
                                Transformez votre activité avec une boutique en ligne performante et
                                sécurisée. Nous créons des sites e-commerce qui convertissent vos
                                visiteurs en clients fidèles.
                            </Text>

                            <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>
                                <Button
                                    as={RouterLink}
                                    to="/Contact"
                                    colorScheme="purple"
                                    size="lg"
                                    rightIcon={<FaArrowRight />}
                                    _hover={{
                                        bg: 'white',            // Couleur de fond blanche au survol
                                        color: 'purple.600',     // Texte de couleur purple.600 au survol
                                        border: '1px solid',     // Ajoute une bordure
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl' // Couleur de la bordure purple.600
                                    }}
                                >
                                    Lancer Ma Boutique
                                </Button>
                                <Button
                                    as={RouterLink}
                                    to="/Créations"
                                    variant="outline"
                                    colorScheme="purple"
                                    size="lg"
                                    _hover={{
                                        bg: 'white',            // Couleur de fond blanche au survol
                                        color: 'purple.600',     // Texte de couleur purple.600 au survol
                                        border: '1px solid',     // Ajoute une bordure
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl' // Couleur de la bordure purple.600
                                    }}
                                >
                                    Voir Nos Réalisations
                                </Button>
                            </Flex>

                            {/* Stats rapides */}
                            <SimpleGrid
                                columns={{ base: 1, sm: 3 }}
                                spacing={8}
                                pt={8}
                                w="full"
                            >
                                <Box textAlign="center" p={4} bg={statBg} borderRadius="lg">
                                    <Text fontSize="3xl" fontWeight="bold" color="purple.500">
                                        500+
                                    </Text>
                                    <Text fontSize="sm" color={textColor}>
                                        Produits Gérés
                                    </Text>
                                </Box>
                                <Box textAlign="center" p={4} bg={statBg} borderRadius="lg">
                                    <Text fontSize="3xl" fontWeight="bold" color="purple.500">
                                        100%
                                    </Text>
                                    <Text fontSize="sm" color={textColor}>
                                        Taux de Satisfaction
                                    </Text>
                                </Box>
                                <Box textAlign="center" p={4} bg={statBg} borderRadius="lg">
                                    <Text fontSize="3xl" fontWeight="bold" color="purple.500">
                                        +150%
                                    </Text>
                                    <Text fontSize="sm" color={textColor}>
                                        Ventes En Ligne
                                    </Text>
                                </Box>
                            </SimpleGrid>
                        </VStack>
                    </MotionBox>

                    <MotionBox
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        display="flex"
                        justifyContent="center"
                    >
                        <Image
                            src={EcommerceImage}
                            alt="Site e-commerce exemple"
                            width={imageWidth}
                            objectFit="cover"
                            borderRadius="2xl"
                            shadow="2xl"
                        />
                    </MotionBox>
                </SimpleGrid>
            </Container>

            {/* Section Garanties */}
            <Box bg={cardBg} py={16}>
                <Container maxW="8xl">
                    <SimpleGrid columns={{ base: 2, md: 4 }} spacing={8}>
                        {guarantees.map((guarantee, index) => (
                            <MotionBox
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.1 }}
                            >
                                <VStack
                                    spacing={4}
                                    p={6}
                                    height="full"
                                    align="center"
                                    textAlign="center"
                                >
                                    <Icon
                                        as={FaShieldAlt}
                                        w={8}
                                        h={8}
                                        color="orange.500"
                                    />
                                    <Text
                                        fontWeight="bold"
                                        color={headingColor}
                                    >
                                        {guarantee.title}
                                    </Text>
                                    <Text
                                        fontSize="sm"
                                        color={textColor}
                                    >
                                        {guarantee.description}
                                    </Text>
                                </VStack>
                            </MotionBox>
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Section Fonctionnalités */}
            <Container maxW="8xl" py={20}>
                <VStack spacing={12}>
                    <VStack spacing={4} textAlign="center">
                        <Badge colorScheme="purple"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm">Fonctionnalités</Badge>
                        <Heading
                            fontSize={{ base: '2xl', md: '4xl' }}
                            color={headingColor}
                        >
                            Une Solution E-commerce Complète
                        </Heading>
                        <Text
                            fontSize="lg"
                            color={textColor}
                            maxW="3xl"
                        >
                            Découvrez nos fonctionnalités premium conçues pour maximiser
                            vos ventes en ligne et offrir une expérience d'achat exceptionnelle.
                        </Text>
                    </VStack>

                    <SimpleGrid
                        columns={{ base: 1, md: 2, lg: 4 }}
                        spacing={8}
                        w="full"
                    >
                        {features.map((feature, index) => (
                            <MotionBox
                                key={feature.id}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                            >
                                <Box
                                    bg={cardBg}
                                    p={8}
                                    borderRadius="xl"
                                    shadow="xl"
                                    height="full"
                                    _hover={{
                                        transform: 'translateY(-5px)',
                                        shadow: '2xl',
                                    }}
                                    transition="all 0.3s"
                                >
                                    <VStack align="start" spacing={5}>
                                        <Box
                                            bg={feature.color}
                                            w={12}
                                            h={12}
                                            borderRadius="lg"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Icon
                                                as={feature.icon}
                                                color="white"
                                                w={6}
                                                h={6}
                                            />
                                        </Box>

                                        <Heading
                                            as="h3"
                                            fontSize="xl"
                                            color={headingColor}
                                        >
                                            {feature.title}
                                        </Heading>

                                        <Text color={textColor}>
                                            {feature.description}
                                        </Text>

                                        <List spacing={2} mt={4}>
                                            {feature.details.map((detail, idx) => (
                                                <ListItem
                                                    key={idx}
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <ListIcon
                                                        as={FaCheck}
                                                        color={feature.color}
                                                    />
                                                    <Text fontSize="sm" color={textColor}>
                                                        {detail}
                                                    </Text>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </VStack>
                                </Box>
                            </MotionBox>
                        ))}
                    </SimpleGrid>
                </VStack>
            </Container>

            {/* Section Processus */}
            <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Notre Processus</Badge>
                            <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                                Comment nous créons votre boutique en ligne
                            </Heading>
                            <Text fontSize="lg" color={textColor} maxW="3xl">
                                Un processus éprouvé pour transformer votre projet en une boutique en ligne performante
                            </Text>
                        </VStack>

                        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
                            {process.map((step, index) => (
                                <MotionBox
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.2 }}
                                >
                                    <Box
                                        bg={bg}
                                        p={8}
                                        borderRadius="xl"
                                        shadow="xl"
                                        h={{ base: 'auto', lg: '350px', xl: '270px' }}
                                        position="relative"
                                        height="full"
                                    >
                                        <VStack spacing={4} align="start">
                                            <Box
                                                bg="purple.500"
                                                color="white"
                                                borderRadius="full"
                                                w={10}
                                                h={10}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                fontSize="lg"
                                                fontWeight="bold"
                                            >
                                                {index + 1}
                                            </Box>
                                            <Heading
                                                as="h3"
                                                fontSize="xl"
                                                color={headingColor}
                                            >
                                                {step.title}
                                            </Heading>
                                            <Text color={textColor}>
                                                {step.description}
                                            </Text>
                                            <HStack color="orange.500" mt="auto"
                                                position={{ base: 'relative', lg: 'absolute', xl: 'absolute' }}
                                                bottom={{ base: '0', lg: '30px', xl: '30px' }}
                                            >
                                                <Icon as={FaClock} />
                                                <Text fontSize="sm" fontWeight="medium">
                                                    {step.duration}
                                                </Text>
                                            </HStack>
                                        </VStack>
                                        {index < process.length - 1 && (
                                            <Box
                                                display={{ base: 'none', lg: 'block' }}
                                                position="absolute"
                                                right="-60%"
                                                top="50%"
                                                w="60%"
                                                h="2px"
                                                bg="purple.500"
                                                zIndex={0}
                                            />
                                        )}
                                    </Box>
                                </MotionBox>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>

            {/* Section Avantages */}
            <Container maxW="8xl" py={20}>
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16}>
                    <VStack align="start" spacing={8}>
                        <Badge colorScheme="purple"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm">Avantages</Badge>
                        <Heading fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
                            Pourquoi choisir notre solution e-commerce ?
                        </Heading>
                        <List spacing={4}>
                            {benefits.map((benefit, index) => (
                                <ListItem
                                    key={index}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <ListIcon
                                        as={FaCheck}
                                        color="green.500"
                                        w={5}
                                        h={5}
                                    />
                                    <Text color={textColor} fontSize="lg">
                                        {benefit}
                                    </Text>
                                </ListItem>
                            ))}
                        </List>
                    </VStack>

                    <SimpleGrid columns={1} spacing={8}>
                        {advancedFeatures.map((section, index) => (
                            <Box
                                key={index}
                                bg={cardBg}
                                p={6}
                                borderRadius="xl"
                                shadow="lg"
                            >
                                <Heading
                                    as="h3"
                                    fontSize="xl"
                                    mb={4}
                                    color={headingColor}
                                >
                                    {section.title}
                                </Heading>
                                <SimpleGrid columns={2} spacing={3}>
                                    {section.features.map((feature, idx) => (
                                        <HStack key={idx}>
                                            <Icon as={FaCheck} color="purple.500" />
                                            <Text fontSize="sm" color={textColor}>
                                                {feature}
                                            </Text>
                                        </HStack>
                                    ))}
                                </SimpleGrid>
                            </Box>
                        ))}
                    </SimpleGrid>
                </SimpleGrid>
            </Container>

            {/* CTA Final */}
            <Box bg={cardBg} py={20}>
                <Container maxW="3xl">
                    <VStack spacing={8} textAlign="center">
                        <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                            Prêt à lancer votre boutique en ligne ?
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            Transformez votre activité avec une boutique en ligne professionnelle
                            qui vous démarque de la concurrence et booste vos ventes.
                        </Text>
                        <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>

                            <Button
                                as={RouterLink}
                                to="/Contact"
                                colorScheme="purple"
                                size="lg"
                                rightIcon={<FaShoppingCart />}
                                _hover={{
                                    bg: 'white',            // Couleur de fond blanche au survol
                                    color: 'purple.600',     // Texte de couleur purple.600 au survol
                                    border: '1px solid',     // Ajoute une bordure
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl' // Couleur de la bordure purple.600
                                }}
                            >
                                Lancer Mon E-commerce
                            </Button>
                            <Button
                                as={RouterLink}
                                to="/Contact"
                                variant="outline"
                                colorScheme="purple"
                                size="lg"
                                _hover={{
                                    bg: 'white',            // Couleur de fond blanche au survol
                                    color: 'purple.600',     // Texte de couleur purple.600 au survol
                                    border: '1px solid',     // Ajoute une bordure
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl' // Couleur de la bordure purple.600
                                }}
                            >
                                En Savoir Plus
                            </Button>
                        </Flex>
                    </VStack>
                </Container>
            </Box>
        </Box>
    );
}