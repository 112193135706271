import React from 'react';
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Image,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../imagesComponents/RockYourNet.webp';
import LogoRYN from '../imagesComponents/Logo_RockYourNet650.webp';


// Section Héroïque (Hero)
const HeroSection = () => {

    const headingColor = useColorModeValue('gray.800', 'white');

    return (
        <Box
            as="section"
            bg={useColorModeValue('gray.100', 'gray.900')}
            py={{ base: '20', md: '160px' }}
            backgroundImage={`url(${Logo})`}  // Ajout de l'image en fond
            backgroundSize="cover"  // Assure que l'image couvre toute la section
            backgroundPosition="center"  // Centre l'image
            backgroundRepeat="no-repeat"  // Empêche la répétition
            position="relative"
            _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: useColorModeValue('rgba(255, 255, 255, 0.85)', 'rgba(0, 0, 0, 0.6)'), // Superposition selon le mode clair ou sombre
                zIndex: 1,  // Assure que l'overlay est derrière le contenu
            }}
        >
            <Container maxW="7xl" position="relative" zIndex={2}>
                <Flex justify="center" align="center" direction={{ base: 'column', md: 'row' }}>
                    <Box flex="1" textAlign={{ base: 'center', md: 'center' }}>
                        <Heading
                            as="h1"
                            fontSize={{ base: '3xl', md: '5xl' }}
                            fontWeight="bold"
                            lineHeight="short"
                            color={headingColor}
                        >
                            Développez votre présence en ligne avec <br></br> <Flex justify="center">
                                <Image src={LogoRYN} alt="Logo Rock Your Net" width={{ base: '80vw', md: '580px' }} my={10} />
                            </Flex>
                        </Heading>
                        <Text mt={4} fontSize={{ base: 'lg', md: '2xl' }} color={headingColor}
                        >
                            Nous créons des sites vitrines, des boutiques en ligne, des applications et optimisons votre référencement pour vous aider à réussir sur le web.
                        </Text>
                        <Button
                            as={RouterLink}
                            to="/contact"
                            mt={16}
                            size="lg"
                            colorScheme="purple"
                            px={6}
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                        >
                            Nous contacter
                        </Button>

                    </Box>
                </Flex>
            </Container>
        </Box>
    );
};

export default HeroSection;
