import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainProduits from '../sections/MainProduits';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet'; // Pour gérer les meta-données dans React

export default function Produits() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Application de Gestion de Site pour Garages et Concessionnaires | Rock Your Net</title>
                <meta name="description" content="Découvrez notre application de gestion de site web dédiée aux garages et concessionnaires. Gérez vos véhicules, services, et personnalisez votre site facilement." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Rock Your Net, application gestion site garage, gestion concessionnaire, site internet automobile, gestion véhicules, site web professionnel, développement web, application pour garage" />
                <link rel="canonical" href="https://rockyournet.com/Produits" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:title" content="Application de Gestion de Site pour Garages et Concessionnaires | Rock Your Net" />
                <meta property="og:description" content="Gérez vos véhicules, vos services, et personnalisez votre site avec l'application de gestion web de Rock Your Net, dédiée aux garages et concessionnaires." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Produits" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" /> {/* Remplacer par une image réelle */}

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Application de Gestion de Site pour Garages et Concessionnaires | Rock Your Net" />
                <meta name="twitter:description" content="Gérez facilement votre site web, vos véhicules et services grâce à notre application intuitive dédiée aux garages et concessionnaires." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" /> {/* Remplacer par une image réelle */}

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
        {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Application de Gestion de Site pour Garages et Concessionnaires",
            "url": "https://rockyournet.com/Produits",
            "logo": "https://rockyournet.com/static/media/logorockyournet.png",
            "sameAs": [
                "https://www.facebook.com/RockYourNet",
                "https://twitter.com/RockYourNet"
            ],
            "description": "Rock Your Net propose une application de gestion de sites web pour garages et concessionnaires. Personnalisez votre site, gérez vos véhicules et vos services facilement.",
            "brand": {
                "@type": "Brand",
                "name": "Rock Your Net"
            },
            "offers": {
                "@type": "Offer",
                "priceCurrency": "EUR",
                "price": "Sur demande",
                "url": "https://rockyournet.com/contact"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+33-6-24-31-10-75",
                "contactType": "Customer Service",
                "areaServed": "FR",
                "availableLanguage": "French"
            }
        }
        `}
                </script>
            </Helmet>


            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}

            >
                {/* Header (fixe avec Navbar) */}
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                {/* Section principale */}
                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainProduits />
                </GridItem>

                {/* Footer */}
                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};
