import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainMarketingBourgogne from '../../sections/référencement/MainMarketingBourgogne';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function MarketingBourgogne() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Agence Marketing Digital en Bourgogne | Rock Your Net</title>
                <meta name="description" content="Rock Your Net, votre agence de marketing digital en Bourgogne. Stratégies digitales personnalisées, social media, référencement et création web pour développer votre entreprise." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="marketing digital Bourgogne, agence digitale Bourgogne, stratégie web Bourgogne, social media Bourgogne, publicité digitale Bourgogne, communication web Bourgogne" />
                <link rel="canonical" href="https://rockyournet.com/Marketing-digital-bourgogne" />

                {/* Open Graph */}
                <meta property="og:title" content="Agence Marketing Digital en Bourgogne | Rock Your Net" />
                <meta property="og:description" content="Développez votre présence digitale en Bourgogne avec Rock Your Net. Expertise en marketing digital, réseaux sociaux et stratégie web." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Marketing-digital-bourgogne" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Agence Marketing Digital en Bourgogne | Rock Your Net" />
                <meta name="twitter:description" content="Solutions de marketing digital sur mesure pour les entreprises de Bourgogne. Stratégie web et communication digitale efficace." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "MarketingAgency",
                        "name": "Rock Your Net - Marketing Digital Bourgogne",
                        "image": "https://rockyournet.com/static/media/logorockyournet.png",
                        "description": "Agence de marketing digital basée en Bourgogne, spécialisée en stratégie digitale, social media et référencement web.",
                        "areaServed": {
                            "@type": "State",
                            "name": "Bourgogne"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services marketing digital",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Stratégie digitale"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Social Media Marketing"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Publicité en ligne"
                                    }
                                }
                            ]
                        },
                        "address": {
                            "@type": "PostalAddress",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainMarketingBourgogne />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};