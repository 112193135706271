import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainAgenceDigitaleBourgogne from '../../sections/référencement/MainAgenceDigitaleBourgogne';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function AgenceDigitaleBourgogne() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Agence Digitale en Bourgogne | Rock Your Net</title>
                <meta name="description" content="Rock Your Net, votre agence digitale en Bourgogne. Experts en transformation digitale, création web et stratégie numérique pour développer votre entreprise." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="agence digitale Bourgogne, agence web Bourgogne, transformation digitale Bourgogne, création site internet Bourgogne, marketing digital Bourgogne, communication digitale Bourgogne" />
                <link rel="canonical" href="https://rockyournet.com/Agence-digitale-bourgogne" />

                {/* Open Graph */}
                <meta property="og:title" content="Agence Digitale en Bourgogne | Rock Your Net" />
                <meta property="og:description" content="Rock Your Net, agence digitale en Bourgogne. Experts en transformation numérique et solutions web innovantes pour votre entreprise." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Agence-digitale-bourgogne" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Agence Digitale en Bourgogne | Rock Your Net" />
                <meta name="twitter:description" content="Agence digitale experte en transformation numérique en Bourgogne. Solutions web et marketing digital sur mesure." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net - Agence Digitale Bourgogne",
                        "image": "https://rockyournet.com/static/media/logorockyournet.png",
                        "description": "Agence digitale en Bourgogne spécialisée en transformation numérique, création web et marketing digital.",
                        "address": {
                            "@type": "PostalAddress",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCircle",
                            "geoMidpoint": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5695"
                            },
                            "geoRadius": "100000"
                        },
                        "areaServed": {
                            "@type": "State",
                            "name": "Bourgogne"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services digitaux",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création de sites web"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Marketing digital"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Transformation numérique"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainAgenceDigitaleBourgogne />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};