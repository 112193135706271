import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainReferencementAuxerre from '../../sections/référencement/MainReferencementAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function ReferencementAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Expert Référencement SEO à Auxerre | Rock Your Net</title>
                <meta name="description" content="Améliorez votre visibilité en ligne avec Rock Your Net, expert en référencement SEO à Auxerre. Stratégies SEO personnalisées et résultats durables." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="référencement SEO Auxerre, SEO Auxerre, optimisation site web Auxerre, agence SEO Auxerre, positionnement Google Auxerre, visibilité web Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Referencement-auxerre" />

                {/* Open Graph */}
                <meta property="og:title" content="Expert Référencement SEO à Auxerre | Rock Your Net" />
                <meta property="og:description" content="Améliorez votre visibilité en ligne avec Rock Your Net. Expertise en référencement naturel SEO à Auxerre pour booster votre présence sur Google." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Referencement-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Expert Référencement SEO à Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Référencement naturel et optimisation SEO à Auxerre. Stratégies personnalisées pour améliorer votre classement sur Google." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "MarketingAgency",
                        "name": "Rock Your Net - Référencement SEO Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet.png",
                        "description": "Agence spécialisée en référencement naturel SEO à Auxerre. Expert en optimisation web et stratégies de positionnement Google.",
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services de référencement",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Audit SEO"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Optimisation on-page"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Référencement local"
                                    }
                                }
                            ]
                        },
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "postalCode": "89000",
                            "addressCountry": "FR"
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainReferencementAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};