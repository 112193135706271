import React, { useState } from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Image,
    SimpleGrid,
    Flex,
    Button,
    List,
    ListItem,
    ListIcon,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useColorModeValue,
    Link,
    VStack,
    HStack,
    Icon,
    Badge,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    Divider,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    useBreakpointValue,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    NumberInput,
    NumberInputField,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import {
    FaCarSide,
    FaStar,
    FaTools,
    FaUsers,
    FaChartLine,
    FaCog,
    FaLaptopCode,
    FaShieldAlt,
    FaHeadset,
    FaSearch,
    FaInfinity,
    FaCheckCircle,
    FaRegClock,
    FaImage,
    FaPalette,
    FaImages,
    FaEnvelope,
    FaMobile,
    FaClock,
    FaRocket,
    FaCalculator,
    FaChartBar,
    FaUsersCog,
    FaRegHandshake,
    FaPlug,
    FaLaptop,
    FaArrowRight
} from 'react-icons/fa';
import { motion } from 'framer-motion';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import des images
import AppImage from '../imagesComponents/GarageAdmin.jpg';
import DashboardImage from '../imagesComponents/GarageAdmin.jpg';
import MobileImage from '../imagesComponents/clients.jpg';
import EspaceAdmin from '../imagesComponents/EspaceAdminGarage.jpg';
import Contact from '../pages/Contact';

const MotionBox = motion(Box);

// Données des plans tarifaires
const pricingPlans = [
    {
        name: "Essentiel",
        price: "89€",
        period: "/mois",
        features: [
            "Jusqu'à 20 véhicules",
            "Interface administration",
            "Gestion des services",
            "SEO de base",
            "Support email"
        ],
        badge: "",
        buttonText: "Commencer",
        isPopular: false
    },
    {
        name: "Pro",
        price: "119€",
        period: "/mois",
        features: [
            "Véhicules illimités",
            "Interface personnalisable",
            "SEO avancé",
            "Multi-utilisateurs",
            "Support prioritaire",
            "Analytics avancés"
        ],
        badge: "Plus populaire",
        buttonText: "Commencer",
        isPopular: true
    },
    {
        name: "Enterprise",
        price: "Sur mesure",
        period: "",
        features: [
            "Fonctionnalités personnalisées",
            "API dédiée",
            "Support 24/7",
            "Formation sur site",
            "Infrastructure dédiée",
            "+ Avantages Formule Pro"
        ],
        badge: "Personnalisé",
        buttonText: "Nous contacter",
        isPopular: false
    }
];

// Témoignages clients
const testimonials = [
    {
        name: "Jean Dupont",
        role: "Directeur, Garage Central",
        image: "https://via.placeholder.com/150",
        text: "Cette application a transformé la gestion de notre garage. La simplicité d'utilisation et l'efficacité sont remarquables.",
        rating: 5
    },
    {
        name: "Marie Martin",
        role: "Gérante, Concession AutoPremium",
        image: "https://via.placeholder.com/150",
        text: "Un outil indispensable pour notre concession. Le support client est exceptionnel.",
        rating: 5
    },
    {
        name: "Pierre Dubois",
        role: "Propriétaire, Garage Express",
        image: "https://via.placeholder.com/150",
        text: "Nous avons augmenté nos ventes de 30% grâce à la visibilité en ligne que cette solution nous apporte.",
        rating: 5
    }
];

// Timeline d'implémentation
const implementationSteps = [
    {
        title: "Analyse des besoins",
        description: "Étude approfondie de vos besoins spécifiques",
        duration: "2-3 jours",
        icon: FaUsers
    },
    {
        title: "Configuration",
        description: "Mise en place et personnalisation de votre plateforme",
        duration: "1 semaine",
        icon: FaCog
    },
    {
        title: "Lancement",
        description: "Mise en production et support",
        duration: "2-3 jours",
        icon: FaRocket
    },
    {
        title: "Formation",
        description: "Formation complète de votre équipe",
        duration: "1 jour",
        icon: FaUsersCog
    }
];

// Tableau comparatif des fonctionnalités
const featureComparison = {
    categories: [
        {
            name: "Gestion des véhicules",
            features: [
                "Ajout de véhicules",
                "Photos multiples",
                "Descriptions détaillées",
                "Historique des modifications",
                "Export PDF"
            ]
        },
        {
            name: "Services",
            features: [
                "Présentation des services",
                "Tarification",
                "Prise de rendez-vous",
                "Historique client"
            ]
        },
        {
            name: "Marketing",
            features: [
                "SEO automatique",
                "Intégration réseaux sociaux",
                "Newsletter",
                "Statistiques de visites"
            ]
        }
    ],
    plans: {
        "Essentiel": ["✓", "✓", "✓", "−", "−", "✓", "✓", "−", "−", "✓", "−", "−", "−"],
        "Pro": ["✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "−"],
        "Enterprise": ["✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓", "✓"]
    }
};

// Intégrations techniques disponibles
const integrations = [
    {
        name: "API REST",
        description: "Intégration facile avec vos systèmes existants",
        icon: FaLaptopCode
    },
    {
        name: "CRM Automobile",
        description: "Compatible avec les principaux CRM du marché",
        icon: FaUsers
    },
    {
        name: "Outils de diagnostic",
        description: "Connexion avec vos outils de diagnostic",
        icon: FaTools
    },
    {
        name: "Plateformes de vente",
        description: "Export vers les principales plateformes d'annonces",
        icon: FaCarSide
    }
];

// Statistiques de performance
const performanceStats = [
    {
        label: "Augmentation moyenne des ventes",
        value: "35%",
        icon: FaChartLine
    },
    {
        label: "Temps gagné par semaine",
        value: "12h",
        icon: FaRegClock
    },
    {
        label: "Satisfaction client",
        value: "96%",
        icon: FaRegHandshake
    }
];


const ProductPage = () => {
    const [roiCalculatorValues, setRoiCalculatorValues] = useState({
        vehicleCount: 30,
        averagePrice: 15000,
        conversionRate: 2
    });

    // Theme colors
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const bg = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.700');
    const statBg = useColorModeValue('purple.50', 'purple.900');
    const TopPointsBG = useColorModeValue('gray.50', 'gray.800');
    const accentColor = useColorModeValue('purple.700', 'orange.200');
    const badgeBg = useColorModeValue('orange.200', 'purple.800');


    // Calculateur de ROI
    const calculateROI = () => {
        const monthlyRevenue = (roiCalculatorValues.vehicleCount * roiCalculatorValues.averagePrice * (roiCalculatorValues.conversionRate / 100)) / 12;
        return monthlyRevenue.toLocaleString('fr-FR');
    };

    return (
        <Box as="section" bg={bg} minH="100vh">
            {/* Hero Section */}
            <Container maxW="8xl" pt={{ base: 20, md: 28 }} pb={{ base: 16, md: 24 }}>
                <VStack spacing={8} alignItems="center" textAlign="center">
                    <Badge
                        colorScheme="purple"
                        fontSize="md"
                        px={6}
                        py={1}
                        borderRadius="full"
                    >
                        Solution Automobile Complète
                    </Badge>
                    <Heading
                        as="h1"
                        fontSize={{ base: "4xl", md: "5xl", lg: "5xl" }}
                        fontWeight="bold"
                        color={headingColor}
                        lineHeight="shorter"
                    >
                        Gérez le site internet de votre Garage en Toute Simplicité
                    </Heading>
                    <Text
                        fontSize={{ base: "lg", md: "xl" }}
                        color={textColor}
                        maxW="3xl"
                    >
                        Une solution tout-en-un pour les professionnels de l'automobile.
                        Gérez vos véhicules, vos services et votre présence en ligne efficacement.
                    </Text>
                    <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>
                        <Button
                            as={RouterLink}
                            to="/contact"
                            size="lg"
                            colorScheme="purple"
                            px={8}
                            fontSize="md"
                            h={14}
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                        >
                            Demander une démo
                        </Button>
                        <Button
                            as="a"
                            href="https://garage-parrot.eu"
                            target="_blank"
                            size="lg"
                            variant="outline"
                            colorScheme="purple"
                            px={8}
                            fontSize="md"
                            h={14}
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                        >
                            Voir la démo live
                        </Button>
                    </Flex>
                </VStack>

                {/* Stats Section */}
                {/*   <SimpleGrid
                    columns={{ base: 1, md: 3 }}
                    spacing={{ base: 5, lg: 8 }}
                    mt={20}
                >
                    {performanceStats.map((stat, index) => (
                        <MotionBox
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.2 }}
                            bg={statBg}
                            p={6}
                            borderRadius="lg"
                            textAlign="center"
                        >
                            <Icon as={stat.icon} w={8} h={8} color={accentColor} mb={4} />
                            <Stat>
                                <StatNumber fontSize="3xl" color={headingColor}>
                                    {stat.value}
                                </StatNumber>
                                <StatLabel fontSize="md" color={textColor}>
                                    {stat.label}
                                </StatLabel>
                            </Stat>
                        </MotionBox>
                    ))}
                </SimpleGrid>  */}
            </Container>

            {/* Features Section */}
            {/* Features Section */}
            <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <VStack spacing={20}>
                        {/* First Feature Block */}
                        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: 8, lg: 16 }} alignItems="center">
                            <MotionBox
                                initial={{ opacity: 0, x: -20 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5 }}
                                viewport={{ once: true }}
                            >
                                <Image
                                    src={EspaceAdmin}
                                    alt="Dashboard"
                                    borderRadius="2xl"
                                    shadow="2xl"
                                    transition="transform 0.3s ease"
                                    loading="lazy"
                                />
                            </MotionBox>

                            <MotionBox
                                initial={{ opacity: 0, x: 20 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5 }}
                                viewport={{ once: true }}
                            >
                                <VStack align="start" spacing={8}>
                                    <Badge colorScheme="purple" px={3} py={1} borderRadius="full">
                                        Interface Intuitive
                                    </Badge>
                                    <Heading as="h2" fontSize={{ base: "2xl", md: "3xl" }} color={headingColor}>
                                        Un Tableau de Bord Puissant et Complet
                                    </Heading>
                                    <Text color={textColor} fontSize="lg" lineHeight="tall">
                                        Notre tableau de bord vous permet de gérer l'ensemble de votre activité
                                        depuis une seule interface. Simplifiez votre gestion quotidienne avec
                                        nos outils intuitifs et performants.
                                    </Text>
                                    <SimpleGrid
                                        columns={{ base: 1, sm: 2 }}
                                        spacing={{ base: 4, sm: 8 }}
                                        w="full"
                                    >
                                        {[
                                            {
                                                icon: FaCarSide,
                                                text: "Gestion des véhicules"
                                            },
                                            {
                                                icon: FaTools,
                                                text: "Services automobiles"
                                            },
                                            {
                                                icon: FaChartLine,
                                                text: "Statistiques en temps réel"
                                            },
                                            {
                                                icon: FaUsers,
                                                text: "Multi-utilisateurs"
                                            },
                                            {
                                                icon: FaSearch,
                                                text: "SEO optimisé"
                                            },
                                            {
                                                icon: FaCog,
                                                text: "Interface personnalisable"
                                            },
                                            {
                                                icon: FaHeadset,
                                                text: "Support client"
                                            },
                                            {
                                                icon: FaInfinity,
                                                text: "Véhicules illimités"
                                            }
                                        ].map((feature, index) => (
                                            <MotionBox
                                                key={index}
                                                initial={{ opacity: 0, y: 10 }}
                                                whileInView={{ opacity: 1, y: 0 }}
                                                transition={{ delay: index * 0.1 }}
                                                viewport={{ once: true }}
                                            >
                                                <HStack
                                                    p={3}
                                                    bg={TopPointsBG}
                                                    borderRadius="lg"
                                                    shadow="md"
                                                    transition="all 0.3s"
                                                    _hover={{
                                                        transform: 'translateY(-2px)',
                                                        shadow: 'lg'
                                                    }}
                                                >
                                                    <Icon as={feature.icon} color="orange.500" w={5} h={5} />
                                                    <Text color={textColor} fontSize="sm" fontWeight="medium">
                                                        {feature.text}
                                                    </Text>
                                                </HStack>
                                            </MotionBox>
                                        ))}
                                    </SimpleGrid>
                                </VStack>
                            </MotionBox>
                        </SimpleGrid>

                        {/* Second Feature Block */}
                        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: 8, lg: 16 }} alignItems="center">
                            <MotionBox
                                initial={{ opacity: 0, x: -20 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5 }}
                                viewport={{ once: true }}
                                order={{ base: 1, lg: 2 }}
                            >
                                <Image
                                    src={MobileImage}
                                    alt="Personnalisation"
                                    borderRadius="2xl"
                                    shadow="2xl"
                                    transition="transform 0.3s ease"
                                    _hover={{ transform: 'scale(1.02)' }}
                                    loading="lazy"
                                />
                            </MotionBox>

                            <MotionBox
                                initial={{ opacity: 0, x: 20 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5 }}
                                viewport={{ once: true }}
                                order={{ base: 2, lg: 1 }}
                            >
                                <VStack align="start" spacing={8}>
                                    <Badge colorScheme="purple" px={3} py={1} borderRadius="full">
                                        Personnalisation
                                    </Badge>
                                    <Heading as="h2" fontSize={{ base: "2xl", md: "3xl" }} color={headingColor}>
                                        Une Liberté Totale de Personnalisation
                                    </Heading>
                                    <Text color={textColor} fontSize="lg" lineHeight="tall">
                                        Créez une expérience unique pour vos clients avec nos outils de personnalisation
                                        avancés. Adaptez chaque aspect de votre interface selon vos besoins et votre image de marque.
                                    </Text>
                                    <SimpleGrid
                                        columns={{ base: 1, sm: 2 }}
                                        spacing={{ base: 4, sm: 8 }}
                                        w="full"
                                    >
                                        {[
                                            {
                                                icon: FaImage,
                                                text: "Gestion des logos"
                                            },
                                            {
                                                icon: FaPalette,
                                                text: "Gestion des couleurs"
                                            },
                                            {
                                                icon: FaImages,
                                                text: "Gestion des images"
                                            },
                                            {
                                                icon: FaEnvelope,
                                                text: "Gestion des messages"
                                            },
                                            {
                                                icon: FaStar,
                                                text: "Gestion des avis-clients"
                                            },
                                            {
                                                icon: FaUsersCog,
                                                text: "Gestion des employés"
                                            }
                                        ].map((feature, index) => (
                                            <MotionBox
                                                key={index}
                                                initial={{ opacity: 0, y: 10 }}
                                                whileInView={{ opacity: 1, y: 0 }}
                                                transition={{ delay: index * 0.1 }}
                                                viewport={{ once: true }}
                                            >
                                                <HStack
                                                    p={3}
                                                    bg={TopPointsBG}
                                                    borderRadius="lg"
                                                    shadow="md"
                                                    transition="all 0.3s"
                                                    _hover={{
                                                        transform: 'translateY(-2px)',
                                                        shadow: 'lg'
                                                    }}
                                                >
                                                    <Icon as={feature.icon} color="orange.500" w={5} h={5} />
                                                    <Text color={textColor} fontSize="sm" fontWeight="medium">
                                                        {feature.text}
                                                    </Text>
                                                </HStack>
                                            </MotionBox>
                                        ))}
                                    </SimpleGrid>
                                </VStack>
                            </MotionBox>
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>

            {/* Je continue avec les autres sections ? */}
            {/* Pricing Section */}
            <Box py={20}>
                <Container maxW="8xl">
                    <VStack spacing={8} textAlign="center" mb={16}>
                        <Heading as="h2" fontSize="3xl" color={headingColor}>
                            Tarification Transparente
                        </Heading>
                        <Text color={textColor} maxW="2xl" fontSize="lg">
                            Choisissez le plan qui correspond le mieux à vos besoins.
                            Tous nos plans incluent les mises à jour et le support technique.
                        </Text>
                    </VStack>

                    <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={8}>
                        {pricingPlans.map((plan, index) => (
                            <MotionBox
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.2 }}
                                bg={cardBg}
                                h={{ base: 'auto', lg: '500px', xl: '475px' }}
                                p={8}
                                borderRadius="xl"
                                shadow={plan.isPopular ? "2xl" : "lg"}
                                border="2px solid"
                                borderColor={plan.isPopular ? "purple.500" : "transparent"}
                                transform={plan.isPopular ? "scale(1.05)" : "scale(1)"}
                                position="relative"
                            >
                                {plan.badge && (
                                    <Badge
                                        colorScheme="purple"
                                        bg={badgeBg}
                                        color={accentColor}
                                        position="absolute"
                                        top={4}
                                        right={4}
                                        px={3}
                                        py={1}
                                        borderRadius="full"
                                    >
                                        {plan.badge}
                                    </Badge>
                                )}
                                <VStack spacing={6} align="stretch">
                                    <Heading as="h3" fontSize="2xl" color={headingColor}>
                                        {plan.name}
                                    </Heading>
                                    <HStack spacing={1} justify="center">
                                        <Text fontSize="4xl" fontWeight="bold" color={headingColor}>
                                            {plan.price}
                                        </Text>
                                        <Text color={textColor}>{plan.period}</Text>
                                    </HStack>
                                    <List spacing={3}>
                                        {plan.features.map((feature, idx) => (
                                            <ListItem key={idx}>
                                                <HStack>
                                                    <Icon as={FaCheckCircle} color="green.500" />
                                                    <Text color={textColor}>{feature}</Text>
                                                </HStack>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Box display="flex" justifyContent="center">

                                        <Button
                                            as={RouterLink}
                                            to="/Contact"
                                            colorScheme={plan.isPopular ? "purple" : "gray"}
                                            variant={plan.isPopular ? "solid" : "outline"}
                                            size="lg"
                                            mt="auto"
                                            w={{ base: '100%', lg: '80%', xl: '85%' }}
                                            position={{ base: 'relative', lg: 'absolute', xl: 'absolute' }}
                                            bottom={{ base: '0', lg: '30px', xl: '30px' }}
                                            _hover={{
                                                transform: 'translateY(-2px)',
                                                shadow: 'lg',
                                            }}
                                        >
                                            {plan.buttonText}
                                        </Button>
                                    </Box>
                                </VStack>
                            </MotionBox>
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>

            {/* ROI Calculator Section */}
            {/*   <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Heading as="h2" fontSize="3xl" color={headingColor}>
                                Calculez Votre Retour sur Investissement
                            </Heading>
                            <Text color={textColor} maxW="2xl" fontSize="lg">
                                Estimez rapidement les bénéfices potentiels de notre solution pour votre entreprise
                            </Text>
                        </VStack>

                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} w="full" alignItems="center">
                            <VStack spacing={8} align="stretch" bg={bg} p={8} borderRadius="xl">
                                <VStack spacing={4}>
                                    <Text fontSize="lg" fontWeight="medium" color={headingColor}>
                                        Nombre de véhicules en stock
                                    </Text>
                                    <Slider
                                        value={roiCalculatorValues.vehicleCount}
                                        onChange={(v) => setRoiCalculatorValues(prev => ({ ...prev, vehicleCount: v }))}
                                        min={10}
                                        max={200}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack bg="purple.500" />
                                        </SliderTrack>
                                        <SliderThumb boxSize={6} />
                                    </Slider>
                                    <NumberInput
                                        value={roiCalculatorValues.vehicleCount}
                                        onChange={(_, v) => setRoiCalculatorValues(prev => ({ ...prev, vehicleCount: v }))}
                                        min={10}
                                        max={200}
                                    >
                                        <NumberInputField />
                                    </NumberInput>
                                </VStack>

                                <VStack spacing={4}>
                                    <Text fontSize="lg" fontWeight="medium" color={headingColor}>
                                        Prix moyen par véhicule (€)
                                    </Text>
                                    <Slider
                                        value={roiCalculatorValues.averagePrice}
                                        onChange={(v) => setRoiCalculatorValues(prev => ({ ...prev, averagePrice: v }))}
                                        min={5000}
                                        max={50000}
                                        step={1000}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack bg="purple.500" />
                                        </SliderTrack>
                                        <SliderThumb boxSize={6} />
                                    </Slider>
                                    <NumberInput
                                        value={roiCalculatorValues.averagePrice}
                                        onChange={(_, v) => setRoiCalculatorValues(prev => ({ ...prev, averagePrice: v }))}
                                        min={5000}
                                        max={50000}
                                        step={1000}
                                    >
                                        <NumberInputField />
                                    </NumberInput>
                                </VStack>

                                <VStack spacing={4}>
                                    <Text fontSize="lg" fontWeight="medium" color={headingColor}>
                                        Taux de conversion estimé (%)
                                    </Text>
                                    <Slider
                                        value={roiCalculatorValues.conversionRate}
                                        onChange={(v) => setRoiCalculatorValues(prev => ({ ...prev, conversionRate: v }))}
                                        min={1}
                                        max={5}
                                        step={0.1}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack bg="purple.500" />
                                        </SliderTrack>
                                        <SliderThumb boxSize={6} />
                                    </Slider>
                                    <NumberInput
                                        value={roiCalculatorValues.conversionRate}
                                        onChange={(_, v) => setRoiCalculatorValues(prev => ({ ...prev, conversionRate: v }))}
                                        min={1}
                                        max={5}
                                        step={0.1}
                                    >
                                        <NumberInputField />
                                    </NumberInput>
                                </VStack>
                            </VStack>

                            <Box bg={bg} p={8} borderRadius="xl" textAlign="center">
                                <VStack spacing={6}>
                                    <Heading as="h3" fontSize="2xl" color={headingColor}>
                                        Revenus potentiels mensuels
                                    </Heading>
                                    <Text fontSize="4xl" fontWeight="bold" color="purple.500">
                                        {calculateROI()}€
                                    </Text>
                                    <Text color={textColor}>
                                        Cette estimation est basée sur vos données et nos statistiques moyennes.
                                        Les résultats réels peuvent varier.
                                    </Text>
                                    <Button
                                        colorScheme="purple"
                                        size="lg"
                                        rightIcon={<Icon as={FaCalculator} />}
                                        onClick={() => { }}
                                    >
                                        Obtenir une analyse détaillée
                                    </Button>
                                </VStack>
                            </Box>
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>  */}
            {/* Timeline Section */}
            <Box py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Heading as="h2" fontSize="3xl" color={headingColor}>
                                Processus d'Implémentation
                            </Heading>
                            <Text color={textColor} maxW="2xl" fontSize="lg">
                                Une mise en place rapide et efficace en 4 étapes simples
                            </Text>
                        </VStack>

                        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
                            {implementationSteps.map((step, index) => (
                                <MotionBox
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: index * 0.2 }}
                                    bg={cardBg}
                                    p={6}
                                    borderRadius="xl"
                                    shadow="lg"
                                    position="relative"
                                    h={{ base: 'auto', lg: '320px', xl: '270px' }}

                                >
                                    <VStack spacing={4}>
                                        <Box
                                            p={4}
                                            bg="purple.500"
                                            borderRadius="full"
                                            color="white"
                                        >
                                            <Icon as={step.icon} w={6} h={6} />
                                        </Box>
                                        <Text
                                            fontSize="lg"
                                            fontWeight="bold"
                                            color={headingColor}
                                        >
                                            {step.title}
                                        </Text>
                                        <Text color={textColor} textAlign="center">
                                            {step.description}
                                        </Text>
                                        <HStack color="orange.500"
                                            position={{ base: 'relative', lg: 'absolute', xl: 'absolute' }}
                                            bottom={{ base: '0', lg: '30px', xl: '30px' }} >
                                            <Icon as={FaClock} />
                                            <Text fontSize="sm" fontWeight="medium">
                                                {step.duration}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                    {index < implementationSteps.length - 1 && (
                                        <Box
                                            display={{ base: 'none', lg: 'block' }}
                                            position="absolute"
                                            right="-100%"
                                            top="50%"
                                            transform="translateY(-50%)"
                                            width="100%"
                                            height="2px"
                                            bg="purple.500"
                                            zIndex={0}
                                        />
                                    )}
                                </MotionBox>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>

            {/* Testimonials Section */}
            {/*    <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Heading as="h2" fontSize="3xl" color={headingColor}>
                                Ce Que Disent Nos Clients
                            </Heading>
                            <Text color={textColor} maxW="2xl" fontSize="lg">
                                Découvrez les retours d'expérience de nos utilisateurs
                            </Text>
                        </VStack>

                        <Box w="full" px={{ base: 4, md: 8 }}>
                            <Swiper
                                modules={[Navigation, Pagination, Autoplay]}
                                spaceBetween={30}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                                autoplay={{ delay: 5000 }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                    },
                                }}
                            >
                                {testimonials.map((testimonial, index) => (
                                    <SwiperSlide key={index}>
                                        <Box
                                            bg={bg}
                                            p={8}
                                            borderRadius="xl"
                                            shadow="lg"
                                            height="full"
                                        >
                                            <VStack spacing={6} height="full">
                                                <Image
                                                    src={testimonial.image}
                                                    alt={testimonial.name}
                                                    borderRadius="full"
                                                    boxSize="100px"
                                                    objectFit="cover"
                                                />
                                                <Text fontSize="lg" color={textColor}>
                                                    "{testimonial.text}"
                                                </Text>
                                                <VStack spacing={1} mt="auto">
                                                    <Text
                                                        fontWeight="bold"
                                                        fontSize="lg"
                                                        color={headingColor}
                                                    >
                                                        {testimonial.name}
                                                    </Text>
                                                    <Text color={textColor}>
                                                        {testimonial.role}
                                                    </Text>
                                                </VStack>
                                                <HStack spacing={1}>
                                                    {Array(testimonial.rating)
                                                        .fill("")
                                                        .map((_, i) => (
                                                            <Icon
                                                                key={i}
                                                                as={FaStar}
                                                                color="yellow.400"
                                                            />
                                                        ))}
                                                </HStack>
                                            </VStack>
                                        </Box>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Box>
                    </VStack>
                </Container>
            </Box>  */}

            {/* Integrations Section */}
            {/*   <Box py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Heading as="h2" fontSize="3xl" color={headingColor}>
                                Intégrations Techniques
                            </Heading>
                            <Text color={textColor} maxW="2xl" fontSize="lg">
                                Notre solution s'intègre parfaitement avec vos outils existants
                            </Text>
                        </VStack>

                        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
                            {integrations.map((integration, index) => (
                                <Box
                                    key={index}
                                    bg={cardBg}
                                    p={6}
                                    borderRadius="xl"
                                    shadow="lg"
                                    transition="all 0.3s"
                                    _hover={{
                                        transform: 'translateY(-4px)',
                                        shadow: 'xl',
                                    }}
                                >
                                    <VStack spacing={4}>
                                        <Icon
                                            as={integration.icon}
                                            w={10}
                                            h={10}
                                            color="purple.500"
                                        />
                                        <Text
                                            fontSize="lg"
                                            fontWeight="bold"
                                            color={headingColor}
                                        >
                                            {integration.name}
                                        </Text>
                                        <Text color={textColor} textAlign="center">
                                            {integration.description}
                                        </Text>
                                    </VStack>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>   */}

            {/* FAQ Section */}
            {/* FAQ Section */}
            <Box bg={cardBg} py={20}>
                <Container maxW="4xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Badge
                                colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm"
                            >
                                FAQ
                            </Badge>
                            <Heading as="h2" fontSize="3xl" color={headingColor}>
                                Questions Fréquentes
                            </Heading>
                            <Text color={textColor} maxW="2xl" fontSize="lg">
                                Trouvez rapidement des réponses à vos questions
                            </Text>
                        </VStack>

                        <Accordion allowMultiple w="full">
                            {[
                                {
                                    question: "Combien de temps faut-il pour mettre en place la solution ?",
                                    answer: "En général, la mise en place complète prend entre 1 et 2 semaines, incluant la configuration, la personnalisation et la formation de votre équipe.",
                                    icon: FaRegClock
                                },
                                {
                                    question: "Quelles sont les fonctionnalités incluses dans l'application ?",
                                    answer: "L'application inclut la gestion des véhicules d'occasion, la présentation de vos services, l'optimisation SEO, la gestion multi-utilisateurs, la personnalisation du site, et la validation des témoignages clients.",
                                    icon: FaCog
                                },
                                {
                                    question: "Puis-je personnaliser le design de mon site ?",
                                    answer: "Oui, vous pouvez personnaliser le logo, les couleurs, les images, et ajouter vos propres contenus pour rendre votre site unique.",
                                    icon: FaPalette
                                },
                                {
                                    question: "Puis-je ajouter plusieurs utilisateurs avec des rôles différents ?",
                                    answer: "Oui, vous pouvez créer des comptes pour vos employés et leur donner accès à certaines fonctionnalités spécifiques, comme répondre aux messages clients ou ajouter de nouveaux véhicules.",
                                    icon: FaUsersCog
                                },
                                {
                                    question: "Quel type de support est inclus ?",
                                    answer: "Tous nos plans incluent un support par email et téléphone en heures ouvrées. Les plans Pro et Enterprise bénéficient d'un support prioritaire et d'un accompagnement personnalisé.",
                                    icon: FaHeadset
                                },
                                {
                                    question: "Comment puis-je tester l'application ?",
                                    answer: "Vous pouvez demander une démo ou visiter notre site fictif Garage Parrot pour explorer toutes les fonctionnalités.",
                                    icon: FaLaptop
                                }
                            ].map((item, index) => (
                                <AccordionItem
                                    key={index}
                                    border="1px solid"
                                    borderColor="gray.100"
                                    borderRadius="lg"
                                    mb={4}
                                    overflow="hidden"
                                    bg="gray.50"
                                    shadow="md"
                                >
                                    <h3>
                                        <AccordionButton
                                            py={4}
                                            px={5}
                                            _hover={{
                                                bg: "purple.100"
                                            }}
                                        >
                                            <HStack flex="1" textAlign="left" spacing={4}>
                                                <Icon
                                                    as={item.icon}
                                                    color="orange.500"
                                                    w={5}
                                                    h={5}
                                                />
                                                <Text
                                                    color="black"
                                                    fontWeight="medium"
                                                    fontSize="lg"
                                                >
                                                    {item.question}
                                                </Text>
                                            </HStack>
                                            <AccordionIcon color="purple.500" />
                                        </AccordionButton>
                                    </h3>
                                    <AccordionPanel
                                        pb={4}
                                        px={5}
                                        pt={2}
                                        bg="white"
                                    >
                                        <Text
                                            color={textColor}
                                            fontSize="md"
                                            lineHeight="tall"
                                        >
                                            {item.answer}
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>

                        {/* CTA dans la FAQ */}
                        <Box textAlign="center" pt={8}>
                            <Text fontSize="lg" color={textColor} mb={4}>
                                Vous avez d'autres questions ?
                            </Text>
                            <Button
                                as={RouterLink}
                                to="/contact"
                                colorScheme="purple"
                                size="lg"
                                rightIcon={<Icon as={FaArrowRight} />}
                                _hover={{
                                    bg: 'white',            // Couleur de fond blanche au survol
                                    color: 'purple.600',     // Texte de couleur purple.600 au survol
                                    border: '1px solid',     // Ajoute une bordure
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl' // Couleur de la bordure purple.600
                                }}
                            >
                                Contactez-nous
                            </Button>
                        </Box>
                    </VStack>
                </Container>
            </Box>

            {/* Final CTA Section */}
            <Box py={20}>
                <Container maxW="3xl" textAlign="center">
                    <VStack spacing={8}>
                        <Heading as="h2" fontSize="3xl" color={headingColor}>
                            Prêt à Transformer Votre Garage ?
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            Rejoignez les centaines de professionnels qui font confiance à notre solution
                            pour développer leur activité.
                        </Text>
                        <HStack spacing={4}>
                            <Button
                                as={RouterLink}
                                to="/contact"
                                colorScheme="purple"
                                size="lg"
                                px={8}
                                rightIcon={<Icon as={FaRocket} />}
                                _hover={{
                                    bg: 'white',            // Couleur de fond blanche au survol
                                    color: 'purple.600',     // Texte de couleur purple.600 au survol
                                    border: '1px solid',     // Ajoute une bordure
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl' // Couleur de la bordure purple.600
                                }}
                            >
                                Démarrer Maintenant
                            </Button>
                        </HStack>
                    </VStack>
                </Container>
            </Box>
        </Box>
    );
};

export default ProductPage;