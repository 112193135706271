import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainMaintenanceYonne from '../../sections/référencement/MainMaintenanceYonne';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function MaintenanceYonne() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Maintenance Site Web Yonne | Service Maintenance Web Pro</title>
                <meta name="description" content="Service professionnel de maintenance de sites web dans l'Yonne. Mises à jour régulières, sécurité, sauvegardes et support technique 24/7 pour votre site internet." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="maintenance site web Yonne, maintenance WordPress Yonne, support technique web Yonne, mise à jour site internet Yonne, sécurité site web Yonne" />
                <link rel="canonical" href="https://rockyournet.com/Maintenance-yonne" />

                {/* Open Graph */}
                <meta property="og:title" content="Maintenance Site Web Yonne | Rock Your Net" />
                <meta property="og:description" content="Service professionnel de maintenance web dans l'Yonne. Mises à jour, sécurité et support technique pour votre site internet." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Maintenance-yonne" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Maintenance Site Web Yonne | Rock Your Net" />
                <meta name="twitter:description" content="Service professionnel de maintenance web dans l'Yonne. Support technique 24/7 et mises à jour régulières." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "TechnicalService",
                        "name": "Rock Your Net - Maintenance Site Web Yonne",
                        "image": "https://rockyournet.com/static/media/logorockyournet.png",
                        "description": "Service professionnel de maintenance de sites web dans l'Yonne. Support technique, mises à jour et sécurité.",
                        "areaServed": {
                            "@type": "State",
                            "name": "Yonne",
                            "address": {
                                "@type": "PostalAddress",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            }
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services de maintenance web",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Maintenance WordPress"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Support technique 24/7"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Sécurité et sauvegardes"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainMaintenanceYonne />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};