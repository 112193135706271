import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainWordPressAuxerre from '../../sections/référencement/MainWordpressAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function WordPressAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Création Site WordPress à Auxerre | Expert WordPress | Rock Your Net</title>
                <meta name="description" content="Expert en création de sites WordPress à Auxerre. Sites professionnels, responsive et optimisés SEO. Développement sur mesure et accompagnement personnalisé." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="création site wordpress auxerre, développement wordpress auxerre, site wordpress auxerre, expert wordpress auxerre, cms wordpress auxerre, maintenance wordpress auxerre" />
                <link rel="canonical" href="https://rockyournet.com/wordpress-auxerre" />

                {/* Open Graph */}
                <meta property="og:title" content="Création Site WordPress à Auxerre | Expert WordPress | Rock Your Net" />
                <meta property="og:description" content="Création de sites WordPress professionnels à Auxerre. Expertise technique et design personnalisé pour votre projet web." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/wordpress-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Création Site WordPress à Auxerre | Expert WordPress" />
                <meta name="twitter:description" content="Création de sites WordPress professionnels et sur mesure à Auxerre. Design responsive et optimisation SEO." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "name": "Création Site WordPress Auxerre",
                        "provider": {
                            "@type": "Organization",
                            "name": "Rock Your Net",
                            "description": "Expert en création de sites WordPress à Auxerre",
                            "url": "https://rockyournet.com",
                            "areaServed": {
                                "@type": "City",
                                "name": "Auxerre"
                            }
                        },
                        "serviceType": "Web Development",
                        "description": "Service de création et développement de sites WordPress professionnels à Auxerre",
                        "offers": {
                            "@type": "Offer",
                            "description": "Sites WordPress sur mesure avec design responsive et optimisation SEO"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services WordPress",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création site vitrine WordPress"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Site e-commerce WordPress"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Maintenance WordPress"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainWordPressAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};