import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainOptimisationWebAuxerre from '../../sections/référencement/MainOptimisationWebAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function OptimisationWebAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Optimisation Site Web à Auxerre | Rock Your Net</title>
                <meta name="description" content="Service d'optimisation de sites web à Auxerre. Améliorez les performances de votre site internet pour une meilleure expérience utilisateur et un meilleur référencement." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="optimisation site web Auxerre, performance site internet Auxerre, amélioration site web Auxerre, vitesse site web Auxerre, expert web Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Optimisation-web-auxerre" />

                {/* Open Graph */}
                <meta property="og:title" content="Optimisation Site Web à Auxerre | Rock Your Net" />
                <meta property="og:description" content="Experts en optimisation de sites web à Auxerre. Améliorez votre site internet pour de meilleures performances et une expérience utilisateur optimale." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Optimisation-web-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Optimisation Site Web à Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Service professionnel d'optimisation de sites web à Auxerre. Performance, vitesse et expérience utilisateur améliorées." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet.png" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebDesignService",
                        "name": "Rock Your Net - Optimisation Web Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet.png",
                        "description": "Service d'optimisation et d'amélioration des performances de sites web à Auxerre.",
                        "provider": {
                            "@type": "Organization",
                            "name": "Rock Your Net",
                            "url": "https://rockyournet.com"
                        },
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services d'optimisation web",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Optimisation des performances"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Amélioration de l'expérience utilisateur"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Optimisation SEO technique"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainOptimisationWebAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};