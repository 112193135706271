import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Image,
    useColorModeValue,
    Badge,
    SimpleGrid,
    Icon,
    List,
    ListItem,
    ListIcon,
    Flex,
} from '@chakra-ui/react';
import { FaCheckCircle, FaSearch, FaList, FaChartLine, FaRegFileAlt, FaPhone, FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../../imagesComponents/Logo_RockYourNet650.webp';
import Audit from '../../imagesComponents/seo.jpg';

const MotionBox = motion(Box);

export default function MainAuditSeoAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const headingColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('white', 'gray.700');
    const buttonBg = useColorModeValue('white', 'gray.800');
    const buttonText = useColorModeValue('purple.500', 'purple.300');
    const sectionSpacing = 20;

    return (
        <Box as="section" bg={bg} minH="100vh" fontSize="lg">
            <Container maxW="8xl" pt={{ base: 8, lg: 20 }} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={6} align="center" mb={28}>
                            <Badge colorScheme="purple" fontSize="md" px={6} py={1} borderRadius="full" mb={6}>
                                Expert en Analyse SEO
                            </Badge>
                            <Heading as="h1" size="2xl" color={headingColor} textAlign="center">
                                Audit SEO à Auxerre
                            </Heading>
                            <Image
                                src={Logo}
                                alt="Logo Rock Your Net"
                                width={{ base: "200px", md: "300px" }}
                                my={6}
                            />
                            <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                Analyse Complète de Votre Site Web
                            </Heading>
                        </VStack>

                        <Text fontSize="xl" color={textColor} mb={12}>
                            Spécialiste de l'<strong>audit SEO à Auxerre</strong>, Rock Your Net réalise une analyse approfondie de votre site web pour identifier les axes d'amélioration et optimiser votre visibilité en ligne. Notre expertise technique et notre connaissance du marché local vous garantissent des recommandations pertinentes et efficaces.
                        </Text>

                        {/* Bouton CTA */}
                        <Flex justify="center" w="full" mb={8}>
                            <MotionBox
                                as={RouterLink}
                                to="/Services/Referencement"
                                bg={buttonBg}
                                whileHover={{ scale: 1.02 }}
                                p={4}
                                px={8}
                                border="2px solid"
                                borderColor="purple.500"
                                color={buttonText}
                                borderRadius="lg"
                                _hover={{
                                    bg: 'white',
                                    color: 'purple.600',
                                    border: '2px solid',
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl'
                                }}
                                textAlign="center"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                gap={3}
                            >
                                <Icon as={FaSearch} w={5} h={5} />
                                <Text fontSize="md" fontWeight="medium">Découvrir Notre Service SEO Premium</Text>
                            </MotionBox>
                        </Flex>
                    </Box>
                </VStack>
            </Container>

            <Container maxW="full" bg={cardBg}>
                <Container maxW="8xl" bg={cardBg} pt={10} pb={10}>
                    <VStack spacing={sectionSpacing} align="start">
                        <Box w="100%" py={12}>
                            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} alignItems="center">
                                <Box>
                                    <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                        Notre Méthodologie d'Audit
                                    </Heading>
                                    <Text color={textColor} mb={8}>
                                        Un <strong>audit SEO à Auxerre</strong> réalisé par nos experts comprend :
                                    </Text>
                                    <List spacing={6}>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Analyse technique</strong> : Performance, structure et accessibilité
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Audit du contenu</strong> : Qualité, pertinence et optimisation SEO
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Analyse concurrentielle</strong> : Positionnement et opportunités
                                            </Box>
                                        </ListItem>
                                        <ListItem display="Flex">
                                            <ListIcon as={FaCheckCircle} color="green.500" w={6} h={6} />
                                            <Box color={textColor}>
                                                <strong>Plan d'action détaillé</strong> : Recommandations et priorités
                                            </Box>
                                        </ListItem>
                                    </List>
                                </Box>
                                <Flex justify="center" align="center" order={{ base: -1, lg: 1 }}>
                                    <Image
                                        src={Audit}
                                        alt="Audit SEO illustration"
                                        width={{ base: "100%", lg: "60%" }}
                                        maxW="500px"
                                        borderRadius="2xl"
                                        shadow="2xl"
                                    />
                                </Flex>
                            </SimpleGrid>
                        </Box>
                    </VStack>
                </Container>
            </Container>

            <Container maxW="8xl" pt={20} pb={4}>
                <VStack spacing={sectionSpacing} align="start">
                    <Box w="full" mb={sectionSpacing}>
                        <VStack spacing={20} align="start">
                            <Box w="full">
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Les Avantages de Notre Audit SEO
                                </Heading>
                                <Text color={textColor} mb={16}>
                                    Un <strong>audit SEO à Auxerre</strong> réalisé par Rock Your Net vous apporte une vision claire et complète de votre présence en ligne.
                                </Text>

                                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={12}>
                                    <MotionBox
                                        as={RouterLink}
                                        to="/Services/Referencement"
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                        cursor="pointer"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaSearch} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Diagnostic Complet</Heading>
                                        <Text color={textColor}>
                                            Analyse exhaustive de votre site et de son potentiel d'optimisation
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaList} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Plan d'Action</Heading>
                                        <Text color={textColor}>
                                            Recommandations concrètes et priorisées pour améliorer votre SEO
                                        </Text>
                                    </MotionBox>

                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        p={8}
                                        bg={cardBg}
                                        borderRadius="xl"
                                        shadow="xl"
                                    >
                                        <Flex justify="center">
                                            <Icon as={FaChartLine} w={12} h={12} color="orange.500" mb={6} />
                                        </Flex>
                                        <Heading as="h4" size="md" mb={4} color={headingColor}>Suivi des Résultats</Heading>
                                        <Text color={textColor}>
                                            Mesure de l'impact des optimisations recommandées
                                        </Text>
                                    </MotionBox>
                                </SimpleGrid>
                            </Box>

                            <Box mt={8}>
                                <Heading as="h2" size="xl" mb={6} color={headingColor}>
                                    Pourquoi Réaliser un Audit SEO ?
                                </Heading>
                                <Text color={textColor} mb={8}>
                                    Un <strong>audit SEO professionnel à Auxerre</strong> est la première étape pour améliorer votre visibilité en ligne. Il vous permet d'identifier les points forts et les faiblesses de votre site, et de mettre en place une stratégie d'optimisation efficace.
                                </Text>
                                <Text color={textColor}>
                                    Notre expertise en tant qu'agence SEO nous permet de vous proposer des solutions adaptées au marché, pour un impact maximal sur votre visibilité.
                                </Text>
                            </Box>

                            <Box>
                                <Heading as="h3" size="xl" mb={6} color={headingColor}>
                                    Un Investissement pour Votre Développement
                                </Heading>
                                <Text color={textColor}>
                                    Faire réaliser un <strong>audit SEO à Auxerre</strong> par Rock Your Net, c'est investir dans la croissance de votre entreprise. Nos recommandations vous permettront d'améliorer votre positionnement sur les moteurs de recherche et d'attirer plus de visiteurs qualifiés sur votre site web.
                                </Text>
                            </Box>

                            {/* Section CTA */}
                            <Box w="full" bg={cardBg} borderRadius="2xl" p={{ base: 4, lg: 12 }} mt={8}>
                                <VStack spacing={8} align="center">
                                    <Heading as="h2" size="xl" color={headingColor} textAlign="center">
                                        Prêt à Optimiser Votre Site Web ?
                                    </Heading>

                                    <Text fontSize="lg" color={textColor} textAlign="center" maxW="2xl">
                                        Contactez-nous pour réaliser un audit SEO complet de votre site et découvrir son potentiel d'amélioration.
                                    </Text>

                                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full" maxW="4xl" mx="auto">
                                        <MotionBox
                                            as={RouterLink}
                                            to="/Services/Referencement"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaSearch} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Services SEO</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Créations"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaArrowRight} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Nos Réalisations</Text>
                                        </MotionBox>

                                        <MotionBox
                                            as={RouterLink}
                                            to="/Contact"
                                            bg={buttonBg}
                                            whileHover={{ scale: 1.02 }}
                                            p={4}
                                            border="2px solid"
                                            borderColor="purple.500"
                                            color={buttonText}
                                            borderRadius="lg"
                                            _hover={{
                                                bg: 'white',
                                                color: 'purple.600',
                                                border: '1px solid',
                                                borderColor: 'purple.600',
                                                transform: 'translateY(-2px)',
                                                shadow: 'xl'
                                            }}
                                            textAlign="center"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={3}
                                        >
                                            <Icon as={FaPhone} w={5} h={5} />
                                            <Text fontSize="md" fontWeight="medium">Contactez-nous</Text>
                                        </MotionBox>
                                    </SimpleGrid>
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}